import * as React from 'react';
import { makeAutoObservable } from 'mobx';
import openSuccessModal from '../../components/showModalFunc';
import { User, Users } from '../../types/User';
import authAxiosClient from '../../utils/authAxiosClient';
import { OrdersModal } from '../../components/Modals/Orders/index';

export class AdminPatientsStore {
	patients = {} as Users;
	filters = {};
	loading = false;
	patientsServices = [];

	constructor() {
		makeAutoObservable(this);
	}

	openModalDetail(item: User) {
		const { destroy } = openSuccessModal({
			width: '75%',
			keyboard: false,
			textContent: (
				<OrdersModal
					title="Список услуг"
					onCancel={() => destroy()}
					user={item}
				/>
			),
		});
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	async getPatients(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(
				`/admin/users/patient?page=${page}&per-page=${pageSize}&sort=${sortField}`,
				{
					params: { ...this.filters },
				}
			);
			this.patients = data;
			return this.patients;
		} finally {
			this.setLoading(false);
		}
	}

	setFilters(filters: any) {
		this.filters = { ...this.filters, ...filters };
	}

	clearFilters() {
		this.filters = {};
	}
}
