import React, { useState } from 'react';

import { transactionsStore } from '../../../stores';
import { InviteReferals } from '../../Cabinet/InviteReferal';
import { TransactionDetail } from '../../Cabinet/TransactionDetail';
import { TransactionList } from '../../Cabinet/TransactionsList';

enum ComponentType {
	transactionList,
	transactionDetail,
	inviteReferal,
}

const Transactions = () => {
	const [componentType, setComponentType] = useState(
		ComponentType.transactionList
	);

	const components = {
		[ComponentType.transactionList]: (
			<TransactionList
				onDetail={async (id: number) => {
					await transactionsStore.getTransactionDetail(id);
					setComponentType(ComponentType.transactionDetail);
				}}
			/>
		),
		[ComponentType.transactionDetail]: (
			<TransactionDetail
				onInvite={() => setComponentType(ComponentType.inviteReferal)}
				onBack={() => setComponentType(ComponentType.transactionList)}
			/>
		),
		[ComponentType.inviteReferal]: (
			<InviteReferals
				onBack={() => setComponentType(ComponentType.transactionList)}
			/>
		),
	};
	return components[componentType] || ComponentType.transactionList;
};

export default Transactions;
