import React from 'react';
import DatePicker from '../common/DatePicker';
import InputFieldTitle from '../InputFieldTitle';

type InputFieldProps = {
	title?: string;
	errorMessage?: string | boolean;
	required?: boolean;
} & any;

const DatePickerField = ({
	title,
	required,
	errorMessage,
	...props
}: InputFieldProps) => (
	<InputFieldTitle
		required={required}
		title={title || ''}
		errorMessage={errorMessage}
	>
		<DatePicker {...props} />
	</InputFieldTitle>
);

export default DatePickerField;
