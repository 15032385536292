import { Button, Row } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => (
	<Row justify="center">
		<div>
			<h1>Page not found</h1>
			<Row justify="center">
				<Link to={'/'}>
					<Button type={'primary'}>Go to main page</Button>
				</Link>
			</Row>
		</div>
	</Row>
);
