import React from 'react';
import { Button, Col, InputNumber, Row } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { servicesStore, userStore } from '../../../stores';
import { PayMethod } from '../PayMethod';
import styles from './styles.module.scss';

interface Props {
	payService: ({ cashBon, medBon }: any) => void;
	onCancel?: () => void;
}

interface InitialValues {
	card_id: null | number;
	type?: string;
	payMethods: CheckBoxTypes[];
	card_amount: number | null;
	mednean_bon: number | null;
	cash_bon: number | null;
}

enum CheckBoxTypes {
	card = 'card_amount',
	mednean = 'mednean_bon',
	cash = 'cash_bon',
}

export const Pay = observer(({ onCancel, payService }: Props) => {
	const { selectedService } = servicesStore;
	const { t } = useTranslation();
	const discountPrice = selectedService.price_discount;
	const options = [
		{
			label: t('Банковской картой'),
			value: CheckBoxTypes.card,
		},
		{
			label: (
				<>
					{t('Меднеан бонусы')}{' '}
					<span className={styles.grayText}>{t('Осталось')}: </span>
					<b className={styles.bonus}>{userStore.userInfo.mednean_bon}</b>
				</>
			),
			value: CheckBoxTypes.mednean,
		},
		{
			label: (
				<>
					{t('Кеш бонуси')}{' '}
					<span className={styles.grayText}>{t('Осталось')}: </span>
					<b className={styles.bonus}>{userStore.userInfo.cash_bon}</b>
				</>
			),
			value: CheckBoxTypes.cash,
		},
	];

	const createInputNumber = (
		value: any,
		label: string,
		inputName: string,
		onChange: any,
		disabled = false
	) => {
		return (
			<>
				<p className={styles.grayText}>{t(label)}</p>
				<InputNumber
					name={inputName}
					value={value}
					min={0}
					max={discountPrice}
					step="0.1"
					disabled={disabled}
					decimalSeparator={'.'}
					className={styles.input}
					placeholder={t('Сумма оплаты')}
					onChange={value => {
						onChange(value);
					}}
				/>
			</>
		);
	};

	const validationSchema = Yup.object().shape({
		payMethods: Yup.array().min(1).of(Yup.string().required()).required(),
		card_amount: Yup.number()
			.nullable()
			.when('payMethods', {
				is: (methods: any) => methods.includes(CheckBoxTypes.card),
				then: Yup.number().nullable().required('Ввод суммы обязателен'),
			}),
		cash_bon: Yup.number()
			.nullable()
			.when('payMethods', {
				is: (methods: any) => methods.includes(CheckBoxTypes.cash),
				then: Yup.number().nullable().required('Ввод суммы обязателен'),
			}),
		mednean_bon: Yup.number()
			.nullable()
			.when('payMethods', {
				is: (methods: any) => methods.includes(CheckBoxTypes.mednean),
				then: Yup.number().nullable().required('Ввод суммы обязателен'),
			}),
	});

	const initialValues: InitialValues = {
		card_id: null,
		type: 'card',
		payMethods: new Array<CheckBoxTypes>(),
		card_amount: null,
		mednean_bon: null,
		cash_bon: null,
	};

	const calculate = (amount: number, lenghtPayMethods: number) => {
		const remaining = discountPrice - amount;
		const result = remaining / (lenghtPayMethods - 1);
		return parseFloat(result.toFixed(2));
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initialValues}
			validateOnMount={true}
			enableReinitialize
			onSubmit={values => {
				payService({
					...values,
					byCard: values.payMethods.includes(CheckBoxTypes.card),
				});
			}}
		>
			{({
				handleSubmit,
				setFieldValue,
				values,
				errors,
				isValid,
				setFieldTouched,
			}: any) => (
				<Observer>
					{() => (
						<Row justify="center">
							<Col>
								<form onSubmit={handleSubmit}>
									<Row justify="center">
										<Col>
											<div className={styles.titleBox}>
												<h3 className={styles.title}>{t('Оплатить')}</h3>
												<span style={{ color: '#17CE76' }}>
													{t('Услуга')}: {selectedService.name}{' '}
													{selectedService.product?.name}
												</span>
												<p>
													{t(
														'Все услуги оплаченные через приложение на 20% дешевле'
													)}
												</p>
											</div>
										</Col>
									</Row>
									<Row justify="center">
										<Col xs={24} lg={24}>
											{createInputNumber(
												discountPrice,
												'Сумма оплаты',
												'',
												() => ({}),
												true
											)}
										</Col>
									</Row>
									<br />
									<Row gutter={24}>
										{values.payMethods.includes(CheckBoxTypes.card) && (
											<Col xs={24} lg={8}>
												{createInputNumber(
													values.card_amount,
													'Оплата картой',
													CheckBoxTypes.card,
													async (amount: number) => {
														const fields = values.payMethods;
														await setFieldValue(CheckBoxTypes.card, amount);
														const result = calculate(amount, fields.length);
														fields.forEach((field: string) => {
															if (field !== CheckBoxTypes.card) {
																setFieldValue([field], result);
																setFieldTouched([field], true);
															}
														});
													}
												)}
												{errors.card_amount ? (
													<div className={styles.errorMsg}>
														{errors.card_amount || ''}
													</div>
												) : (
													Boolean(Number(values.card_amount)) && (
														<div className={styles.danger}>
															{t('Комисия банка составит', {
																amount: (
																	(Number(values.card_amount) * 2.5) /
																	100
																).toFixed(2),
															})}
														</div>
													)
												)}
											</Col>
										)}
										{values.payMethods.includes(CheckBoxTypes.mednean) && (
											<Col xs={24} lg={8}>
												{createInputNumber(
													values.mednean_bon,
													'Меднеан бонусы',
													CheckBoxTypes.mednean,
													async (amount: number) => {
														const fields = values.payMethods;
														await setFieldValue(CheckBoxTypes.mednean, amount);
														const result = calculate(amount, fields.length);
														fields.forEach((field: string) => {
															if (field !== CheckBoxTypes.mednean) {
																setFieldValue([field], result);
																setFieldTouched([field], true);
															}
														});
													}
												)}
												{errors.mednean_bon && (
													<div className={styles.errorMsg}>
														{errors.mednean_bon}
													</div>
												)}
											</Col>
										)}
										{values.payMethods?.includes(CheckBoxTypes.cash) && (
											<Col xs={24} lg={8}>
												{createInputNumber(
													values.cash_bon,
													'Кэш бонусы',
													CheckBoxTypes.cash,
													async (amount: number) => {
														const fields = values.payMethods;
														await setFieldValue(CheckBoxTypes.cash, amount);
														const result = calculate(amount, fields.length);
														fields.forEach((field: string) => {
															if (field !== CheckBoxTypes.cash) {
																setFieldValue([field], result);
																setFieldTouched([field], true);
															}
														});
													}
												)}
												{errors.cash_bon && (
													<div className={styles.errorMsg}>
														{errors.cash_bon}
													</div>
												)}
											</Col>
										)}
									</Row>
									<br />
									<PayMethod
										values={values.payMethods}
										options={options}
										onChange={async (list: any) => {
											await setFieldValue('payMethods', list);
											const lenghtPayMethods = list.length;
											const res = parseFloat(
												(discountPrice / lenghtPayMethods).toFixed(2)
											);

											if (lenghtPayMethods === 0) {
												await setFieldValue(CheckBoxTypes.card, null);
												await setFieldValue(CheckBoxTypes.cash, null);
												await setFieldValue(CheckBoxTypes.mednean, null);
											} else {
												list.forEach(async (item: string) => {
													await setFieldValue(item, res);
													setFieldTouched(item, true);
												});
											}
										}}
									/>
									<br />
									<Row justify="center" gutter={[12, 12]}>
										<Col span={12}>
											<Button onClick={onCancel} block size="large">
												{t('Отменить')}
											</Button>
										</Col>
										<Col span={12}>
											<Button
												disabled={!isValid}
												htmlType="submit"
												block
												size="large"
												type="primary"
											>
												{t('Оплатить')}
											</Button>
										</Col>
									</Row>
								</form>
							</Col>
						</Row>
					)}
				</Observer>
			)}
		</Formik>
	);
});
