import { AutoComplete, Button, Col, Input, Row, Select } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import settings from '../../../settings';
import { adminInvoicesStore, adminServicesStore } from '../../../stores';
import { Service } from '../../../types/Service';
import { getDiscount } from '../../../utils/helperFunctions';
import { getBase64FromUrl } from '../../../utils/toBase64';
import InputField from '../../InputField';
import DepartmentSelection from '../../Selections/Departments';
import styles from './styles.module.scss';

const { TextArea } = Input;

const images = [
	'/images/services/2.webp',
	'/images/services/3.webp',
	'/images/services/4.webp',
	'/images/services/5.webp',
	'/images/services/6.webp',
	'/images/services/default.webp',
];

export const ServiceInvoicesForm = observer(
	({ onCancel, onSubmit, initialValues }: any) => {
		const { t } = useTranslation();
		const requiredText = t('Обязательное поле');
		const { data } = adminServicesStore.services;

		const ServiceSchema = Yup.object().shape({
			price: Yup.string().required(requiredText),
			department_id: Yup.string().required(requiredText),
			img_base64: Yup.string().required(requiredText),
			languages: Yup.object().shape({
				ua: Yup.object().shape({
					name: Yup.string().required(requiredText),
					desc: Yup.string().required(requiredText),
				}),
				ru: Yup.object().shape({
					name: Yup.string().required(requiredText),
					desc: Yup.string().required(requiredText),
				}),
			}),
		});

		React.useEffect(() => {
			adminServicesStore.getServices();
		}, []);

		const options: any = {
			ru: data.map((service: Service) => ({
				value: service.languages?.ru?.name,
				rusdesc: service.languages?.ru?.desc,
				uaname: service.languages?.ua?.name,
				uadesc: service.languages?.ua?.desc,
			})),
			ua: data.map((service: Service) => ({
				value: service.languages?.ua?.name,
				uadesc: service.languages?.ua?.desc,
				rusname: service.languages?.ru?.name,
				rusdesc: service.languages?.ru?.desc,
			})),
		};

		const searchByName = (inputValue: string, option: any) =>
			option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1;

		return (
			<>
				<Formik
					initialValues={initialValues}
					validateOnMount
					validationSchema={ServiceSchema}
					onSubmit={onSubmit}
				>
					{({
						handleSubmit,
						setFieldValue,
						isValid,
						values,
						setValues,
					}: any) => (
						<Observer>
							{() => (
								<form onSubmit={handleSubmit}>
									<Row gutter={12}>
										<Col span={10}>
											<InputField
												name="price"
												type="number"
												value={values.price}
												title={t('Цена услуги')}
												onChange={(event: any) => {
													const price = Number(event.target.value);
													const price_discount = price - getDiscount(price, 20);
													setValues({
														...values,
														price,
														price_discount,
													});
												}}
											/>
										</Col>
										<Col span={10}>
											<div>{t('Филия')}</div>
											<DepartmentSelection
												defaultValue={values.department_id}
												onChange={(value: any) =>
													setFieldValue('department_id', value)
												}
											/>
										</Col>
									</Row>
									<Row gutter={12}>
										<Col span={10}>
											<InputField
												title={t('Скидка в процентах')}
												value={20}
												disabled
												onChange={(event: any) =>
													setFieldValue('discount', event.target.value)
												}
											/>
										</Col>
										<Col span={10}>
											<InputField
												title={t('Цена со скидкой')}
												disabled
												value={values.price_discount}
												placeholder={t('Цена со скидкой')}
												onChange={(event: any) =>
													setFieldValue('price_discount', event.target.value)
												}
											/>
										</Col>
									</Row>
									<Row justify="end" align="middle" gutter={12}>
										<span>{t('Украинским')}</span>
										<img
											className={styles.langIcon}
											src="/images/ukraine.webp"
											alt="ukraine"
										/>
									</Row>
									<Row>
										<Col span={24}>
											<span>{t('Название услуги')}</span>
											<AutoComplete
												value={values.languages.ua?.name}
												allowClear
												filterOption={searchByName}
												style={{ width: '100%' }}
												placeholder={t('Название услуги')}
												options={options.ua}
												onSelect={(value: any, data: any) => {
													setFieldValue('languages.ua.name', value);
													setFieldValue('languages.ua.desc', data.uadesc);
													setFieldValue('languages.ru.name', data.rusname);
													setFieldValue('languages.ru.desc', data.rusdesc);
												}}
												onChange={(value: any) => {
													setFieldValue('languages.ua.name', value);
												}}
											/>
										</Col>
										<Col span={24}>
											<br />
											<span>{t('Описание услуги')}</span>
											<TextArea
												value={values.languages.ua?.desc}
												placeholder={t('Описание услуги')}
												onChange={(event: any) =>
													setFieldValue('languages.ua.desc', event.target.value)
												}
											/>
										</Col>
									</Row>
									<br />
									<Row justify="end" align="middle" gutter={12}>
										<span>{t('Русским')}</span>
										<img
											className={styles.langIcon}
											src="/images/russia.webp"
											alt="russia"
										/>
									</Row>
									<Row>
										<Col span={24}>
											<span>{t('Название услуги')}</span>
											<AutoComplete
												allowClear
												placeholder={t('Выберите услугу')}
												filterOption={searchByName}
												style={{ width: '100%' }}
												options={options.ru}
												value={values.languages.ru?.name}
												onSelect={(value: any, data: any) => {
													setFieldValue('languages.ru.name', value);
													setFieldValue('languages.ru.desc', data.rusdesc);
													setFieldValue('languages.ua.name', data.uaname);
													setFieldValue('languages.ua.desc', data.uadesc);
												}}
												onChange={(value: any) => {
													setFieldValue('languages.ru.name', value);
												}}
											/>
										</Col>
										<Col span={24}>
											<br />
											<span>{t('Описание услуги')}</span>
											<TextArea
												value={values.languages.ru?.desc}
												placeholder={t('Описание услуги')}
												onChange={(event: any) =>
													setFieldValue('languages.ru.desc', event.target.value)
												}
											/>
										</Col>
									</Row>
									<div className={styles.space}>
										<Row justify="space-between">
											<Col md={6}>
												<div>{t('Выберите картинку')}</div>
												<Select
													placeholder={t('Выберите картинку')}
													className={styles.select}
													size="large"
													onChange={(img: string) => {
														getBase64FromUrl(img).then(data =>
															setFieldValue('img_base64', data)
														);
													}}
												>
													{images.map((image: string) => (
														<Select.Option key={image} value={image}>
															<img width={35} src={image} alt="" />
														</Select.Option>
													))}
												</Select>
											</Col>
											<Col span={12}>
												{values.img ? (
													<>
														<div>{t('Текущая картинка')}</div>
														<img
															width={50}
															src={`${settings.apiUrl}${values.img}`}
															alt=""
														/>
													</>
												) : (
													<span>{t('Картинка не выбрана')}</span>
												)}
											</Col>
										</Row>
									</div>
									<Row justify="end" gutter={12}>
										<Col>
											<Button onClick={onCancel} type="default" size="large">
												{t('Отменить')}
											</Button>
										</Col>
										<Col>
											<Button
												loading={
													adminServicesStore.loading ||
													adminInvoicesStore.loading
												}
												disabled={!isValid}
												htmlType="submit"
												type="primary"
												size="large"
											>
												{t('Сохранить')}
											</Button>
										</Col>
									</Row>
								</form>
							)}
						</Observer>
					)}
				</Formik>
			</>
		);
	}
);
