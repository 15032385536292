import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import styles from '../../../../components/Admin/Table/styles.module.scss';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminDepartmentsStore, languageStore } from '../../../../stores';

const SettingsBranch = observer(() => {
	const { t } = useTranslation();

	React.useEffect(() => {
		adminDepartmentsStore.getDepartments();
	}, [languageStore.currentLang]);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: (text: React.ReactNode) => <b>{text}</b>,
		},
		{
			title: t('Название'),
			dataIndex: 'name',
		},
		{
			title: t('Номер телефона'),
			dataIndex: 'phone',
		},
		{
			title: t('Почта'),
			dataIndex: 'email',
		},
		{
			render: (values: any) => (
				<EditDeleteIcons
					onEdit={() => adminDepartmentsStore.openEditDepartmentModal(values)}
					onDelete={() => adminDepartmentsStore.deleteDepartment(values.id)}
				/>
			),
		},
	];

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.btn}
					type="primary"
					onClick={() => adminDepartmentsStore.openCreateDepartmentModal()}
				>
					{t('Добавить')}
				</Button>
			</Row>
			<br />
			<AdminTable
				pagination={false}
				columns={columns}
				data={adminDepartmentsStore.departments}
			/>
		</AdminLayout>
	);
});

export default SettingsBranch;
