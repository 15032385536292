import * as React from 'react';

import { AdminLayout } from '../../../components/Admin/Layout';

const StatisticMainPage = () => {
	return (
		<AdminLayout>
			<div></div>
		</AdminLayout>
	);
};

export default StatisticMainPage;
