import { message } from 'antd';
import { makeAutoObservable } from 'mobx';

import settings from '../../settings';
import { Transaction, TransactionInfo } from '../../types/Transactions';
import authAxiosClient from '../../utils/authAxiosClient';

type OutProps = {
	name?: string;
	card?: string;
	amount?: number;
	inn?: string;
	sms?: number;
};

const { domainUrl } = settings;
export class TransactionsStore {
	loading = false;
	transactions: Transaction[] = [];
	transactionType = '0,1';
	selectedTransaction = {} as TransactionInfo;
	withDrawData = {};
	statistics = [];

	constructor() {
		makeAutoObservable(this);
	}

	changeTransactionType(type: string) {
		this.transactionType = type;
	}

	async getTransactions() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/transactions?per-page=0&type=${this.transactionType}`
			);
			this.transactions = data.data.filter(
				(item: Transaction) => item.status === 2
			);
		} finally {
			this.setLoading(false);
		}
	}

	async getTransactionDetail(id: number) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(`/transactions/${id}`);
			this.selectedTransaction = data.data;
			return data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async downloadReceipe(local_code: string) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/transactions/order/${local_code}`
			);
			return data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async getStatistic() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				'/transactions/statistics?type=1,2,3'
			);
			this.statistics = data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async createDeposit(values: any) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.post('/transactions', values);
			if (data.data.url) {
				const { id, url } = data.data;
				message.loading('Переадерсация на сервис Portmone...');
				const redirectUrl = `${url}?successUrl=${domainUrl}/personal-cabinet/operation-success/${id}&failureUrl=${domainUrl}/personal-cabinet/operation-failed/${id}`;
				setTimeout(() => {
					window.location.assign(redirectUrl);
				}, 2000);
			}
			return data;
		} finally {
			this.setLoading(false);
		}
	}

	async payService(values: any) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.post(
				`/transactions/${values.serviceType}`,
				values
			);
			if (data.data.url) {
				const { id, url } = data.data;
				message.loading('Переадерсация на сервис Portmone...');
				const redirectUrl = `${url}?successUrl=${domainUrl}/personal-cabinet/operation-success/${id}&failureUrl=${domainUrl}/personal-cabinet/operation-failed/${id}`;
				setTimeout(() => {
					window.location.assign(redirectUrl);
				}, 2000);
			}
			return data;
		} finally {
			this.setLoading(false);
		}
	}

	async beforeOutCash(values: OutProps) {
		try {
			this.setLoading(true);
			await authAxiosClient.post('/transactions/before-out', values);
		} finally {
			this.setLoading(false);
		}
	}

	async outCash(values: any) {
		this.setLoading(true);

		try {
			return await authAxiosClient.post('/transactions/out', values);
		} catch (e: any) {
			return e.response;
		} finally {
			this.setLoading(false);
		}
	}

	setWithDrawData(values: OutProps) {
		this.withDrawData = { ...this.withDrawData, ...values };
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
