import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { userStore } from '../../../stores';
import styles from './styles.module.scss';

const UserBalance = () => {
	const { t } = useTranslation();
	React.useEffect(() => {
		userStore.getInfo();
	}, []);

	return (
		<div className={styles.card}>
			<div className={styles.cardTitle}>{t('Ваш баланс')}</div>
			<Row justify="space-between" gutter={12}>
				<Col>
					<div className={styles.bonusCount}>{t('Меднеан бонусы')}:</div>
					<div className={styles.cardTextDecoration}>
						{userStore.userInfo.mednean_bon}
					</div>
				</Col>
				<Col>
					<div className={styles.bonusCount}>{t('Кэш бонусы')}:</div>
					<div className={styles.cardTextDecoration}>
						{userStore.userInfo.cash_bon}
					</div>
				</Col>
				<Col />
			</Row>
		</div>
	);
};

export default observer(UserBalance);
