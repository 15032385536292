import {
	BellOutlined,
	ControlOutlined,
	MenuOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import { Badge, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';

import {
	ADMIN,
	CABINET_NOTIFICATIONS,
	CABINET_SETTINGS,
} from '../../../pathNames';
import { Role } from '../../../routes/routes';
import { cabinetSideBarStore, userStore } from '../../../stores';
import LanguageSelection from '../../Selections/Languages';
import styles from './styles.module.scss';

const TopBar = () => (
	<Row justify="space-between">
		<Col>
			<span
				className={styles.trigger}
				onClick={() => cabinetSideBarStore.setCollapsed()}
			>
				<MenuOutlined />
			</span>
		</Col>
		<Col>
			<Row justify="end" gutter={16}>
				{(userStore.userInfo.role === Role.Admin ||
					userStore.userInfo.role === Role.Root) && (
					<Col>
						<NavLink to={ADMIN}>
							<ControlOutlined title="АДМИНКА" className={styles.navIcon} />
						</NavLink>
					</Col>
				)}
				<Col>
					<NavLink to={CABINET_SETTINGS}>
						<SettingOutlined className={styles.navIcon} />
					</NavLink>
				</Col>
				<Col>
					<Badge
						className={styles.notificationCounter}
						count={userStore.notSeenNotifications}
						offset={[0, 22]}
						size="small"
					>
						<NavLink to={CABINET_NOTIFICATIONS}>
							<BellOutlined className={styles.navIcon} />
						</NavLink>
					</Badge>
				</Col>
				<Col>
					<LanguageSelection />
				</Col>
			</Row>
		</Col>
	</Row>
);

export default observer(TopBar);
