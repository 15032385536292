import { makeAutoObservable } from 'mobx';
import React from 'react';

import { NotificationCreate } from '../../components/Modals/NotificationCreate';
import { NotificationDetail } from '../../components/Modals/NotificationDetail';
import openSuccessModal from '../../components/showModalFunc';
import authAxiosClient from '../../utils/authAxiosClient';
import { adminNotificationsStore } from '../index';

export class AdminNotificationsStore {
	notifications = [];
	filteredNotifications = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	openDetailModal(values: any) {
		const { destroy } = openSuccessModal({
			width: '70%',
			keyboard: false,
			textContent: (
				<NotificationDetail
					title="Список уведомлений"
					onCancel={() => destroy()}
					data={values}
				/>
			),
		});
	}

	openCreateNotifModal() {
		const { destroy } = openSuccessModal({
			width: '70%',
			keyboard: false,
			textContent: (
				<NotificationCreate
					destroyModal={() => destroy()}
					onSubmit={(values: any) =>
						adminNotificationsStore.createNotification(values)
					}
				/>
			),
		});
	}

	setFilteredNotifications(data: any) {
		this.filteredNotifications = data;
	}

	createValidData(values: any) {
		const { titleRus, textRus, titleUkr, textUkr } = values;
		const ukrData = { text: titleUkr, data: textUkr, language_id: 1, type: 0 };
		const rusData = { text: titleRus, data: textRus, language_id: 2, type: 0 };
		return { ukrData, rusData };
	}

	async createNotification(values: any) {
		this.setLoading(true);
		const { ukrData, rusData } = this.createValidData(values);
		try {
			const { data } = await authAxiosClient.post(
				'/admin/notifications',
				ukrData
			);
			await authAxiosClient.put(
				`/admin/notifications/${data.data.id}`,
				rusData
			);
		} finally {
			this.setLoading(false);
		}
	}

	async editNotification(values: any) {
		this.setLoading(true);
		try {
			await authAxiosClient.post(`/admin/notifications/${values.id}`, values);
		} finally {
			this.setLoading(false);
		}
	}

	async getUserNotifications(id: number) {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(`/admin/notifications/${id}`);
			this.notifications = data.data;
			this.setFilteredNotifications(data.data);
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
