import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/User';
import AdminUserInfo from '../../Admin/UserInfo/AdminUserInfo';
import DatePicker from '../../common/DatePicker';
import InputField from '../../InputField';
import * as Yup from 'yup';
import dayjs from 'dayjs';

const { Option } = Select;

type Props = {
	onCancel: () => void;
	onSubmit: (values: any) => void;
	user: User;
};

export const EditUserProfile = observer(
	({ onCancel, onSubmit, user }: Props) => {
		const { t } = useTranslation();
		const phoneRegexp = /^380\d{9}$/g;
		const requiredText = t('Обязательное поле');

		const validationSchema = Yup.object().shape({
			last_name: Yup.string().required(requiredText),
			first_name: Yup.string().required(requiredText),
			birthday: Yup.string().required(requiredText),
			gender: Yup.string().required(requiredText),
			phone: Yup.string()
				.matches(phoneRegexp, 'Неправильный номер телефона')
				.required(t<string>('Обязательное поле')),
		});

		const onSubmitHandler = (values: any) => {
			onSubmit(values);
		};

		const { phone, email } = user;
		const { first_name, last_name, birthday, user_id, middle_name, gender } =
			user.profiles;

		return (
			<Formik
				validationSchema={validationSchema}
				onSubmit={onSubmitHandler}
				enableReinitialize={true}
				initialValues={{
					first_name,
					last_name,
					birthday,
					user_id,
					middle_name,
					gender,
					phone,
					email,
				}}
			>
				{({ handleSubmit, setFieldValue, values, errors }: any) => (
					<Observer>
						{() => (
							<>
								<AdminUserInfo user={user} loading={false} />
								<form onSubmit={handleSubmit}>
									<Row gutter={12}>
										<Col>
											<InputField
												errorMessage={errors.last_name}
												value={values.last_name}
												title={t('Фамилия')}
												placeholder={t('Фамилия')}
												required={true}
												onChange={event =>
													setFieldValue('last_name', event.target.value)
												}
											/>
										</Col>
										<Col>
											<InputField
												errorMessage={errors.first_name}
												value={values.first_name}
												title={t('Имя')}
												placeholder={t('Имя')}
												required={true}
												onChange={event =>
													setFieldValue('first_name', event.target.value)
												}
											/>
										</Col>
										<Col>
											<InputField
												errorMessage={errors.middle_name}
												value={values.middle_name}
												title={t('Отчество')}
												placeholder={t('Отчество')}
												required={true}
												onChange={event =>
													setFieldValue('middle_name', event.target.value)
												}
											/>
										</Col>
										<Col>
											<div>{t('Пол')}</div>
											<Select
												defaultValue={values.gender}
												onChange={(value: number) =>
													setFieldValue('gender', value)
												}
												placeholder="Пол"
												style={{ width: '100px' }}
											>
												<Option value={1}>{t('Мужской')}</Option>
												<Option value={0}>{t('Женский')}</Option>
											</Select>
										</Col>
									</Row>
									<Row gutter={12}>
										<Col>
											<InputField
												errorMessage={errors.phone}
												value={values.phone}
												title={t('Телефон')}
												placeholder={t('Телефон')}
												required={true}
												onChange={event =>
													setFieldValue('phone', event.target.value)
												}
											/>
										</Col>
										<Col>
											<InputField
												value={values.email}
												title={t('Email')}
												placeholder={t('Email')}
												required={true}
												onChange={event =>
													setFieldValue('email', event.target.value)
												}
											/>
										</Col>
										<Col>
											<div>{t('Дата рождения')}</div>
											<DatePicker
												onChange={(date, stringDate) => {
													setFieldValue('birthday', stringDate);
												}}
												value={dayjs(values.birthday)}
											/>
										</Col>
									</Row>

									<Row gutter={[12, 12]} justify="space-between">
										<Button
											block
											htmlType="reset"
											onClick={() => {
												onCancel();
											}}
										>
											{t('Отменить')}
										</Button>
										<Button block htmlType="submit" type="primary">
											{t('Сохранить')}
										</Button>
									</Row>
								</form>
							</>
						)}
					</Observer>
				)}
			</Formik>
		);
	}
);
