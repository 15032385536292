import { makeAutoObservable } from 'mobx';
import React from 'react';

import openSuccessModal from '../../components/showModalFunc';
import { User } from '../../types/User';
import authAxiosClient from '../../utils/authAxiosClient';
import { TransactionsModal } from '../../components/Modals/Transactions/index';

export class AdminStatisticsStore {
	statistics = {
		users: 0,
		input_mednean_bon: 0,
		input_cash_bon: 0,
		output_mednean_bon: 0,
		output_cash_bon: 0,
	};
	period = '7';
	bonusType = '1';
	transactionsStatistic = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async getPeriodStatistics() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/admin/statistics?day=${this.period}&per-page=0`
			);
			this.statistics = data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async getStatistics() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/admin/statistics?day=${this.period}`
			);
			this.statistics = data.data;
		} finally {
			this.setLoading(false);
		}
	}
	async getTransactionsStatistic() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/admin/transactions?per-page=0&day=${this.period}&amount_more=0`
			);
			this.transactionsStatistic = data.data;
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	changeBonusType(value: string) {
		this.bonusType = value;
	}

	changePeriod(value: string) {
		if (value) {
			this.period = value;
			this.getPeriodStatistics();
		} else {
			this.getStatistics();
		}
	}

	openModalDetail(values: User) {
		const { destroy } = openSuccessModal({
			width: '70%',
			keyboard: false,
			textContent: (
				<TransactionsModal user={values} onCancel={() => destroy()} />
			),
		});
	}
}
