import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CabinetLayout } from '../../../../components/Cabinet/Layout';
import { PageTitle } from '../../../../components/Cabinet/PageTitle';
import DatePickerField from '../../../../components/DatePickerField';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import {
	CABINET_SETTINGS,
	CABINET_SETTINGS_CHANGE_PASSORD,
} from '../../../../pathNames';
import { userStore } from '../../../../stores';
import { UserProfiles } from '../../../../types/User';
import styles from './styles.module.scss';

const CabinetSettingsProfile = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const requiredText = t('Обязательное поле');

	const validationSchema = Yup.object().shape({
		last_name: Yup.string().required(requiredText),
		first_name: Yup.string().required(requiredText),
		birthday: Yup.string().required(requiredText),
		gender: Yup.string().required(requiredText),
	});
	return (
		<CabinetLayout>
			<PageTitle title={t('Настройки профиля')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col md={18} xs={23}>
						<Row justify="center">
							<h3 className={styles.title}>{t('Настройки личных данных')}</h3>
						</Row>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								...userStore.userInfo,
								...userStore.userInfo.profiles,
							}}
							onSubmit={(values: UserProfiles) => {
								userStore.changeUserInfo(values);
							}}
						>
							{({
								handleSubmit,
								setFieldValue,
								initialValues,
								handleBlur,
								touched,
								errors,
								values,
							}: any) => (
								<Observer>
									{() => (
										<form onSubmit={handleSubmit}>
											<Row gutter={12} align="top" justify="space-between">
												<Col lg={12}>
													<Row gutter={[0, 8]}>
														<Col span={24}>
															<InputField
																onBlur={handleBlur}
																name="lastName"
																title={t('Фамилия')}
																placeholder={t('Фамилия')}
																required={true}
																className={styles.input}
																value={values.last_name}
																defaultValue={initialValues.profiles?.last_name}
																errorMessage={
																	touched.last_name && errors.last_name
																}
																onChange={(e: any) =>
																	setFieldValue('last_name', e.target.value)
																}
															/>
														</Col>
														<Col span={24}>
															<InputField
																onBlur={handleBlur}
																name="firstName"
																title={t('Имя')}
																placeholder={t('Имя')}
																required={true}
																className={styles.input}
																value={values.first_name}
																defaultValue={
																	initialValues.profiles?.first_name
																}
																errorMessage={
																	touched.first_name && errors.first_name
																}
																onChange={(e: any) =>
																	setFieldValue('first_name', e.target.value)
																}
															/>
														</Col>
														<Col span={24}>
															<InputField
																onBlur={handleBlur}
																name="middleName"
																title={t('Отчество')}
																placeholder={t('Отчество')}
																required={true}
																className={styles.input}
																value={values.middle_name}
																defaultValue={
																	initialValues.profiles?.middle_name
																}
																errorMessage={
																	touched.middle_name && errors.middle_name
																}
																onChange={(e: any) =>
																	setFieldValue('middle_name', e.target.value)
																}
															/>
														</Col>
														<Col span={24}>
															<InputField
																name="phone"
																onBlur={handleBlur}
																disabled
																title={t('Номер телефона')}
																placeholder={t('Номер телефона')}
																required={true}
																className={styles.input}
																value={values.phone}
																defaultValue={initialValues.phone}
																errorMessage={touched.phone && errors.phone}
																onChange={(event: any) =>
																	setFieldValue('phone', event.target.value)
																}
															/>
														</Col>
														<Col span={24}>
															<DatePickerField
																showToday={false}
																className={styles.datePicker}
																defaultValue={dayjs(
																	initialValues.profiles?.birthday
																)}
																title={t('Дата рождения')}
																style={{ width: '100%' }}
																format="DD.MM.YYYY"
																disabledDate={(current: any) =>
																	current > dayjs().endOf('day')
																}
																placeholder={t('Дата рождения')}
																errorMessage={
																	touched.birthday && errors.birthday
																}
																onChange={(date: any) =>
																	setFieldValue(
																		'birthday',
																		date.format('YYYY-MM-DD')
																	)
																}
															/>
														</Col>
													</Row>
												</Col>
												<Col lg={12}>
													<Row gutter={[0, 8]}>
														<Col span={6}>
															<SelectField
																bordered={false}
																title={t('Пол')}
																placeholder={t('Пол')}
																options={[
																	{
																		label: t<string>('Мужской'),
																		name: t('Мужской'),
																		value: 1,
																	},
																	{
																		label: t<string>('Женский'),
																		name: t('Женский'),
																		value: 0 as number,
																	},
																]}
																defaultValue={String(
																	initialValues.profiles?.gender
																)}
																value={values.gender}
																className={styles.select}
																errorMessage={touched.gender && errors.gender}
																onChange={(value: string) =>
																	setFieldValue('gender', Number(value))
																}
															/>
														</Col>
														<Col span={24}>
															<InputField
																onBlur={handleBlur}
																name="email"
																title={t('Электронный адрес')}
																placeholder={t('Электронный адрес')}
																required={false}
																className={styles.input}
																value={values.email}
																defaultValue={initialValues.email}
																errorMessage={touched.email && errors.email}
																onChange={(e: any) =>
																	setFieldValue('email', e.target.value)
																}
															/>
														</Col>
														<Col span={24}>
															<InputField
																fieldType="password"
																onBlur={handleBlur}
																name="password"
																disabled
																bordered={false}
																title={t('Пароль')}
																placeholder={t('Пароль')}
																required={true}
																className={styles.input}
																defaultValue="*******"
															/>
															<NavLink to={CABINET_SETTINGS_CHANGE_PASSORD}>
																{t('Сменить пароль')}
															</NavLink>
														</Col>
													</Row>
												</Col>
											</Row>
											<br />
											<br />
											<br />
											<Row gutter={10} align="bottom" justify="center">
												<Col span={12}>
													<Button
														onClick={() => navigate(CABINET_SETTINGS)}
														block
														size="large"
													>
														{t('Отменить')}
													</Button>
												</Col>
												<Col span={12}>
													<Button
														disabled={userStore.loading}
														loading={userStore.loading}
														htmlType="submit"
														block
														size="large"
														type="primary"
													>
														{t('Сохранить')}
													</Button>
												</Col>
											</Row>
										</form>
									)}
								</Observer>
							)}
						</Formik>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
});

export default CabinetSettingsProfile;
