import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { makeAutoObservable } from 'mobx';
import React from 'react';
dayjs.extend(customParseFormat);

import { CreateEditQueue } from '../../components/Distributors/AdminCreateEditQueue';
import openSuccessModal from '../../components/showModalFunc';
import authAxiosClient from '../../utils/authAxiosClient';

export enum CalendarDateType {
	Month = 'month',
	Week = 'week',
	Day = 'day',
	dateFormat = 'DD.MM.YYYY',
}

interface Filters {
	department_id?: number | string;
	product_id?: number | string;
	start?: string | null;
	end?: string | null;
	name?: string | null;
	month?: any;
}

export class AdminCalendarStore {
	loading = false;
	timeList: any = [];
	time: any = [];

	currentDate: any = dayjs().startOf('day');
	currentMonth: any = dayjs().startOf('day');

	currentWeek: string[] = ['1', '2', '3', '4', '5', '6', '7'];
	filters: Filters = {};

	constructor() {
		makeAutoObservable(this);
		this.getArrayOfWeek();
	}

	openModalCreate() {
		const { destroy } = openSuccessModal({
			width: '75%',
			keyboard: false,
			textContent: (
				<CreateEditQueue
					onSubmit={async (values: any) => {
						await this.createTimeForRecording(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}
	openModalEdit(quene: any) {
		const { destroy } = openSuccessModal({
			width: '75%',
			keyboard: false,
			textContent: (
				<CreateEditQueue
					initialValues={quene}
					onSubmit={async (values: any) => {
						await this.editTimeForRecording(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	async getTimeList(page = 1, pageSize = 10, sortField = '') {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(`/admin/time/list`, {
				params: {
					...this.filters,
					page,
					'per-page': pageSize,
					sort: sortField,
				},
			});
			this.timeList = data;
		} finally {
			this.setLoading(false);
		}
	}

	async getTime() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get('/admin/time', {
				params: {
					...this.filters,
				},
			});
			this.time = data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async createTimeForRecording(values: any) {
		this.setLoading(true);
		try {
			await authAxiosClient.post('/admin/time', values);
			await this.getTimeList();
		} finally {
			this.setLoading(false);
		}
	}

	async editTimeForRecording(values: any) {
		this.setLoading(true);
		try {
			await authAxiosClient.put(`/ admin / time / ${values.id}`, values);
			await this.getTimeList();
		} finally {
			this.setLoading(false);
		}
	}

	async deleteQuene(id: number) {
		this.setLoading(true);
		try {
			await authAxiosClient.delete(`/ admin / time / ${id}`);
			await this.getTimeList();
		} finally {
			this.setLoading(false);
		}
	}

	resetCurrentDate() {
		this.currentDate = dayjs().startOf('day');
		this.getArrayOfWeek();
		this.setFilters({});
		this.time = [];
	}

	setFilters = (filters: any) => {
		if (Object.keys(filters).length) {
			this.filters = { ...this.filters, ...filters };
		} else {
			this.filters = {};
		}
	};

	clearFilters() {
		this.filters = {} as Filters;
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	getTimeUnix = (date: any) => dayjs(date, CalendarDateType.dateFormat).unix();
	getStartOfDay = (date: any) =>
		dayjs(date, CalendarDateType.dateFormat)
			.startOf(CalendarDateType.Day)
			.unix();
	getEndOfDay = (date: any) =>
		dayjs(date, CalendarDateType.dateFormat).endOf(CalendarDateType.Day).unix();

	getArrayOfWeek = () => {
		for (let i = 1; i <= this.currentWeek.length; i++) {
			this.currentWeek[i - 1] = this.currentDate
				.day(i)
				.format(CalendarDateType.dateFormat);
		}
	};

	changeWeek = (type: string) => {
		if (type === 'subtract') {
			this.currentDate = this.currentDate.subtract(7, 'day');
		} else if (type === 'add') {
			this.currentDate = this.currentDate.add(7, 'day');
		}
		this.getArrayOfWeek();
		this.setFilters({
			...this.filters,
			start: this.getTimeUnix(this.currentWeek[0]),
			end: this.getTimeUnix(this.currentWeek[6]),
		});
		this.getTime();
	};

	changeMonth = (type: string) => {
		if (type === 'subtract') {
			this.currentMonth = this.currentMonth.subtract(1, 'month');
		} else if (type === 'add') {
			this.currentMonth = this.currentMonth.add(1, 'month');
		}
		this.setFilters({ month: this.currentMonth.format('YYYY-MM-DD') });
		this.getTimeList();
	};
}
