import { EditOutlined } from '@ant-design/icons';
import { Col, Image, Row, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import settings from '../../../settings';
import { userStore } from '../../../stores';
import { getBase64 } from '../../../utils/toBase64';
import styles from './styles.module.scss';

interface PageProps {
	editIcon?: boolean;
	detailInfo?: boolean;
}

export const UserInfo: FunctionComponent<PageProps> = observer(
	({ editIcon, detailInfo }: any) => {
		const { profiles } = userStore.userInfo;
		const { t } = useTranslation();
		return (
			<Row justify="center">
				<Col>
					<div className={styles.listTop}>
						<div className={styles.avatar}>
							<Image
								preview={false}
								loading="eager"
								src={`${settings.apiUrl}${profiles?.avatar}`}
								fallback={
									profiles?.gender === 1
										? '/images/male.png'
										: '/images/female.png'
								}
							/>
							{editIcon && (
								<Upload
									beforeUpload={() => false}
									onChange={(info: any) => {
										if (info.fileList.length) {
											getBase64(
												info.fileList[0].originFileObj,
												(imageUrl: any) => {
													userStore.changeAvatar(imageUrl);
												}
											);
										}
									}}
									showUploadList={false}
								>
									<EditOutlined className={styles.editIcon} />
								</Upload>
							)}
						</div>
						<div className={styles.name}>
							{profiles?.first_name} {profiles?.last_name}
						</div>
						{detailInfo && (
							<Row
								className={styles.referalCashInfo}
								gutter={16}
								justify="center"
							>
								<Col>
									<div>{userStore.userInfo.referralCount}</div>
									<div>{t('Рефералов')}</div>
								</Col>
								<Col>
									<div>{userStore.userInfo.referrer}</div>
									<div>{t('Кэш бонусы')}</div>
								</Col>
							</Row>
						)}
					</div>
				</Col>
			</Row>
		);
	}
);

UserInfo.defaultProps = {
	editIcon: false,
	detailInfo: false,
};
