import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const { TextArea } = Input;

export const NotificationCreate = ({ onSubmit, destroyModal }: any) => {
	const { t } = useTranslation();
	const validation = [
		{
			required: true,
			message: t('Обязательное поле'),
		},
	];
	const [form] = Form.useForm();
	const initialValues = {
		titleRus: '',
		textRus: '',
		titleUkr: '',
		textUkr: '',
	};
	const onFinish = (values: any) => {
		onSubmit(values);
		destroyModal();
	};
	return (
		<div className={styles.mrgTop}>
			<Form
				initialValues={initialValues}
				layout="vertical"
				onFinish={onFinish}
				form={form}
			>
				<Row justify="end" align="middle">
					<Col span={12}>
						<span>Русский</span>
						<img
							className={styles.langIcon}
							src="/images/russia.webp"
							alt="russia"
						/>
					</Col>
					<Col span={12}>
						<span>Українська</span>
						<img
							className={styles.langIcon}
							src="/images/ukraine.webp"
							alt="ukraine"
						/>
					</Col>
				</Row>
				<br />
				<Row gutter={12}>
					<Col span={12}>
						<Form.Item
							hasFeedback
							name="titleRus"
							label="Заголовок сообщения"
							className={styles.input}
							rules={validation}
						>
							<Input
								placeholder="Заголовок сообщения"
								name="titleRus"
								className={styles.input}
							/>
						</Form.Item>
						<Form.Item
							hasFeedback
							name="textRus"
							label="Текст сообщения"
							rules={validation}
						>
							<TextArea placeholder="Текст сообщения" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							hasFeedback
							name="titleUkr"
							label="Заголовок повідомлення"
							className={styles.input}
							rules={validation}
						>
							<Input
								placeholder="Заголовок повідомлення"
								name="titleUkr"
								className={styles.input}
							/>
						</Form.Item>
						<Form.Item
							hasFeedback
							name="textUkr"
							label="Текст повідомлення"
							rules={validation}
						>
							<TextArea placeholder="Текст повідомлення" />
						</Form.Item>
					</Col>
				</Row>
				<Row justify="end">
					<Col>
						<Button htmlType="submit" type="primary" size="large">
							Отправить
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
};
