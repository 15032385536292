import React from 'react';
import { makeAutoObservable } from 'mobx';
import openSuccessModal from '../../components/showModalFunc';

import { User, Users } from '../../types/User';
import authAxiosClient from '../../utils/authAxiosClient';
import { EditUserProfile } from '../../components/Modals/EditUserProfile/index';

interface Filters {
	ageStart?: string;
	ageEnd?: string;
	searchField?: string;
	id?: number;
}

interface SelectedAdmin {
	permissions: string[];
	departments: any[];
	user_id: number | null;
	role: string | null;
}

export class AdminUsersStore {
	users = {} as Users;
	filters = {} as Filters;
	selectedAdmin = {} as SelectedAdmin;
	usersWithRoles = {};
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async getUsers(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(`/admin/users`, {
				params: {
					...this.filters,
					page,
					'per-page': pageSize,
					sort: sortField,
				},
			});
			this.users = data;
		} finally {
			this.setLoading(false);
		}
	}

	async getUsersWithRoles(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get('/admin/users/position', {
				params: { page, 'per-page': pageSize, sort: sortField },
			});
			this.usersWithRoles = data;
		} finally {
			this.setLoading(false);
		}
	}

	async editUserPermissions(values: any) {
		this.setLoading(true);
		const { user_id } = values;
		try {
			await authAxiosClient.put(
				`/admin/user/${user_id}/set-permissions`,
				values
			);
		} finally {
			this.setLoading(false);
		}
	}

	async deleteUserRoles(user_id: number) {
		this.setLoading(true);
		try {
			await authAxiosClient.put(`/admin/user/${user_id}/delete-role`);
			await this.getUsersWithRoles();
		} finally {
			this.setLoading(false);
		}
	}

	async deleteUser(user_id: number) {
		this.setLoading(true);
		try {
			await authAxiosClient.delete(`/admin/user/${user_id}`);
			await this.getUsers();
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	setFilters(filters: any) {
		this.filters = { ...this.filters, ...filters };
	}

	setSelectedAdmin(admin: SelectedAdmin | null) {
		if (admin) {
			this.selectedAdmin = admin;
		} else {
			this.selectedAdmin = {} as SelectedAdmin;
		}
	}

	openEditUserModal(user: User) {
		const { destroy } = openSuccessModal({
			title: 'Редактирование профиля',
			width: '50%',
			keyboard: false,
			textContent: (
				<EditUserProfile
					user={user}
					onSubmit={values => {
						this.editUserProfile(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	async editUserProfile(values: any) {
		const { user_id } = values;
		this.setLoading(true);
		try {
			await authAxiosClient.put(`/admin/user/${user_id}`, values);
			await this.getUsers();
		} finally {
			this.setLoading(false);
		}
	}

	clearFilters() {
		this.filters = {};
	}
}
