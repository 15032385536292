import { makeAutoObservable } from 'mobx';

import axiosClient from '../utils/axiosClient';
import Storage, { StorageType } from './Storage';

import { authStore } from './index';

const STORAGE_TYPE = StorageType.SessionStorage;
const JWT_TOKEN_STORAGE_NAME = 'accessToken';

type Token = {
	access_token: string;
	expired_at: number;
	msg: string;
	description?: string;
	code: number;
	refresh_token: string;
	role: string;
	permissions: string[];
};

interface SignInRequestValues {
	phone: string;
	password: string;
}

export class AuthStore {
	token: Token = {} as Token;
	loading = false;
	badCredentials = false;
	authorized = false;

	constructor() {
		makeAutoObservable(this);
		this.getToken();
	}

	deleteToken = () => {
		Storage(STORAGE_TYPE).remove(JWT_TOKEN_STORAGE_NAME);
		this.token = {} as Token;
	};

	saveToken = (token: Token) => {
		Storage(STORAGE_TYPE).put(JWT_TOKEN_STORAGE_NAME, token);
		this.token = token;
	};

	getToken = () => {
		this.token =
			Storage(STORAGE_TYPE).get(JWT_TOKEN_STORAGE_NAME) || ({} as Token);
		if (this.token.code === 1) {
			this.authorized = true;
		}
	};

	signOut() {
		authStore.deleteToken();
		this.authorized = false;
	}

	async signIn(values: SignInRequestValues) {
		this.loading = true;
		this.badCredentials = false;

		try {
			const { data } = await axiosClient.post('/user/login', values);
			authStore.saveToken(data.data);
			this.authorized = true;
		} finally {
			this.loading = false;
		}
	}
}
