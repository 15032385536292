import React from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { adminServicesStore } from '../../../stores';
import { EmptyData } from '../../EmptyData';

const ServicesSelection = ({ ...props }: any) => {
	const { t } = useTranslation();
	const { data } = adminServicesStore.services;

	React.useEffect(() => {
		adminServicesStore.getServices();
	}, []);

	const searchByName = (input: string, option: any) => {
		const childrens = option.props?.children
			.map((item: string) => item.toLowerCase())
			.filter((item: string) => item !== ' ');
		return childrens.filter((item: string) => item.indexOf(input) > -1).length;
	};

	return (
		<Select
			{...props}
			placeholder={t('Выберите услугу')}
			loading={adminServicesStore.loading}
			notFoundContent={<EmptyData />}
			optionFilterProp="children"
			filterOption={searchByName}
			style={{ width: '130px' }}
			allowClear
		>
			{[] ||
				data.map((item: any) => (
					<Select.Option key={item.created_at} value={item.id}>
						{item.name}
					</Select.Option>
				))}
		</Select>
	);
};

export default observer(ServicesSelection);
