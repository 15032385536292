import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import settings from '../../../settings';
import { adminTransactionsStore } from '../../../stores';
import { User } from '../../../types/User';
import { AdminTable } from '../../Admin/Table';
import AdminUserInfo from '../../Admin/UserInfo/AdminUserInfo';
import styles from './styles.module.scss';

type Props = {
	onCancel: () => void;
	user: User;
	title?: string;
};

export const TransactionsModal = observer(
	({ onCancel, user, title = '' }: Props) => {
		const { t } = useTranslation();
		const { data, _meta } = adminTransactionsStore.userTransactions;
		const paginationHandler = (page: any, pageSize: any, sortField: string) => {
			adminTransactionsStore.getUserTransactions({
				page,
				pageSize,
				sortField,
				id: user.id,
			});
		};
		const columns = [
			{
				title: 'ID',
				dataIndex: 'id',
			},
			{
				title: t('Услуга'),
				dataIndex: 'note',
			},
			{
				title: 'Дата',
				dataIndex: 'created_at',
				render: (date: any) => <>{new Date(date * 1000).toLocaleString()}</>,
				sorter: (a: any, b: any) => a.created_at - b.created_at,
			},
			{
				title: t('Цена'),
				dataIndex: 'amount',
				sorter: (a: any, b: any) => a.amount - b.amount,
			},
			{
				dataIndex: 'local_code',
				render: (code: any) => (
					<a href={`${settings.apiUrl}/v1/transactions/order/${code}`}>
						<FilePdfOutlined
							style={{ fontSize: '18px' }}
							title={t('Скачать квитанцию')}
						/>
					</a>
				),
			},
			{
				title: t('Статус'),
				render: (item: any) => {
					const stls = [
						styles.grey,
						styles.warning,
						styles.success,
						styles.danger,
					];
					const status = item?.status || 0;
					const statusName = item?.statusName || 'Невідомо';

					return <p className={stls[status]}>{statusName}</p>;
				},
			},
		];

		React.useEffect(() => {
			adminTransactionsStore.getUserTransactions({ id: user.id });
		}, []);

		return (
			<>
				<AdminUserInfo user={user} loading={adminTransactionsStore.loading} />
				<h3 className={styles.title}>{title}</h3>
				<div className={styles.container}>
					<AdminTable
						pagination={{ ..._meta, onChange: paginationHandler }}
						loading={adminTransactionsStore.loading}
						columns={columns}
						data={data}
					/>
				</div>
				<Row justify="center" gutter={8}>
					<Col>
						<Button
							className={styles.closeButton}
							type="primary"
							onClick={onCancel}
						>
							{t('Закрыть')}
						</Button>
					</Col>
				</Row>
			</>
		);
	}
);
