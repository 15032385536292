import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PASSWORD_RECOVERY, SIGN_UP } from '../../../../pathNames';
import { authStore } from '../../../../stores';
import styles from '../signIn.module.scss';

const SignInSide = observer(() => {
	const { t } = useTranslation();

	const requiredText = t('Обязательное поле');
	const phoneRegexp = /^0\d{9}$/g;
	const validationSchema = Yup.object().shape({
		password: Yup.string().required(requiredText),
		phone: Yup.string()
			.matches(phoneRegexp, t<string>('Неправильный номер телефона'))
			.required(requiredText),
	});

	const getStatus = (
		errorField: string | undefined,
		touched: boolean | undefined
	) => {
		if (touched) {
			if (errorField) {
				return 'error';
			}
			return 'success';
		}
	};

	const onSubmit = async (
		values: { password: string; phone: string },
		{ setErrors }: any
	) => {
		const data = {
			phone: `38${values.phone}`,
			password: values.password,
		};
		try {
			await authStore.signIn(data);
		} catch (error: any) {
			const err = {
				[error.response.data?.data[0]?.field]:
					error.response.data?.data[0]?.message,
			};

			setErrors(err);
		}
	};

	return (
		<Col xs={{ span: 24 }} md={{ span: 14 }}>
			<Row justify="center" align="middle" className={styles.signIn}>
				<Col>
					<Row justify="center">
						<img
							className={styles.img}
							src="/images/logo-nav.webp"
							alt="logo"
						/>
					</Row>
					<h2 className={styles.title}>{t('Вход')}</h2>
					{authStore.badCredentials && (
						<Alert
							className={styles.alertCredentials}
							type="error"
							message={
								<div className={styles.errorCredentials}>
									<ExclamationCircleOutlined
										className={styles.errorCredentialsIcon}
									/>
									<span>{t('Неправильный телефон или пароль')}.</span>
								</div>
							}
						/>
					)}
					<Formik
						validateOnMount={true}
						initialValues={{ phone: '', password: '' }}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({
							values,
							handleSubmit,
							isValid,
							touched,
							errors,
							setFieldValue,
							setFieldTouched,
						}) => (
							<Observer>
								{() => (
									<form onSubmit={handleSubmit}>
										<span>{t('Номер телефона')}</span>
										<Form.Item
											hasFeedback
											validateStatus={getStatus(errors.phone, touched.phone)}
											help={touched.phone && errors.phone}
										>
											<Input
												prefix="+38"
												required={true}
												className={styles.formControl}
												value={values.phone}
												onBlur={() => setFieldTouched('phone')}
												onChange={event =>
													setFieldValue('phone', event.target.value)
												}
											/>
										</Form.Item>

										<span>{t('Пароль')}</span>
										<Form.Item
											hasFeedback
											validateStatus={getStatus(
												errors.password,
												touched.password
											)}
											help={touched.password && errors.password}
										>
											<Input
												type="password"
												required={true}
												className={styles.formControl}
												value={values.password}
												onBlur={() => setFieldTouched('password')}
												onChange={event =>
													setFieldValue('password', event.target.value)
												}
											/>
										</Form.Item>
										<Button
											type="primary"
											className={styles.button}
											htmlType="submit"
											size="large"
											loading={authStore.loading}
											disabled={authStore.loading || !isValid}
										>
											{t('Вход')}
										</Button>
									</form>
								)}
							</Observer>
						)}
					</Formik>
					<Link to={PASSWORD_RECOVERY} className={styles.link}>
						{t('Забыли пароль')}
					</Link>
					<Link to={SIGN_UP} className={styles.link}>
						<span>{t('Нет аккаунта?')}</span> {t('Зарегистрируйтесь')}
					</Link>
				</Col>
			</Row>
		</Col>
	);
});

export default SignInSide;
