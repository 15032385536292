import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import i18n from 'i18next';

export const showSuccessNotification = (
	response: AxiosResponse | undefined
): void => {
	if (
		response &&
		(response?.config?.method === 'post' || response?.config?.method === 'put')
	) {
		notification.success({
			message: response.data?.data?.msg || i18n.t('Успешно'),
			placement: 'bottomLeft',
		});
	}
	if (response && response?.config?.method === 'delete') {
		notification.success({
			message: i18n.t<string>('Удалено успешно'),
			placement: 'bottomLeft',
		});
	}
};
