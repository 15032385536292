import { CoffeeOutlined, LineChartOutlined } from '@ant-design/icons';
import { List, Tabs } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { CABINET_STATISTICS } from '../../../pathNames';
import { transactionsStore } from '../../../stores';
import { EmptyData } from '../../EmptyData';
import styles from './styles.module.scss';
const { TabPane } = Tabs;

export const TransactionList = observer(({ onDetail }: any) => {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	React.useEffect(() => {
		transactionsStore.getTransactions();
	}, []);

	const tabs = [
		{ key: '0,1', name: 'Оплата' },
		{ key: 2, name: t('Пополнение') },
		{ key: 3, name: t('Вывод') },
	];

	const getColorByType = (type: number) => {
		switch (type) {
			case 0:
			case 1:
				return styles.pay;
			case 2:
				return styles.fillIn;
			case 3:
				return styles.withDraw;
		}
	};

	return (
		<>
			<Tabs
				tabBarGutter={22}
				tabBarStyle={{ color: '#A5A5A5', fontWeight: 600, fontSize: '18px' }}
				onChange={async (key: string) => {
					setLoading(true);
					transactionsStore.changeTransactionType(key);
					await transactionsStore.getTransactions();
					setLoading(false);
				}}
				activeKey={transactionsStore.transactionType}
			>
				<TabPane
					tab={
						<NavLink to={CABINET_STATISTICS}>
							<LineChartOutlined className={styles.statisticIcon} />
						</NavLink>
					}
				/>
				{tabs.map(tabData => (
					<TabPane disabled={loading} tab={tabData.name} key={tabData.key} />
				))}
			</Tabs>
			<List
				size="large"
				loading={loading}
				pagination={{}}
				locale={{
					emptyText: (
						<EmptyData
							icon={<CoffeeOutlined />}
							text={t('У вас еще нет транзакций')}
						/>
					),
				}}
				itemLayout="horizontal"
				dataSource={transactionsStore.transactions}
				renderItem={(item: any) => (
					<List.Item
						style={{ padding: '0 10px 0 0' }}
						onClick={async () => {
							setLoading(true);
							await onDetail(item.id);
							setLoading(false);
						}}
					>
						<List.Item.Meta
							title={
								<div className={styles.transactionHeader}>
									<div>
										<span className={styles.amount}>
											{item.type === 3
												? item.withdrawal_amount
												: item.fullPrice}{' '}
											грн
										</span>
										<span className={getColorByType(item.type)}>
											{item.note}
										</span>
									</div>

									<div className={styles.grayText}>
										{dayjs.unix(item.created_at).format('DD.MM.YYYY HH:mm')}
									</div>
								</div>
							}
							description={
								<span className={styles.description}>{item.description}</span>
							}
						/>
					</List.Item>
				)}
			/>
		</>
	);
});
