import { makeAutoObservable } from 'mobx';

export class CabinetSideBarStore {
	collapsed = true;
	collapseWidth = 112;
	constructor() {
		makeAutoObservable(this);
	}

	setCollapsed() {
		this.collapsed = !this.collapsed;
	}
	setCollapsedWidth(value: boolean) {
		if (value) {
			this.collapseWidth = 0;
		} else {
			this.collapseWidth = 112;
		}
	}
}
