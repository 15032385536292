import { LeftOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputRef, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CABINET_DEPOSITE, SIGN_UP } from '../../../pathNames';
import { referalsStore } from '../../../stores';
import styles from './styles.module.scss';

interface Props {
	onBack: () => void;
	toFirstPage?: () => void;
}

export const InviteReferals = observer(({ onBack, toFirstPage }: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const referalLink = React.useRef<InputRef | null>(null);

	const copyLink = () => {
		if (referalLink && referalLink.current) {
			referalLink.current.select();
			document.execCommand('copy');
		}
	};

	React.useEffect(() => {
		referalsStore.createReferalLink();
	}, []);

	const trans = t('Поколение');
	const cashbon = t('кеш бонусов');
	return (
		<div className={styles.scrollable}>
			<Row className={styles.navigation} justify="space-between">
				{onBack && (
					<Col>
						<Button onClick={onBack} type="link" block>
							<LeftOutlined className={styles.icon} />
							{t('Назад')}
						</Button>
					</Col>
				)}
				{toFirstPage && (
					<Col>
						<Button onClick={toFirstPage} type="link" block>
							<UndoOutlined className={styles.icon} />
						</Button>
					</Col>
				)}
			</Row>
			<div className={styles.listTop}>
				<Row gutter={24} justify="center">
					<Col md={24}>
						<h3 className={styles.title}>{t('Пригласить реферала')}</h3>
					</Col>

					<Col md={22}>
						{!referalsStore.referalLink.code && (
							<p className={styles.dangerText}>
								Для того чтобы Вы могли пригласить других пользователей вам
								необходимо осуществить как минимум одну оплату или одно
								пополнение.
							</p>
						)}
						<p>{t('Обьяснение реферальной системи-1')}</p>
						<ul style={{ listStyle: 'none', padding: 0 }}>
							<li>
								1 {trans} 10% - 1000 {cashbon}
							</li>
							<li>
								2 {trans} 7% - 7000 {cashbon}
							</li>
							<li>
								3 {trans} 5% - 50000 {cashbon}
							</li>
							<li>
								4 {trans} 3% - 300000 {cashbon}
							</li>
							<li>
								5 {trans} 2% - 2000000 {cashbon}
							</li>
							<li>
								6 {trans} 1% - 10000000 {cashbon}
							</li>
							<li>
								7 {trans} 0,1% - 10000000 {cashbon}
							</li>
						</ul>
						<p>{t('Обьяснение реферальной системи-2')}</p>
					</Col>
					{referalsStore.referalLink.code ? (
						<Col md={22}>
							<Row gutter={3} align="middle">
								<span>{t('Скопируйте ссылку, что бы поделиться ею')}</span>
								<Col span={18}>
									<Input
										ref={referalLink}
										value={`${window.location.origin}${SIGN_UP}/${referalsStore.referalLink.code}`}
									/>
								</Col>
								<Col span={4}>
									<Button size="middle" onClick={copyLink} type="primary">
										{t('Копировать')}
									</Button>
								</Col>
							</Row>
						</Col>
					) : (
						<Col md={22}>
							<Button
								onClick={() => navigate(CABINET_DEPOSITE)}
								block
								size="middle"
								type="primary"
							>
								{t('Пополнить')}
							</Button>
						</Col>
					)}
				</Row>
			</div>
		</div>
	);
});
