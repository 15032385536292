import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ua from './translations/uk.json';

const resources = {
	uk: {
		translation: ua,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'uk',
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
