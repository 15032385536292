import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { EmptyData } from '../../EmptyData';
import styles from './styles.module.scss';

export const AdminTable = ({
	children,
	data = [],
	pagination,
	className,
	localeText,
	localeIcon,
	loading,
	size = 'middle',
	...props
}: any) => {
	const { t } = useTranslation();
	const { options } = pagination;

	return (
		<>
			{children}
			<Table
				rowKey={record => record.id}
				total={data.length}
				loading={loading}
				onChange={(pagination: any, extra: any, column: any) => {
					const {
						current,
						pageSize,
						options: { onChange },
					} = pagination;
					const { order, columnKey } = column;
					let { field } = column;
					let direction = '';
					if (order === 'ascend') {
						direction = '';
					} else if (order === 'descend') {
						direction = '-';
					} else if (order === undefined) {
						field = '';
					}
					const sortField = direction + '' + (field || columnKey);
					onChange(current, pageSize, sortField);
				}}
				{...props}
				locale={{
					emptyText: <EmptyData icon={localeIcon} text={localeText} />,
				}}
				size={size}
				className={className || styles.table}
				dataSource={data}
				pagination={
					pagination && {
						position: ['bottomCenter'],
						hideOnSinglePage: true,
						showSizeChanger: true,
						current: options?.currentPage,
						pageSize: options?.perPage,
						total: options?.totalCount,
						pageSizeOptions: [10, 30, 50],
						showTotal: (total: number) => `${t('Всего найдено')}: ${total}`,
						...pagination,
					}
				}
			/>
		</>
	);
};
