import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const EditDeleteIcons = ({
	onEdit,
	onDelete,
	onOpen,
	disabled,
}: any) => {
	const { t } = useTranslation();

	return (
		<div style={{ display: 'flex' }}>
			{onOpen && (
				<Button title={t('Детально')} type="text" disabled={disabled}>
					<EyeOutlined onClick={onOpen} className={styles.controlIcon} />
				</Button>
			)}
			{onEdit && (
				<Button title={t('Редактировать')} type="text" disabled={disabled}>
					<EditOutlined onClick={onEdit} className={styles.controlIcon} />
				</Button>
			)}
			{onDelete && (
				<Popconfirm
					title={t('Вы увереныx что хотите удалить?')}
					onConfirm={onDelete}
				>
					<Button title={t('Удалить')} type="text" disabled={disabled}>
						<DeleteOutlined className={styles.controlIcon} />
					</Button>{' '}
				</Popconfirm>
			)}
		</div>
	);
};
