import { Button, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminNotificationsStore, adminUsersStore } from '../../../../stores';
import styles from './styles.module.scss';

const Notifications = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminUsersStore;
	const { _meta, data } = adminUsersStore.users;

	const onSearch = (name: string) => {
		if (!name) {
			adminUsersStore.setFilters({ name: null });
		} else {
			adminUsersStore.setFilters({ name });
		}
		adminUsersStore.getUsers();
	};
	const ageHandler = (value: string) => {
		if (!value) {
			adminUsersStore.setFilters({ birthday_start: null, birthday_end: null });
		} else {
			const [birthday_start, birthday_end] = value.split('-');
			adminUsersStore.setFilters({ birthday_start, birthday_end });
		}
		adminUsersStore.getUsers();
	};
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminUsersStore.getUsers(page, pageSize, sortField);
	};

	React.useEffect(() => {
		adminUsersStore.getUsers(1, 10);
		return () => {
			adminUsersStore.clearFilters();
		};
	}, []);
	const columns = [
		{
			title: 'ID ',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('ФИО'),
			dataIndex: 'profiles',
			render: (profiles: any) => (
				<>
					{profiles.first_name} {profiles.last_name}
				</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: t('Меднеан бонусы'),
			dataIndex: 'mednean_bon',
			sorter: true,
		},
		{
			title: t('Кэш бонусы'),
			dataIndex: 'cash_bon',
			sorter: true,
		},
		{
			title: t('Дата создания'),
			dataIndex: 'created_at',
			render: (created_at: any) => (
				<>{new Date(created_at * 1000).toLocaleDateString()}</>
			),
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onOpen={() => adminNotificationsStore.openDetailModal(item)}
				/>
			),
		},
	];

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.createBtn}
					type="primary"
					onClick={() => adminNotificationsStore.openCreateNotifModal()}
				>
					{t('Создать уведомление')}
				</Button>
			</Row>
			<div className={styles.container}>
				<AdminTable
					loading={adminUsersStore.loading}
					pagination={{
						options: {
							..._meta,
							onChange: paginationHandler,
						},
					}}
					className={styles.table}
					columns={columns}
					data={data}
				>
					<TableFilters
						searchFieldConfigs={{
							loading,
							disabled: loading,
							className: styles.btnSearch,
							placeholder: t('Введите для поиска'),
							onSearch: (value: string) => onSearch(value),
						}}
						ageFieldConfigs={{
							onChange: ageHandler,
							loading,
							disabled: loading,
						}}
					/>
				</AdminTable>
			</div>
		</AdminLayout>
	);
});

export default Notifications;
