import * as React from 'react';

import { AdminLayout } from '../../components/Admin/Layout';

const Admin = () => (
	<AdminLayout>
		<h1>Добро пожаловать в АДМИНКУ!</h1>
	</AdminLayout>
);

export default Admin;
