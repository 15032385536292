import { Layout } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from '../NavBar';
import SideBar from '../SideBar';
import TopBar from '../TopBar';
import styles from './styles.module.scss';

const { Content } = Layout;

export const CabinetLayout = ({ children }: { children: React.ReactNode }) => {
	const { t } = useTranslation();
	return (
		<Layout className={styles.personalCabinet}>
			<SideBar />
			<Layout className={styles.content}>
				<header className={styles.mt_10}>
					<TopBar />
				</header>
				<div className={styles.title}>{t('Личный кабинет')}</div>
				<NavBar />
				<Content>{children}</Content>
			</Layout>
		</Layout>
	);
};
