import { Col, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../common/DatePicker';
import DepartmentSelection from '../../Selections/Departments';
import ServicesSelection from '../../Selections/Services';
import styles from './styles.module.scss';

interface defaultProps {
	loading?: boolean;
	disabled?: boolean;
	className?: any;
	placeholder?: string;
	onSearch?: any;
	onSelect?: any;
	onChange?: any;
	value?: string | number;
	values?: string[];
	defaultValue?: any;
	title?: string;
	optionFilterProp?: any;
}

interface configProps {
	searchFieldConfigs?: defaultProps;
	datesRangesConfigs?: defaultProps;
	departmentFieldConfigs?: defaultProps;
	bonusFieldConfigs?: defaultProps;
	ageFieldConfigs?: defaultProps;
	serviceFieldConfigs?: defaultProps;
}

export const TableFilters = observer(
	({
		searchFieldConfigs,
		datesRangesConfigs,
		departmentFieldConfigs,
		bonusFieldConfigs,
		ageFieldConfigs,
		serviceFieldConfigs,
	}: configProps) => {
		const { t } = useTranslation();

		return (
			<Row align="bottom" gutter={16}>
				{searchFieldConfigs?.onSearch && (
					<Col>
						<p className={styles.filterTitle}>{t('Поиск')}</p>
						<Input.Search
							enterButton
							style={{ width: '100%' }}
							disabled={searchFieldConfigs?.loading}
							loading={searchFieldConfigs?.loading}
							className={searchFieldConfigs?.className}
							placeholder={
								searchFieldConfigs?.placeholder || t('Введите для поиска')
							}
							allowClear
							onSearch={searchFieldConfigs?.onSearch}
						/>
					</Col>
				)}
				{datesRangesConfigs?.onChange && (
					<Col>
						<p className={styles.filterTitle}>{t('Период')}</p>
						<DatePicker.RangePicker
							defaultValue={datesRangesConfigs.defaultValue}
							disabled={datesRangesConfigs?.disabled}
							onChange={datesRangesConfigs?.onChange}
							format="YYYY-MM-DD"
						/>
					</Col>
				)}
				{serviceFieldConfigs?.onSelect ||
					(serviceFieldConfigs?.onChange && (
						<Col>
							<p className={styles.filterTitle}>{t('Услуга')}</p>
							<ServicesSelection {...serviceFieldConfigs} />
						</Col>
					))}
				{departmentFieldConfigs?.onChange && (
					<Col>
						<p className={styles.filterTitle}>{t('Филия')}: </p>
						<DepartmentSelection {...departmentFieldConfigs} />
					</Col>
				)}
				{bonusFieldConfigs?.onChange && (
					<Col>
						<p className={styles.filterTitle}>{t('Бонусы')}: </p>
						<Select
							style={{ width: '135px' }}
							disabled={bonusFieldConfigs?.disabled}
							loading={bonusFieldConfigs?.loading}
							defaultValue="bon2"
							onChange={bonusFieldConfigs?.onChange}
						>
							<Select.Option value="bon">Кэш</Select.Option>
							<Select.Option value="bon2">Меднеан</Select.Option>
						</Select>
					</Col>
				)}
				{ageFieldConfigs?.onChange && (
					<Col>
						<p className={styles.filterTitle}>{t('Вік')}: </p>
						<Select
							style={{ width: '135px' }}
							loading={ageFieldConfigs?.loading}
							disabled={ageFieldConfigs?.loading}
							allowClear
							onChange={ageFieldConfigs?.onChange}
						>
							<Select.Option value="6-17">6 - 17</Select.Option>
							<Select.Option value="18-44">18 - 44</Select.Option>
							<Select.Option value="49-59">49 - 59</Select.Option>
							<Select.Option value="60-74">69 - 74</Select.Option>
							<Select.Option value="75-90">75 - 90</Select.Option>
							<Select.Option value="90-100">90 - 100</Select.Option>
						</Select>
					</Col>
				)}
			</Row>
		);
	}
);
