import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import InputField from '../../../components/InputField';
import { NewPasswordWithCode } from '../../../components/Modals/NewPasswordWithCode';
import showModalFunc from '../../../components/showModalFunc';
import { SIGN_IN } from '../../../pathNames';
import { passwordRecovery } from '../../../stores';
import styles from './passwordRecovery.module.scss';

const PasswordRecovery = observer(() => {
	const { t } = useTranslation();
	const phoneRegexp = /^0\d{9}$/g;
	const validationSchema = Yup.object().shape({
		phone: Yup.string()
			.matches(phoneRegexp, 'Неправильный номер телефона')
			.required(t<string>('Обязательное поле')),
	});

	return (
		<Row className={styles.flexColumnReverse}>
			<Col
				style={{ backgroundImage: 'url(/images/bg-auth.webp)' }}
				xs={{ span: 0 }}
				md={{ span: 10 }}
			>
				<Row justify="center" align="middle" className={styles.signIn}>
					<div>
						<h2 className={styles.title}>Снова вернулись!</h2>
						<p>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo.
						</p>
						<Link to={SIGN_IN}>
							<Button className={styles.formControl} ghost>
								{t('Вход')}
							</Button>
						</Link>
					</div>
				</Row>
			</Col>
			<Col xs={{ span: 24 }} md={{ span: 14 }}>
				<Row justify="center" align="middle" className={styles.signUp}>
					<div>
						<Row justify="center">
							<img
								className={styles.img}
								src="/images/logo-nav.webp"
								alt="logo"
							/>
						</Row>
						<h1 className={styles.title}>{t('Восстановление пароля')}</h1>
						{passwordRecovery.phoneNotFound && (
							<Alert
								className={styles.alertCredentials}
								type="error"
								message={
									<div className={styles.errorCredentials}>
										<ExclamationCircleOutlined
											className={styles.errorCredentialsIcon}
										/>
										<span>{t('Такой телефон не был найден')}.</span>
									</div>
								}
							/>
						)}
						<Formik
							initialValues={{ phone: '' }}
							validationSchema={validationSchema}
							onSubmit={({ phone }) => {
								const phoneWithCountryCode = `38${phone}`;
								passwordRecovery.sendRecovery({ phone: phoneWithCountryCode });
								const { destroy } = showModalFunc({
									title: t('Введите 4-х значный код из смс'),
									width: '50%',
									keyboard: false,
									textContent: (
										<NewPasswordWithCode
											onSubmit={({ recovery_token, password }) => {
												passwordRecovery.sendNewPassword({
													recovery_token,
													password,
													phone: phoneWithCountryCode,
												});
												destroy();
											}}
										/>
									),
								});
							}}
						>
							{({
								values,
								handleSubmit,
								isValid,
								touched,
								errors,
								setFieldValue,
								setFieldTouched,
							}) => (
								<Observer>
									{() => (
										<form onSubmit={handleSubmit}>
											<InputField
												addonBefore="+38"
												title="Телефон"
												required={true}
												className={styles.formControl}
												value={values.phone}
												errorMessage={
													(passwordRecovery.phoneNotFound && ' ') ||
													(touched.phone && errors.phone)
												}
												onBlur={() => setFieldTouched('phone')}
												onChange={event =>
													setFieldValue('phone', event.target.value)
												}
											/>

											<Button
												type="primary"
												className={styles.button}
												htmlType="submit"
												loading={passwordRecovery.loading}
												disabled={!isValid}
											>
												{t('Восстановить')}
											</Button>
										</form>
									)}
								</Observer>
							)}
						</Formik>
						<Link to={SIGN_IN} className={styles.link}>
							<span>{t('Вспомнили пароль?')}</span> {t('Войдите')}
						</Link>
					</div>
				</Row>
			</Col>
		</Row>
	);
});

export default PasswordRecovery;
