import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { servicesStore, transactionsStore, userStore } from '../../../stores';
import { OPERTAION_SUCCESS } from '../../../pathNames';
import BookService from '../../../components/Cabinet/BookService';
import { Pay } from '../../../components/Cabinet/Pay';

import styles from './styles.module.scss';
import Services from '../../../components/Cabinet/Services';

enum ComponentType {
	services,
	bookTime,
	choosePayMethod,
	operationSuccess,
	operationFailed,
}

type TransactionValues = {
	cash_bon: number;
	mednean_bon: number;
	type: 'card' | 'token';
	serviceType: string | undefined;
	order_id?: number;
	time_id?: number;
	card_id?: number;
};
enum ServiceType {
	service = 'product',
	invoice = 'invoice',
}

const CabinetServices = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [time_id, setTimeId] = useState(undefined);
	const [componentType, setComponentType] = useState(ComponentType.services);
	const [serviceType, setServiceType] = useState<string>();
	const components = {
		[ComponentType.services]: (
			<Services
				payInvoice={() => {
					setServiceType(ServiceType.invoice);
					setComponentType(ComponentType.choosePayMethod);
				}}
				selectService={() => {
					setServiceType(ServiceType.service);
					setComponentType(ComponentType.bookTime);
				}}
			/>
		),
		[ComponentType.bookTime]: (
			<BookService
				onBook={(timeId: any) => {
					setTimeId(timeId);
					servicesStore.bookTime(timeId);

					setComponentType(ComponentType.choosePayMethod);
				}}
				onCancel={() => setComponentType(ComponentType.services)}
			/>
		),
		[ComponentType.choosePayMethod]: (
			<Pay
				payService={async ({
					cash_bon = 0,
					mednean_bon = 0,
					byCard,
					card_id = null,
					type,
				}: any) => {
					const allValues: TransactionValues = {
						cash_bon,
						mednean_bon,
						card_id,
						type,
						serviceType,
					};
					if (serviceType === ServiceType.invoice) {
						allValues.order_id = servicesStore.selectedService.id;
						await transactionsStore.payService(allValues);
						if (!byCard) {
							navigate(OPERTAION_SUCCESS);
							await userStore.getInfo();
						}
					}

					if (serviceType === ServiceType.service) {
						allValues.time_id = time_id;
						await transactionsStore.payService(allValues);
						if (!byCard) {
							navigate(OPERTAION_SUCCESS);
							await userStore.getInfo();
						}
					}
				}}
				onCancel={() => setComponentType(ComponentType.services)}
			/>
		),
		[ComponentType.operationSuccess]: (
			<>
				<Row justify="center">
					<Col>
						<CheckCircleOutlined className={styles.iconSuccess} />
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col className={styles.textSuccess}>Операция выполнена успешно.</Col>
				</Row>
			</>
		),
		[ComponentType.operationFailed]: (
			<>
				<Row justify="center">
					<Col>
						<CloseCircleOutlined className={styles.iconFailed} />
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col className={styles.textFailed}>Операция не выполнена.</Col>
				</Row>
			</>
		),
	};
	return (
		<CabinetLayout>
			<PageTitle title={t('Оплата услуг')} />
			<div className={styles.container}>
				{components[componentType] || ComponentType.services}
			</div>
		</CabinetLayout>
	);
});

export default CabinetServices;
