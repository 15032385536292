import { Button, Col, Modal, Row } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import React from 'react';

import styles from './showModalFunc.module.scss';

type OpenSuccessModal = {
	textContent: React.ReactNode;
	title?: string;
	handleSubmit?: () => void;
	okText?: string;
	closable?: boolean;
} & ModalFuncProps;

const openSuccessModal = ({
	textContent,
	title,
	handleSubmit,
	closable = true,
	okText = 'OK',
	...props
}: OpenSuccessModal) =>
	Modal.success({
		maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.25)' },
		centered: true,
		icon: null,
		closable,
		bodyStyle: {
			padding: '0',
		},
		okCancel: false,
		okButtonProps: {
			style: {
				display: 'none',
			},
		},
		content: (
			<Row justify="center">
				<Col>{title && <h2 className={styles.title}>{title}</h2>}</Col>
				<Col md={22}>{textContent}</Col>

				{handleSubmit && (
					<Col md={22}>
						<br />
						<Button block size="large" type="primary" onClick={handleSubmit}>
							{okText}
						</Button>
					</Col>
				)}
			</Row>
		),
		...props,
	});

openSuccessModal.defaultProps = {
	width: 420,
};

export default openSuccessModal;
