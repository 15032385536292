import { Tabs } from 'antd';
import * as React from 'react';

import { CalendarMonth } from '../../../../components/Admin/CalendarMonth';
import { CalendarWeek } from '../../../../components/Admin/CalendarWeek';
import { AdminLayout } from '../../../../components/Admin/Layout';
import { adminCalendarStore } from '../../../../stores';
import { CalendarDateType } from '../../../../stores/Admin/Calendar';
import styles from './styles.module.scss';

const Calendar = () => {
	const [activeTab, setActiveTab] = React.useState(CalendarDateType.Week);
	const items = [
		{ label: 'Неделя', key: CalendarDateType.Week, children: <CalendarWeek /> },
		{
			label: 'Месяц',
			key: CalendarDateType.Month,
			children: <CalendarMonth />,
		},
	];

	return (
		<AdminLayout>
			<Tabs
				items={items}
				className={styles.tabList}
				onChange={(key: any) => {
					adminCalendarStore.clearFilters();
					setActiveTab(key);
				}}
				defaultActiveKey={activeTab}
				type="card"
			/>
		</AdminLayout>
	);
};

export default Calendar;
