import { Modal, notification } from 'antd';
import { AxiosError } from 'axios';
import i18n from 'i18next';

export const showServerErrorNotification = (error: AxiosError | any): void => {
	if (error && error.response) {
		if (error.response.status === 401) {
			notification.error({
				placement: 'bottomLeft',
				message: i18n.t<string>('Доступ заборонено'),
				description: i18n.t<string>(
					'Доступ заборонений до даних. Будь ласка, запитайте свого адміністратора.'
				),
			});
		}
		if (error.response.status === 403) {
			notification.error({
				placement: 'bottomLeft',
				message: 'Доступ заборонено',
				description:
					error.response.data.message ||
					'Доступ заборонений до даних. Будь ласка, запитайте свого адміністратора.',
			});
		}
		if (error.response.status === 422) {
			const modal = Modal.error({
				title:
					error.response.data?.data[0]?.message ||
					error?.response?.data?.data?.msg,
			});
			setTimeout(() => {
				modal.destroy();
			}, 3000);
		}
		if (error.response.status === 500) {
			notification.error({
				placement: 'bottomLeft',
				message: 'Помилка сервера!',
				description:
					"Пробачте, але сервер не працює. Клієнт не може з'єднатися з сервером.",
			});
		}
	} else {
		notification.error({
			placement: 'bottomLeft',
			message: 'Проверьте подключение к интернету.',
			description: 'Клиент не может соедениться с сервером.',
		});
	}
};
