import { makeAutoObservable } from 'mobx';

export class AdminMenu {
	sidebarActiveLink = '';
	menuActiveLink = '';
	isFirstRender = true;

	constructor() {
		makeAutoObservable(this);
	}

	setSidebarLink(value: string) {
		this.sidebarActiveLink = value;
	}

	setMenuLink(value: string) {
		this.menuActiveLink = value;
	}

	setIsFirstRender() {
		this.isFirstRender = false;
	}
}
