export function getBase64(img: any, callback: any) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	return reader.readAsDataURL(img);
}

export const getBase64FromUrl = async (url: RequestInfo) => {
	const data = await fetch(url);
	const blob = await data.blob();
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = function () {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};
