import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import settings from '../settings';
import { authStore, languageStore } from '../stores';
import Storage, { StorageType } from '../stores/Storage';
import { showServerErrorNotification } from './showServerErrorNotification';
import { showSuccessNotification } from './showServerSuccessNotification';
const STORAGE_TYPE = StorageType.SessionStorage;
const JWT_TOKEN_STORAGE_NAME = 'accessToken';
const authAxiosClient = axios.create({
	baseURL: `${settings.apiUrl}/v1`,
	timeout: 3 * 60 * 1000,
});
authAxiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
	const token = Storage(STORAGE_TYPE).get(JWT_TOKEN_STORAGE_NAME);

	if (token?.access_token) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token.access_token}`,
			language: languageStore.currentLang,
		};
	} else {
		authStore.signOut();
	}

	return config;
});

authAxiosClient.interceptors.response.use(
	(response: AxiosResponse) => {
		showSuccessNotification(response);

		return response;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			authStore.signOut();
		}
		showServerErrorNotification(error);
		return Promise.reject(error);
	}
);

export default authAxiosClient;
