import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { UserSelection } from '../../../../components/Selections/Users';
import { adminReferralsStore, referalsStore } from '../../../../stores';
import styles from './styles.module.scss';

const Referrals = observer(() => {
	const { t } = useTranslation();
	const [showUsers, setShowUsers] = React.useState(false);
	const [withFilters, setWithFilters] = React.useState(false);

	const generationColumn = [
		{
			dataIndex: 'level',
			title: 'Поколение',
			render: (level: string) => referalsStore.getGenerationRomanNum(level),
		},
		{
			title: 'Рефералов',
			dataIndex: 'count',
			render: (count: number) => <>{count || 0}</>,
		},
		{
			title: 'Кэш бонусы',
			dataIndex: 'cash_bon',
			render: (cash_bon: number) => <>{cash_bon || 0}</>,
		},
	];
	const usersColumn = [
		{
			title: 'ID ',
			dataIndex: 'id',
			render: (text: React.ReactNode) => <b>{text}</b>,
		},
		{
			title: t('ФИО'),
			dataIndex: 'profiles',
			render: (profiles: any) => (
				<>
					{profiles?.first_name} {profiles?.last_name}
				</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: t('Меднеан бонусы'),
			dataIndex: 'mednean_bon',
		},
		{
			title: t('Кэш бонусы'),
			dataIndex: 'cash_bon',
		},
		{
			title: t('Дата создания'),
			render: (user: any) => (
				<>{new Date(user.created_at * 1000).toLocaleDateString()}</>
			),
		},
		{
			render: (user: any) => (
				<Button
					className={styles.detailBtn}
					onClick={async () => {
						await adminReferralsStore.getUserReferrals(user);
						setShowUsers(false);
					}}
				>
					{t('Посмотреть список рефералов')}
				</Button>
			),
		},
	];

	React.useEffect(
		() => () => {
			adminReferralsStore.getUserReferrals({});
		},
		[]
	);

	const { profiles } = adminReferralsStore.userReferrals.user;

	return (
		<AdminLayout>
			<Row justify="space-between" align="middle">
				<Col span={12}>
					<h3 className={styles.title}>
						{profiles?.last_name} {profiles?.first_name}
					</h3>
					<p>{profiles?.birthday}</p>
				</Col>
				<Col span={6}>
					<UserSelection
						loading={adminReferralsStore.loading}
						onChange={(value: any, options: any) => {
							if (options?.user) {
								setWithFilters(true);
								adminReferralsStore.getUserReferrals(options.user);
							} else {
								setWithFilters(false);
								adminReferralsStore.getUserReferrals({});
							}
							setShowUsers(false);
						}}
					/>
				</Col>
			</Row>
			<div style={{ marginTop: '20px' }}>
				<AdminTable
					pagination={false}
					loading={adminReferralsStore.loading}
					localeText={!withFilters ? <>{t('Выберите пациента')}</> : null}
					localeIcon={!withFilters ? <ArrowUpOutlined rotate={55} /> : null}
					columns={showUsers ? usersColumn : generationColumn}
					data={adminReferralsStore.userReferrals.referrals}
					onRow={(record: any) => ({
						onClick: async () => {
							adminReferralsStore.setLoading(true);
							if (Number(record.count)) {
								await adminReferralsStore.getUserReferralByLevel(record.level);
								setShowUsers(true);
								adminReferralsStore.setLoading(false);
							}
						},
					})}
				/>
			</div>
		</AdminLayout>
	);
});

export default Referrals;
