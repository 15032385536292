import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adminDepartmentsStore } from '../../../stores';

const DepartmentSelection = ({ onChange, defaultValue, ...props }: any) => {
	const { t } = useTranslation();

	React.useEffect(() => {
		adminDepartmentsStore.getDepartments();
	}, []);
	return (
		<Select
			allowClear
			{...props}
			defaultValue={defaultValue}
			placeholder={t('Филия')}
			style={{ width: '120px' }}
			onChange={value => onChange(value)}
		>
			{adminDepartmentsStore.departments.map((item: any) => (
				<Select.Option key={item.id} value={item.id}>
					{item.name || 'Без названия'}
				</Select.Option>
			))}
		</Select>
	);
};

export default observer(DepartmentSelection);
