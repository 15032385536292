import { makeAutoObservable } from 'mobx';

import { Generations, Referal, ReferalLink } from '../../types/Referrals';
import authAxiosClient from '../../utils/authAxiosClient';

export class ReferalsStore {
	referals: any = {};
	generations: Generations[] = [];
	selectedLevel: null | number = null;
	selectedReferal: Referal | null = null;
	referalLink = {} as ReferalLink;
	filters = {};
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	selectGenerationLevel(level: number) {
		this.selectedLevel = level;
	}

	selectReferal(referal: Referal) {
		this.selectedReferal = { ...referal };
	}

	async getGenerationsList() {
		const { data } = await authAxiosClient.get('/referrals');
		this.generations = this.reformatData(data.data);
	}

	async getReferalsList(page = 1, pageSize = 10) {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(
				`/referrals/${this.selectedLevel}?page=${page}&per-page=${pageSize}`,
				{
					params: {
						...this.filters,
					},
				}
			);
			this.referals = data;
		} catch (error) {
			console.log(error);
		} finally {
			this.setLoading(false);
		}
	}

	async createReferalLink() {
		const { data } = await authAxiosClient.get('/referrals/create-code');
		this.referalLink = data.data;
	}

	getGenerationRomanNum(value: number | string | null) {
		switch (Number(value)) {
			case 1:
				return 'I';
			case 2:
				return 'II';
			case 3:
				return 'III';
			case 4:
				return 'IV';
			case 5:
				return 'V';
			case 6:
				return 'VI';
			case 7:
				return 'VII';
			default:
				return 'I';
		}
	}

	reformatData(data: any) {
		const final: any = [];
		for (const key in data) {
			final.push(...data[key]);
		}

		return final.map((item: any, index: number) => ({
			...item,
			key: index + 1,
			editable: false,
		}));
	}

	setFilters(filters: any) {
		this.filters = { ...filters, ...this.filters };
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
