import { Button, Row, Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState, Suspense } from 'react';

import { signUpStore } from '../../stores';

const LicenseAgreement = React.lazy((): any => import('../LicenseAgreement'));
interface AgreementPopupProps {
	onSubmit: () => void;
}

export const SignUpAgreementPopup = observer(
	({ onSubmit }: AgreementPopupProps) => {
		const [isAgreedAgreement, setIsAgreedAgreement] = useState(false);

		return (
			<div>
				<Suspense fallback={<div>Loading...</div>}>
					<LicenseAgreement agreement={true} />
				</Suspense>
				<br />
				<Checkbox
					checked={isAgreedAgreement}
					onChange={e => setIsAgreedAgreement(e.target.checked)}
				>
					Я приймаю умови угоди
				</Checkbox>
				<Row justify="space-between">
					<Button
						type="primary"
						loading={signUpStore.loading}
						onClick={onSubmit}
						disabled={!isAgreedAgreement}
					>
						Далі
					</Button>
				</Row>
			</div>
		);
	}
);
