export const getDiscount = (price: number, discount: number) =>
	(Number(price) * Number(discount)) / 100;

export const getRoleName = (role: string) => {
	switch (role) {
		case 'admin':
			return 'Администратор';
		case 'moderator':
			return 'Модератор';
		case 'department':
			return 'Регистратор';
		case 'user':
			return 'Пользователь';
		case 'root':
			return 'Директор';
		default:
			return 'Пользователь';
	}
};
