import { Col, Image, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import settings from '../../../settings';
import { servicesStore } from '../../../stores';
import styles from './styles.module.scss';

const Services = ({ selectService, payInvoice }: any) => {
	const { t } = useTranslation();
	const defaultImageUrl = '/images/services/default.webp';
	React.useEffect(() => {
		servicesStore.getServices();
		servicesStore.getInvoices();
	}, []);
	return (
		<>
			<Row justify="center">
				<Col xs={18} md={8}>
					<div className={styles.titleBox}>
						<h3 className={styles.title}>{t('Услуги')}</h3>
						<p>{t('Все услуги оплаченные через приложение на 20% дешевле')}</p>
					</div>
				</Col>
			</Row>
			<Row justify="space-around">
				<Col span={11}>
					<h3 className={styles.title}>{t('Пройденные услуги')}</h3>
					<br />
					<Row gutter={[16, 16]}>
						{servicesStore.invoices.map((item: any) => (
							<Col xs={24} md={8} lg={8} key={item.created_at}>
								<div
									onClick={() => {
										servicesStore.chooseService(item);
										payInvoice();
									}}
									className={styles.serviceCard}
								>
									<p>
										<Image
											preview={false}
											width={50}
											src={`${settings.apiUrl}${item.product?.img}`}
											fallback={defaultImageUrl}
										/>
									</p>
									<p>{item.product?.name}</p>
									<b>
										{item.price} ({item.price_discount}*)
									</b>
								</div>
							</Col>
						))}
					</Row>
				</Col>
				<Col span={11}>
					<h3 className={styles.title}>{t('Услуги по записи')}</h3>
					<br />
					<Row wrap={true} gutter={[16, 16]} justify="space-between">
						{servicesStore.services.map((item: any) => (
							<Col xs={24} md={8} lg={8} key={item.created_at}>
								<div
									className={styles.serviceCard}
									onClick={() => {
										servicesStore.chooseService(item);
										selectService();
									}}
								>
									<p>
										<Image
											preview={false}
											width={50}
											src={`${settings.apiUrl}${item.img}`}
											fallback={defaultImageUrl}
										/>
									</p>
									<span>{item.name}</span>
									<b>
										{item.price} ({item.price_discount}*)
									</b>
								</div>
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default observer(Services);
