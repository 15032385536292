import { Button, Col, Input, Radio, Row, Select, Spin } from 'antd';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
	adminInvoicesStore,
	adminOrdersStore,
	adminUsersStore,
} from '../../../stores';
import { getDiscount } from '../../../utils/helperFunctions';
import { EmptyData } from '../../EmptyData';
import InputField from '../../InputField';
import styles from './styles.module.scss';

const { Option } = Select;

export const OrderCreateChange = observer(
	({ onSubmit, initialValues }: any) => {
		const { t } = useTranslation();
		const { loading, users } = adminUsersStore;

		const [templateMode, setTemplateMode] = React.useState('1');
		const searchByName = (name: string) => {
			adminUsersStore.setFilters({ name });
			adminUsersStore.getUsers();
			return users?.data.length;
		};
		const searchByPhone = (phone: string) => {
			adminUsersStore.setFilters({ phone });
			adminUsersStore.getUsers();
			return users?.data.length;
		};
		const chooseService = (service: string) => {
			adminInvoicesStore.setFilters({ service });
			adminInvoicesStore.getInvoices();
			return users?.data.length;
		};
		const options = [
			{ label: 'Основной', value: '1' },
			{ label: 'Шаблонный', value: '2' },
		];

		React.useEffect(() => {
			adminUsersStore.getUsers();
			adminInvoicesStore.getInvoices();
			return () => {
				adminUsersStore.clearFilters();
				adminInvoicesStore.clearFilters();
			};
		}, []);

		const createTemplateInvoice = async (values: any) => {
			const { price } = values;
			const newMainInvoice = {
				price,
				discount: 20,
				price_discount: getDiscount(price, 20),
				department_id: 4,
				languages: {
					ru: {
						name: 'Дневной курс',
						desc: 'Дневной курс',
					},
					ua: {
						name: 'Денний курс',
						desc: 'Денний курс',
					},
				},
			};
			const {
				data: { id },
			} = await adminInvoicesStore.createInvoice(newMainInvoice);

			return {
				...values,
				product_id: id,
			};
		};

		const onFinish = async (values: any) => {
			switch (templateMode) {
				case '1': {
					onSubmit(values);
					break;
				}
				case '2': {
					const personalInvoiceData = await createTemplateInvoice(values);
					await onSubmit(personalInvoiceData);
					break;
				}
			}
		};

		const validationSchema = Yup.object().shape({
			user_id: Yup.string().required(t<string>('Обязательное поле')),
			phone: Yup.string().required(t<string>('Обязательное поле')),
			price: Yup.string().required(t<string>('Обязательное поле')),
		});

		return (
			<>
				<Formik
					enableReinitialize={true}
					validationSchema={validationSchema}
					validateOnMount
					validateOnChange
					initialValues={initialValues}
					onSubmit={onFinish}
				>
					{({
						handleSubmit,
						setFieldValue,
						values,
						isValid,
						setValues,
					}): any => (
						<Observer>
							{() => (
								<form onSubmit={handleSubmit}>
									<Row justify="center">
										<Radio.Group
											disabled={values.product_id}
											options={options}
											onChange={e => {
												if (e.target.value === '1') {
													setValues({
														...values,
														price: '',
														price_discount: '',
													});
												}
												setTemplateMode(e.target.value);
											}}
											optionType="button"
											buttonStyle="solid"
											value={templateMode}
										/>
									</Row>
									<br />
									<Spin tip={t('Идет загрузка') + '...'} spinning={loading}>
										<Row gutter={[8, 8]}>
											<Col span={24}>
												<span>{t('Пациент')}</span>
												<Select
													loading={loading}
													allowClear
													showSearch
													optionFilterProp="children"
													value={`${values.last_name || ''} ${values.first_name || ''
														}`}
													style={{ width: '100%' }}
													placeholder={t('Пациент')}
													onSearch={debounce(searchByName, 400)}
													onChange={(value, optionData: any) => {
														setValues({
															...values,
															user_id: value,
															last_name: optionData?.last_name,
															first_name: optionData?.first_name,
															phone: optionData?.phone,
														});
													}}
												>
													{users?.data?.map((item: any) => (
														<Option
															key={item.phone}
															value={item.profiles.user_id}
															first_name={item.profiles.first_name}
															last_name={item.profiles.last_name}
															phone={item.phone}
														>
															{item.profiles.last_name}{' '}
															{item.profiles.first_name}
														</Option>
													))}
												</Select>
											</Col>
											<Col span={24}>
												<span>{t('Номер телефона')}</span>
												<Select
													loading={loading}
													style={{ width: '100%' }}
													showSearch
													allowClear
													value={values.phone}
													placeholder={t('Номер телефона')}
													optionFilterProp="children"
													onSearch={debounce(searchByPhone, 400)}
													onChange={(value, optionData: any) => {
														setValues({
															...values,
															user_id: value,
															last_name: optionData?.last_name,
															first_name: optionData?.first_name,
															phone: optionData?.phone,
														});
													}}
												>
													{users?.data?.map((item: any) => (
														<Option
															key={item.phone}
															value={item.profiles.user_id}
															first_name={item.profiles.first_name}
															last_name={item.profiles.last_name}
															phone={item.phone}
														>
															{item.phone}
														</Option>
													))}
												</Select>
											</Col>
											<Col span={24}>
												<span>{t('Название услуги')}</span>
												{templateMode === '1' && (
													<Select
														loading={adminInvoicesStore.loading}
														showSearch
														notFoundContent={<EmptyData />}
														defaultValue={values?.product_name}
														style={{ width: '100%' }}
														onSearch={debounce(chooseService, 400)}
														onChange={(value, optionData: any) => {
															setValues({
																...values,
																product_id: value,
																price: optionData?.price,
																price_discount: optionData?.price_discount,
															});
														}}
														allowClear
													>
														{adminInvoicesStore.invoices.data?.map(
															(item: any) => (
																<Option
																	key={item.id}
																	price={item.price}
																	price_discount={item.price_discount}
																	value={item.id}
																>
																	{item.name}
																</Option>
															)
														)}
													</Select>
												)}
												{templateMode === '2' && (
													<Input
														value="Дневной курс / Денний курс"
														disabled
														style={{ width: '100%' }}
													/>
												)}
											</Col>
											<Col span={24}>
												<span>{t('Стоимость')}</span>
												<Input
													disabled={templateMode === '1'}
													defaultValue={values?.price}
													value={values?.price}
													title={t('Стоимость')}
													placeholder={t('Стоимость')}
													onChange={event => {
														const price = Number(event.target.value);
														const price_discount =
															price - getDiscount(price, 20);
														setValues({
															...values,
															price,
															price_discount,
														});
													}}
												/>
											</Col>
											<Col span={24}>
												<InputField
													required={false}
													disabled
													defaultValue={values.price_discount}
													value={values?.price_discount}
													title={t('Цена со скидкой')}
													placeholder={t('Цена со скидкой')}
													onChange={event =>
														setFieldValue('price_discount', event.target.value)
													}
												/>
											</Col>
										</Row>
										<Row gutter={[12, 12]}>
											<Col span={24}>
												<Button
													loading={
														adminOrdersStore.loading ||
														adminInvoicesStore.loading
													}
													className={styles.btn}
													disabled={!isValid}
													htmlType="submit"
													type="primary"
													size="large"
												>
													{t('Сохранить')}
												</Button>
											</Col>
										</Row>
									</Spin>
								</form>
							)}
						</Observer>
					)}
				</Formik>
			</>
		);
	}
);
