import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
interface Props {
	onChange: (e: any) => void;
	options?: any[];
	values: string[];
}

export const PayMethod = ({ onChange, options, values }: Props) => {
	const { t } = useTranslation();

	return (
		<Row justify="space-between">
			<Col>
				<p className={styles.grayText}>{t('Выбор способа оплаты')}</p>
				<Checkbox.Group value={values} options={options} onChange={onChange} />
			</Col>
		</Row>
	);
};
