import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CabinetLayout } from '../../../../components/Cabinet/Layout';
import { PageTitle } from '../../../../components/Cabinet/PageTitle';
import styles from './styles.module.scss';

const CabinetSettingsAbout = () => {
	const { t } = useTranslation();

	return (
		<CabinetLayout>
			<PageTitle title={t('Настройки профиля')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col span={18}>
						<h3 className={styles.title}>{t('Про приложение')}</h3>
						<p className={styles.text}>
							{t('Реферал - участник партнёрской программы')}
						</p>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetSettingsAbout;
