import { FilePdfOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Progress, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import settings from '../../../settings';
import { transactionsStore } from '../../../stores';
import styles from './styles.module.scss';

export const TransactionDetail = observer(({ onBack, onInvite }: any) => {
	const { t } = useTranslation();
	const {
		selectedTransaction: {
			income,
			card,
			mednean_bon,
			cash_bon,
			description,
			military,
			amount,
			type,
			fullPrice,
			before_mednean_bon,
			status,
			note,
			nonDiscountPrice,
			created_at,
			local_code,
			before_cash_bon,
			discount_price,
			withdrawal_amount,
		},
	} = transactionsStore;
	const payTypes = [0, 1];
	const isPayServiceType = payTypes.includes(type);

	const statuses = [
		{ node: <Progress type="circle" percent={100} width={60} />, status: 2 },
		{
			node: (
				<Progress type="circle" status="exception" percent={100} width={60} />
			),
			status: 3,
		},
	];

	const getTransactionNameByType = (value: any) => {
		switch (value) {
			case 0:
			case 1:
				return <span className={styles.pay}>{note}</span>;
			case 2:
				return <span className={styles.fillIn}>{note}</span>;
			case 3:
				return <span className={styles.withDraw}>{note}</span>;
		}
	};

	return (
		<>
			<Row className={styles.navigation} justify="space-between" align="middle">
				<Col>
					<Button icon={<LeftOutlined />} onClick={onBack} type="link">
						{t('Назад')}
					</Button>
				</Col>
				<Col>
					<Button
						icon={<FilePdfOutlined className={styles.icon} />}
						href={`${settings.apiUrl}/v1/transactions/order/${local_code}`}
						type="link"
					>
						{t('Скачать квитанцию')}
					</Button>
				</Col>
			</Row>
			<Row gutter={24} justify="center">
				{isPayServiceType && (
					<Col md={16} xs={24}>
						<Row justify="center">
							{statuses.map(item => (
								<React.Fragment key={item.status}>
									{status === item.status && item.node}
								</React.Fragment>
							))}
						</Row>
						<Row justify="center">
							<span className={styles.title}>
								{getTransactionNameByType(type)}
							</span>
						</Row>
						<Row justify="center">
							<span className={styles.amount}>{fullPrice} ₴ </span>
						</Row>
						<Row gutter={16} justify="center">
							<span className={styles.priceWithoutDiscount}>
								{t('Цена без скидки', { amount: nonDiscountPrice })}
							</span>
						</Row>
						<Row gutter={16} justify="center">
							<span className={styles.grayText}>
								{dayjs.unix(created_at).format('DD.MM.YYYY HH:mm')}
							</span>
						</Row>
						<Row>
							<b>{t('Средство оплаты')}:</b>
						</Row>
						<Row justify="space-between">
							<Col>{t('Меднеан бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{mednean_bon}</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<Row justify="space-between">
							<Col>{t('Кэш бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{cash_bon}</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<Row justify="space-between">
							<Col>{t('Банковская карта')}:</Col>
							<Col>
								<span className={styles.grayText}>{amount}</span>
							</Col>
						</Row>
						<Row justify="space-between">
							<span className={styles.grayText}>{card}</span>
						</Row>
						<br />
						<Row justify="space-between">
							<Col>{isPayServiceType && <b>{t('Полная цена услуги')}</b>}</Col>
							<Col>
								<span className={styles.grayText}>{nonDiscountPrice}</span>
							</Col>
						</Row>
						<Row justify="space-between">
							<Col>{isPayServiceType && t('Цена скидка 20%')}</Col>
							<Col>
								<span className={styles.grayText}>{discount_price}</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<br />
						<Row>
							<b>{t('Остаток на момент оплаты')}:</b>
						</Row>
						<Row justify="space-between">
							<Col>{t('Меднеан бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{before_mednean_bon}</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<Row justify="space-between">
							<Col>{t('Кэш бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{before_cash_bon}</span>
							</Col>
						</Row>
						<br />
						<br />
						<Row justify="center">
							<Button
								onClick={onInvite}
								className={styles.referalLinkBtn}
								type="primary"
							>
								{t('Пригласить реферала')}
							</Button>
						</Row>
					</Col>
				)}
				{type === 2 && (
					<Col md={16} xs={24}>
						<Row justify="center">
							{statuses.map(item => (
								<React.Fragment key={item.status}>
									{status === item.status && item.node}
								</React.Fragment>
							))}
						</Row>
						<Row justify="center">
							<span className={styles.title}>
								{getTransactionNameByType(type)}
							</span>
						</Row>
						<Row justify="center">
							<span className={styles.amount}>{amount} ₴ </span>
						</Row>
						<Row gutter={16} justify="center">
							<span className={styles.grayText}>
								{dayjs.unix(created_at).format('DD.MM.YYYY HH:mm')}
							</span>
						</Row>
						<Row gutter={16} justify="center">
							<Col>
								<span>
									<b>{description}</b>
								</span>
								<p className={styles.grayText}>{card}</p>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<br />
						<Row>
							<b>{t('Остаток на момент пополнение')}:</b>
						</Row>
						<Row justify="space-between">
							<Col>{t('Меднеан бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{before_mednean_bon}</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<Row justify="space-between">
							<Col>{t('Кэш бонусы')}:</Col>
							<Col>
								<span className={styles.grayText}>{before_cash_bon}</span>
							</Col>
						</Row>
						<br />
						<br />
						<Row justify="center">
							<Button
								onClick={onInvite}
								className={styles.referalLinkBtn}
								type="primary"
							>
								{t('Пригласить реферала')}
							</Button>
						</Row>
					</Col>
				)}
				{type === 3 && (
					<Col md={16} xs={24}>
						<Row justify="center">
							{statuses.map(item => (
								<React.Fragment key={item.status}>
									{status === item.status && item.node}
								</React.Fragment>
							))}
						</Row>
						<Row justify="center">
							<span className={styles.title}>
								{getTransactionNameByType(type)}
							</span>
						</Row>
						<Row justify="center">
							<span className={styles.amount}>{withdrawal_amount} ₴ </span>
						</Row>
						<Row gutter={16} justify="center">
							<span className={styles.grayText}>
								{dayjs.unix(created_at).format('DD.MM.YYYY HH:mm')}
							</span>
						</Row>
						<Row gutter={16} justify="center">
							<Col>
								<span>
									<b>{description}</b>
								</span>
								<p className={styles.grayText}>{card}</p>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<br />
						<Row justify="space-between">
							<Col>
								<b>{t('Сумма за вычетом комисий  ')}</b>:
							</Col>
							<Col>
								<span className={styles.grayText}>{amount} ₴</span>
							</Col>
						</Row>
						<Divider className={styles.divider} />
						<Row justify="space-between">
							<Col>
								<b>
									{t('Удержано налогов')} <br />{' '}
									{t('(18% НДФЛ и 1.5% военный сбор)')}
								</b>
								:
							</Col>
							<Col>
								<span className={styles.grayText}>
									{Number(+military + +income).toFixed(2)} ₴
								</span>
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<Row justify="space-between">
							<Col>
								<b>{t('Остаток кэш бонусов на момент вывода')}</b> :
							</Col>
							<Col>
								<span className={styles.grayText}>{before_cash_bon}</span>
							</Col>
						</Row>
						<br />
						<br />
						<Row justify="center">
							<Button
								onClick={onInvite}
								className={styles.referalLinkBtn}
								type="primary"
							>
								{t('Пригласить реферала')}
							</Button>
						</Row>
					</Col>
				)}
			</Row>
		</>
	);
});
