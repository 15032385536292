import { makeAutoObservable } from 'mobx';
import React from 'react';

import { ServiceInvoicesForm } from '../../components/Modals/ServicesInvoices';
import openSuccessModal from '../../components/showModalFunc';
import { Services } from '../../types/Service';
import authAxiosClient from '../../utils/authAxiosClient';

const initialValues = {
	url: '',
	department_id: '',
	img_base64: '',
	price: '',
	price_discount: '',
	discount: '',
	created_at: '',
	languages: {
		ua: {
			name: '',
			desc: '',
		},
		ru: {
			name: '',
			desc: '',
		},
	},
};

export class AdminServicesStore {
	services = {} as Services;
	loading = false;
	readonly servicesType: number = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	createValidData(values: any) {
		const {
			price,
			discount,
			price_discount,
			img_base64,
			desc,
			url,
			time,
			department_id,
			languages: { ru, ua },
		} = values;
		const validData = {
			price,
			discount,
			price_discount,
			img_base64,
			desc,
			url,
			time,
			department_id,
		};
		const uaData = {
			language_id: 1,
			type: this.servicesType,
			...validData,
			...ua,
		};
		const ruData = {
			language_id: 2,
			type: this.servicesType,
			...validData,
			...ru,
		};

		return { uaData, ruData };
	}

	openModalCreateService() {
		const { destroy } = openSuccessModal({
			title: 'Добавление основной услуги',
			width: '50%',
			keyboard: false,
			textContent: (
				<ServiceInvoicesForm
					onSubmit={(values: any) => {
						this.createService(values);
						destroy();
					}}
					onCancel={() => destroy()}
					initialValues={initialValues}
				/>
			),
		});
	}

	openModalEditService(service: any) {
		const { destroy } = openSuccessModal({
			title: 'Редактирование основной услуги',
			width: '50%',
			keyboard: false,
			textContent: (
				<ServiceInvoicesForm
					initialValues={service}
					onSubmit={(values: any) => {
						this.editService(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	async getServices(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get('/admin/products', {
				params: {
					type: this.servicesType,
					page,
					'per-page': pageSize,
					sort: sortField,
				},
			});
			this.services = data;
		} finally {
			this.setLoading(false);
		}
	}

	async createService(values: any) {
		this.setLoading(true);
		const { uaData, ruData } = this.createValidData(values);

		try {
			const { data } = await authAxiosClient.post('/admin/products', uaData);
			await authAxiosClient.post(`/admin/products/${data.data.id}`, ruData);
			this.getServices();
			return data.data.id;
		} finally {
			this.setLoading(false);
		}
	}

	async editService(values: any) {
		const { uaData, ruData } = this.createValidData(values);

		try {
			await authAxiosClient.post(`/admin/products/${values.id}`, uaData);
			await authAxiosClient.post(`/admin/products/${values.id}`, ruData);
		} finally {
			this.getServices();
		}
	}

	async deleteServices(id: any) {
		try {
			await authAxiosClient.delete(`/admin/products/${id}`);
		} finally {
			this.getServices();
		}
	}

	getServiceName(id: number) {
		const service = this.services.data.find((item: any) => item?.id === id);
		return service?.name;
	}
}
