import {
	BarChartOutlined,
	CalculatorOutlined,
	CreditCardOutlined,
	ExportOutlined,
	UploadOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import {
	CABINET,
	CABINET_CALCULATOR,
	CABINET_DEPOSITE,
	CABINET_SERVICES,
	CABINET_STATISTICS,
	CABINET_WITHDRAW,
} from '../../../pathNames';
import { userStore } from '../../../stores';
import UserBalance from '../UserBalance';
import styles from './styles.module.scss';

const NavBar = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	React.useEffect(() => {
		userStore.getNotifications();
	}, []);

	return (
		<Row justify="space-between" align="middle">
			<Col xs={24} md={24} lg={18}>
				<Row gutter={[16, 8]} align="middle">
					<Col xs={24} md={24} lg={11} xl={11}>
						<UserBalance />
					</Col>
					<Col xs={0} md={0} lg={4} xl={4}>
						<div className={styles.cardTab}>
							<NavLink to={CABINET_DEPOSITE} className={styles.fillIn}>
								<UploadOutlined className={styles.navIcon} />
								<p>{t('Пополнить счет')}</p>
							</NavLink>
						</div>
					</Col>
					<Col xs={0} md={0} lg={4} xl={4}>
						<div className={styles.cardTab}>
							<NavLink to={CABINET_SERVICES} className={styles.payService}>
								<CreditCardOutlined className={styles.navIcon} />
								<p>{t('Оплатить услуги')}</p>
							</NavLink>
						</div>
					</Col>
					<Col xs={0} md={0} lg={4} xl={4}>
						<div className={styles.cardTab}>
							<NavLink to={CABINET_WITHDRAW} className={styles.withDraw}>
								<ExportOutlined className={styles.navIcon} />
								<p>{t('Вывод Кеш бонусов')}</p>
							</NavLink>
						</div>
					</Col>
				</Row>
			</Col>
			<Col xs={0} md={0} lg={6} xl={6}>
				<Row gutter={[8, 8]} justify="end">
					<Col xs={0} md={11} lg={11}>
						<div className={styles.cardTabDark}>
							<NavLink to={CABINET_CALCULATOR}>
								<CalculatorOutlined className={styles.navIcon} />
								<p>{t('Калькулятор')}</p>
							</NavLink>
						</div>
					</Col>
					<Col xs={0} md={0} lg={11}>
						<div className={styles.cardTabDark}>
							{pathname === CABINET ? (
								<NavLink to={CABINET_STATISTICS}>
									<BarChartOutlined className={styles.navIcon} />
									<p>{t('Статистика')}</p>
								</NavLink>
							) : (
								<NavLink to={CABINET}>
									<UserOutlined className={styles.navIcon} />
									<p>{t('Личный кабинет')}</p>
								</NavLink>
							)}
						</div>
					</Col>
				</Row>
			</Col>
			<Row className={styles.mobileMenuCarousel}>
				{pathname !== CABINET && (
					<Col className={styles.menuWhiteItem}>
						<NavLink to={CABINET}>
							<UserOutlined className={styles.navIcon} />
							<p>{t('Личный кабинет')}</p>
						</NavLink>
					</Col>
				)}
				<Col className={styles.menuWhiteItem}>
					<NavLink to={CABINET_DEPOSITE} className={styles.fillIn}>
						<UploadOutlined className={styles.navIcon} />
						<div>{t('Пополнить счет')}</div>
					</NavLink>
				</Col>
				<Col className={styles.menuWhiteItem}>
					<NavLink to={CABINET_SERVICES} className={styles.payService}>
						<CreditCardOutlined className={styles.navIcon} />
						<div>{t('Оплатить услуги')}</div>
					</NavLink>
				</Col>
				<Col className={styles.menuWhiteItem}>
					<NavLink to={CABINET_WITHDRAW} className={styles.withDraw}>
						<ExportOutlined className={styles.navIcon} />
						<div>{t('Вывод Кеш бонусов')}</div>
					</NavLink>
				</Col>
				<Col className={styles.menuBlueItem}>
					<NavLink to={CABINET_CALCULATOR}>
						<CalculatorOutlined className={styles.navIcon} />
						<div>{t('Калькулятор')}</div>
					</NavLink>
				</Col>
				<Col className={styles.menuBlueItem}>
					<NavLink to={CABINET_STATISTICS}>
						<BarChartOutlined className={styles.navIcon} />
						<div>{t('Статистика')}</div>
					</NavLink>
				</Col>
				{pathname === CABINET && (
					<>
						<Col md={0} className={styles.menuBlueItem}>
							<a href="#transactions">
								<UserOutlined className={styles.navIcon} />
								<div>{t('Транзакции')}</div>
							</a>
						</Col>
						<Col md={0} className={styles.menuBlueItem}>
							<a href="#referrals">
								<UserOutlined className={styles.navIcon} />
								<div>{t('Рефералы')}</div>
							</a>
						</Col>
					</>
				)}
			</Row>
		</Row>
	);
};

export default observer(NavBar);
