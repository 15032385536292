import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import CalendarMainPage from '../pages/Admin/Calendar';
import Calendar from '../pages/Admin/Calendar/Calendar';
import CalendarSettings from '../pages/Admin/Calendar/CalendarSettings';
import AdminPage from '../pages/Admin/index';
import Patients from '../pages/Admin/Patients';
import ServicesMainPage from '../pages/Admin/Services';
import CreatePay from '../pages/Admin/Services/CreatePay';
import Invoices from '../pages/Admin/Services/Invoices';
import Services from '../pages/Admin/Services/Services';
import SettingsMainPage from '../pages/Admin/Settings';
import SettingAccess from '../pages/Admin/Settings/Access';
import SettingsBranch from '../pages/Admin/Settings/Branch';
import SettingProfile from '../pages/Admin/Settings/Profile';
import RoleList from '../pages/Admin/Settings/RoleList';
import StatisticMainPage from '../pages/Admin/Statistiс';
import StatisticsCalculator from '../pages/Admin/Statistiс/Calculator';
import StatisticsCommon from '../pages/Admin/Statistiс/Common';
import StatisticsDetail from '../pages/Admin/Statistiс/Detail';
import UsersMainPage from '../pages/Admin/Users';
import Notifications from '../pages/Admin/Users/Notifications';
import Referrals from '../pages/Admin/Users/Referrals';
import Cabinet from '../pages/Cabinet';
import CabinetCalculator from '../pages/Cabinet/Calculator';
import CabinetCalculatorResults from '../pages/Cabinet/CalculatorResults';
import CabinetChangePassword from '../pages/Cabinet/ChangePassword';
import CabinetDeposite from '../pages/Cabinet/Deposit';
import CabinetNotifications from '../pages/Cabinet/Notifications';
import OperationFailed from '../pages/Cabinet/OperationFailed';
import OperationSuccess from '../pages/Cabinet/OperationSuccess';
import CabinetServices from '../pages/Cabinet/Services';
import CabinetSettingsAbout from '../pages/Cabinet/Settings/About';
import CabinetSettingsLicense from '../pages/Cabinet/Settings/License';
import CabinetSettingsProfile from '../pages/Cabinet/Settings/Profile';
import CabinetSettings from '../pages/Cabinet/Settings/Settings';
import CabinetStatistics from '../pages/Cabinet/Statistics';
import CabinetWithdraw from '../pages/Cabinet/Withdraw';
import CabinetWithdrawFinal from '../pages/Cabinet/WithdrawFinal';
import { NotFound } from '../pages/Common/NotFound';
import PasswordRecovery from '../pages/Common/PasswordRecovery';
import SignIn from '../pages/Common/SignIn';
import SignUpPage from '../pages/Common/SignUp';
import {
	ADMIN,
	ADMIN_CALENDAR,
	ADMIN_CALENDAR_CALENDAR,
	ADMIN_CALENDAR_PATIENTS,
	ADMIN_CALENDAR_SETTINGS,
	ADMIN_SERVICES,
	ADMIN_SERVICES_CREATE_PAY,
	ADMIN_SERVICES_INVOICES,
	ADMIN_SERVICES_SERVICES,
	ADMIN_SETTINGS,
	ADMIN_SETTINGS_ACCESS,
	ADMIN_SETTINGS_BRANCHES,
	ADMIN_SETTINGS_POSITIONS,
	ADMIN_SETTINGS_PROFILE,
	ADMIN_STATISTICS,
	ADMIN_STATISTICS_CALCULATOR,
	ADMIN_STATISTICS_COMMON,
	ADMIN_STATISTICS_DETAIL,
	ADMIN_USERS,
	ADMIN_USERS_NOTIFICATIONS,
	ADMIN_USERS_PATIENTS,
	ADMIN_USERS_REFERRALS,
	CABINET,
	CABINET_CALCULATOR,
	CABINET_CALCULATOR_RESULT,
	CABINET_DEPOSITE,
	CABINET_NOTIFICATIONS,
	CABINET_SERVICES,
	CABINET_SETTINGS,
	CABINET_SETTINGS_ABOUT,
	CABINET_SETTINGS_CHANGE_PASSORD,
	CABINET_SETTINGS_LICENSE,
	CABINET_SETTINGS_PROFILE,
	CABINET_STATISTICS,
	CABINET_WITHDRAW,
	CABINET_WITHDRAW_FINAL,
	OPERATION_FAILED,
	OPERTAION_SUCCESS,
	PASSWORD_RECOVERY,
	SIGN_IN,
	SIGN_UP,
} from '../pathNames';
import { authStore } from '../stores';
import { PrivateRoute } from './privateRoute';

export enum Role {
	Root = 'root',
	Admin = 'admin',
	Moderator = 'moderator',
	Department = 'department',
	User = 'user',
}

export const MainRoutes = observer(() => {
	const navigate = useNavigate();
	const {
		token: { role },
		authorized,
	} = authStore;
	const isAdmin: boolean =
		Role.Admin === role ||
		Role.Department === role ||
		Role.Moderator === role ||
		Role.Root === role;

	React.useEffect(() => {
		if (isAdmin) {
			navigate(ADMIN);
		}
		if (role === Role.User) {
			navigate(CABINET);
		}
	}, [authorized]);

	return (
		<Routes>
			<Route path="/" element={<SignIn />}>
				<Route path={SIGN_IN} element={<SignIn />} />
			</Route>
			<Route path={SIGN_UP} element={<SignUpPage />}>
				<Route path=":referrer_id" element={<SignUpPage />} />
			</Route>
			<Route path={PASSWORD_RECOVERY} element={<PasswordRecovery />} />
			<Route
				path={ADMIN}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Department, Role.Root]}
					>
						<AdminPage />
					</PrivateRoute>
				}
			/>

			<Route
				path={ADMIN_SERVICES}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<ServicesMainPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SERVICES_SERVICES}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Services />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SERVICES_INVOICES}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Invoices />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SERVICES_CREATE_PAY}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<CreatePay />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SERVICES_CREATE_PAY}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<CreatePay />
					</PrivateRoute>
				}
			/>

			<Route
				path={ADMIN_STATISTICS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<StatisticMainPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_STATISTICS_COMMON}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<StatisticsCommon />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_STATISTICS_DETAIL}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<StatisticsDetail />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_STATISTICS_CALCULATOR}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<StatisticsCalculator />
					</PrivateRoute>
				}
			/>

			<Route
				path={ADMIN_CALENDAR}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<CalendarMainPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_CALENDAR_CALENDAR}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Calendar />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_CALENDAR_PATIENTS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Patients />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_CALENDAR_SETTINGS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<CalendarSettings />
					</PrivateRoute>
				}
			/>

			<Route
				path={ADMIN_SETTINGS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<SettingsMainPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SETTINGS_ACCESS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<SettingAccess />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SETTINGS_PROFILE}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<SettingProfile />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SETTINGS_BRANCHES}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<SettingsBranch />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_SETTINGS_POSITIONS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<RoleList />
					</PrivateRoute>
				}
			/>

			<Route
				path={ADMIN_USERS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<UsersMainPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_USERS_PATIENTS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Patients />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_USERS_REFERRALS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Referrals />
					</PrivateRoute>
				}
			/>
			<Route
				path={ADMIN_USERS_NOTIFICATIONS}
				element={
					<PrivateRoute
						roles={[Role.Moderator, Role.Admin, Role.Root, Role.Department]}
					>
						<Notifications />
					</PrivateRoute>
				}
			/>

			<Route
				path={CABINET}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<Cabinet />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_DEPOSITE}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetDeposite />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_WITHDRAW}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetWithdraw />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_WITHDRAW_FINAL}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetWithdrawFinal />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SERVICES}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetServices />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SETTINGS_LICENSE}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetSettingsLicense />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SETTINGS}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetSettings />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_STATISTICS}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetStatistics />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_CALCULATOR}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetCalculator />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_CALCULATOR_RESULT}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetCalculatorResults />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_NOTIFICATIONS}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetNotifications />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SETTINGS_PROFILE}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetSettingsProfile />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SETTINGS_ABOUT}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetSettingsAbout />
					</PrivateRoute>
				}
			/>
			<Route
				path={CABINET_SETTINGS_CHANGE_PASSORD}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<CabinetChangePassword />
					</PrivateRoute>
				}
			/>
			<Route
				path={`${OPERTAION_SUCCESS}`}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<OperationSuccess />
					</PrivateRoute>
				}
			/>
			<Route
				path={`${OPERATION_FAILED}`}
				element={
					<PrivateRoute roles={[Role.User, Role.Admin, Role.Root]}>
						<OperationFailed />
					</PrivateRoute>
				}
			/>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
});
