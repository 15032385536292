import { Button, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { adminOrdersStore, languageStore } from '../../../../stores';
import styles from './styles.module.scss';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons/index';

const CreatePay = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminOrdersStore;
	const { _meta, data } = adminOrdersStore.orders;

	React.useEffect(() => {
		adminOrdersStore.getOrders();
		return () => {
			adminOrdersStore.clearFilters();
		};
	}, [languageStore.currentLang]);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: 'Пациент',
			dataIndex: 'user',
			render: (user: any) => (
				<>
					{user?.profiles.first_name} {user?.profiles.last_name}
				</>
			),
		},
		{
			title: 'Номер телефона',
			dataIndex: 'user',
			render: (user: any) => <>{user?.phone}</>,
		},
		{
			title: 'Название услуги',
			dataIndex: 'product',
			render: (product: any) => <>{product?.name}</>,
		},
		{
			title: 'Описание услуги',
			dataIndex: 'product',
			render: (product: any) => <>{product?.desc}</>,
		},
		{
			title: 'Цена',
			dataIndex: 'price',
			sorter: (a: any, b: any) => a?.price - b?.price,
		},
		{
			title: 'Дата создания',
			dataIndex: 'created_at',
			render: (item: any) => <>{new Date(item * 1000).toLocaleDateString()}</>,
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onOpen={() => adminOrdersStore.openModalDetail(item)}
				/>
			),
		},
	];

	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminOrdersStore.getOrders(page, pageSize, sortField);
	};

	const onSearch = (name: string) => {
		if (!name) {
			adminOrdersStore.setFilters({ name: null });
		} else {
			adminOrdersStore.setFilters({ name });
		}
		adminOrdersStore.getOrders();
	};

	const onDateChange = (dates: any, values: any) => {
		if (!values.length) {
			adminOrdersStore.setFilters({ start: null, end: null });
		} else {
			const [start, end] = values;
			adminOrdersStore.setFilters({ start, end });
		}
		adminOrdersStore.getOrders();
	};

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.btn}
					type="primary"
					onClick={() => adminOrdersStore.openModalCreate()}
				>
					{t('Создать инвойс')}
				</Button>
			</Row>
			<AdminTable
				pagination={{
					options: { ..._meta, onChange: paginationHandler },
				}}
				loading={loading}
				columns={columns}
				data={data}
			>
				<TableFilters
					searchFieldConfigs={{
						loading,
						disabled: loading,
						className: styles.btnSearch,
						placeholder: t('Название услуги'),
						onSearch,
					}}
					datesRangesConfigs={{ onChange: onDateChange, disabled: loading }}
				/>
			</AdminTable>
		</AdminLayout>
	);
});

export default CreatePay;
