import { Col, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import {
	adminStatisticsStore,
	adminTransactionsStore,
	adminUsersStore,
} from '../../../../stores';
import styles from './styles.module.scss';

const { Option } = Select;
const chartData = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400,
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210,
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500,
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
];

const StatisticsCommon = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminUsersStore;
	const {
		users,
		input_mednean_bon,
		input_cash_bon,
		output_mednean_bon,
		output_cash_bon,
	} = adminStatisticsStore.statistics;
	const { _meta, data } = adminUsersStore.users;

	const onSearch = (name: string) => {
		if (!name) {
			adminUsersStore.setFilters({ name: null });
		} else {
			adminUsersStore.setFilters({ name });
		}
		adminUsersStore.getUsers();
	};
	const ageHandler = (value: string) => {
		if (!value) {
			adminUsersStore.setFilters({ birthday_start: null, birthday_end: null });
		} else {
			const [birthday_start, birthday_end] = value.split('-');
			adminUsersStore.setFilters({ birthday_start, birthday_end });
		}
		adminUsersStore.getUsers();
	};
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminUsersStore.getUsers(page, pageSize, sortField);
	};
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('ФИО'),
			dataIndex: 'profiles',
			render: (profiles: any) => (
				<span>
					{profiles.first_name} {profiles.last_name}
				</span>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: t('Меднеан бонусы'),
			dataIndex: 'mednean_bon',
			sorter: true,
		},
		{
			title: t('Кэш бонусы'),
			dataIndex: 'cash_bon',
			sorter: true,
		},
		{
			title: t('Дата рождения'),
			key: 'birthday',
			render: (user: any) => <>{user.profiles.birthday}</>,
			sorter: true,
		},
		{
			title: t('Дата создания'),
			dataIndex: 'created_at',
			render: (time: number) => (
				<>{new Date(time * 1000).toLocaleDateString()}</>
			),
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (values: any) => (
				<EditDeleteIcons
					onOpen={() => adminTransactionsStore.openModalDetail(values)}
				/>
			),
		},
	];
	const date = dayjs();

	React.useEffect(() => {
		adminUsersStore.getUsers(1, 10);
		adminStatisticsStore.getStatistics();
		return () => {
			adminUsersStore.clearFilters();
		};
	}, []);

	return (
		<AdminLayout>
			<div className={styles.container}>
				<Row gutter={16} justify="space-between">
					<Col span={13}>
						<div className={styles.topBlock}>
							<Row justify="space-between" align="middle">
								<Col span={9}>
									<span>{t('Все пользователи')}</span>
								</Col>
								<Col span={15}>
									<Row justify="end">
										<Col span={11}>
											<span className={styles.selectLabel}>{t('Бонусы')}</span>
											<Select
												onSelect={(value: any) =>
													adminStatisticsStore.changeBonusType(value)
												}
												className={styles.select}
												bordered={false}
												defaultValue="1"
											>
												<Option value="1">Меднеан</Option>
												<Option value="2">Кэш</Option>
											</Select>
										</Col>
										<Col span={11}>
											<span className={styles.selectLabel}>{t('Период')}</span>
											<Select
												onSelect={(value: any) =>
													adminStatisticsStore.changePeriod(value)
												}
												className={styles.select}
												bordered={false}
												defaultValue=""
											>
												<Option value="1">День</Option>
												<Option value="7">{t('Неделя')}</Option>
												<Option value="30">{t('Месяц')}</Option>
												<Option value="90">Квартал</Option>
												<Option value="365">{t('Год')}</Option>
												<Option value="">{t('Всё время')}</Option>
											</Select>
										</Col>
									</Row>
								</Col>
							</Row>
							<br />
							<Row gutter={[16, 16]} justify="space-between">
								<Col className={styles.dateBlock} span={24}>
									<b>
										{date.format('DD')} {date.format('MMMM')},
									</b>{' '}
									{date.format('dddd')}
								</Col>
							</Row>
							<br />
							<Row justify="space-between">
								<Col span={8} className={styles.counter}>
									<span>{t('Количество пользователей')}:</span>
									<p>{users}</p>
								</Col>
								<Col span={8} className={styles.counter}>
									<span>{t('Поступлено')}:</span>
									<p>
										{adminStatisticsStore.bonusType === '1'
											? Math.round(input_mednean_bon)
											: Math.round(input_cash_bon)}
									</p>
								</Col>
								<Col span={8} className={styles.counter}>
									<span>{t('Использовано')}:</span>
									<p>
										{adminStatisticsStore.bonusType === '1'
											? Math.round(output_mednean_bon)
											: Math.round(output_cash_bon)}
									</p>
								</Col>
							</Row>
						</div>
					</Col>
					<Col span={11}>
						<div className={styles.topBlock}>
							<Row>
								<Col span={12}>
									<span className={styles.selectLabel}>Тип:</span>
									<Select
										onSelect={(value: any) =>
											adminStatisticsStore.changeBonusType(value)
										}
										className={styles.select}
										bordered={false}
										defaultValue="1"
									>
										<Option value="1">Меднеан</Option>
										<Option value="2">Кэш</Option>
										<Option value="3">{t('Пользователи')}</Option>
									</Select>
								</Col>
								<Col span={12}>
									<span className={styles.selectLabel}>{t('Период')}:</span>
									<Select
										onSelect={(value: any) =>
											adminStatisticsStore.changePeriod(value)
										}
										className={styles.select}
										bordered={false}
										defaultValue=""
									>
										<Option value="1">День</Option>
										<Option value="7">{t('Неделя')}</Option>
										<Option value="30">{t('Месяц')}</Option>
										<Option value="90">Квартал</Option>
										<Option value="365">{t('Год')}</Option>
										<Option value="">{t('Всё время')}</Option>
									</Select>
								</Col>
							</Row>
							<div style={{ width: '100%' }}>
								<ResponsiveContainer width="100%" height={200}>
									<LineChart
										width={500}
										height={200}
										data={chartData}
										margin={{
											top: 5,
											right: 30,
											left: 20,
											bottom: 5,
										}}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis dataKey="name" />
										<YAxis />
										<Tooltip />
										<Line
											type="monotone"
											dataKey="pv"
											strokeOpacity={1}
											stroke="#8884d8"
											activeDot={{ r: 8 }}
										/>
										<Line
											type="monotone"
											dataKey="uv"
											strokeOpacity={1}
											stroke="#82ca9d"
										/>
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					</Col>
				</Row>
				<AdminTable
					pagination={{
						options: {
							..._meta,
							onChange: paginationHandler,
						},
					}}
					loading={loading}
					data={data}
					columns={columns}
				>
					<TableFilters
						searchFieldConfigs={{
							loading,
							disabled: loading,
							className: styles.btnSearch,
							placeholder: t('Введите для поиска'),
							onSearch: (value: string) => onSearch(value),
						}}
						ageFieldConfigs={{
							onChange: ageHandler,
							disabled: loading,
							loading,
						}}
					/>
				</AdminTable>
			</div>
		</AdminLayout>
	);
});

export default StatisticsCommon;
