import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { SignUpSmsCodePopup } from '../../../../components/Modals/EnterSignUpMobileCode';
import showModalFunc from '../../../../components/showModalFunc';
import { SignUpAgreementPopup } from '../../../../components/SignUpAgreementPopup';
import { SIGN_IN } from '../../../../pathNames';
import { signUpStore } from '../../../../stores';
import styles from '../signUp.module.scss';

const SignUpPage = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const requiredText = t('Обязательное поле');

	const params = useParams();
	const phoneRegexp = /^0\d{9}$/g;
	const nameRegexp = /^[А-Яєіїґ]+$/gi;
	const validationSchema = Yup.object().shape({
		password: Yup.string().required(requiredText),
		passwordConfirm: Yup.string()
			.required(requiredText)
			.oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
		firstName: Yup.string()
			.matches(nameRegexp, t<string>('Цифры или латинские буквы не допустимы'))
			.required(requiredText),
		middleName: Yup.string()
			.matches(nameRegexp, t<string>('Цифры или латинские буквы не допустимы'))
			.required(requiredText),
		lastName: Yup.string()
			.matches(nameRegexp, requiredText)
			.required(requiredText),
		birthday: Yup.string().required(requiredText).nullable(),
		gender: Yup.string().required(requiredText),
		email: Yup.string().email(t<string>('Невалидный емейл')),
		phone: Yup.string()
			.matches(phoneRegexp, 'телефон не валидный')
			.required(requiredText),
	});

	const initialValues = {
		phone: '',
		password: '',
		passwordConfirm: '',
		email: '',
		firstName: '',
		lastName: '',
		middleName: '',
		birthday: '',
		gender: '',
		referrer_id: params.referrer_id || null,
		confirmedToken: '',
	};

	const getStatus = (
		errorField: string | undefined,
		touched: boolean | undefined
	) => {
		if (touched) {
			if (errorField) {
				return 'error';
			}
			return 'success';
		}
	};

	return (
		<Col xs={24} md={14}>
			<Row justify="center" align="middle" className={styles.signUp}>
				<div>
					<Row justify="center">
						<img className={styles.img} src="/images/logo-nav.webp" alt="logo" />
					</Row>
					<h1 className={styles.title}>{t('Регистрация')}</h1>
					<Formik
						initialValues={initialValues}
						validateOnMount={true}
						validationSchema={validationSchema}
						onSubmit={(values, formikHelpers) => {
							const correctData = {
								...values,
								phone: signUpStore.addCodeToPhone(values.phone),
							};
							const { destroy: closeAgreementModal } = showModalFunc({
								width: '80%',
								keyboard: false,
								textContent: (
									<SignUpAgreementPopup
										onSubmit={async () => {
											try {
												await signUpStore.sendBeforeRegistration(correctData);
												const { destroy: closeSmsCodeModal } = showModalFunc({
													title: t('Введите 4-х значный код из смс'),
													width: '40%',
													keyboard: false,
													onCancel: () => {
														signUpStore.setLoading(false);
													},
													textContent: (
														<SignUpSmsCodePopup
															onSubmit={async (
																smsValues: any,
																{ setFieldError }: any
															) => {
																try {
																	correctData.confirmedToken = smsValues.code;
																	await signUpStore.sendSignUpCodeRegistration(
																		correctData
																	);
																	closeSmsCodeModal();
																	navigate(SIGN_IN);
																} catch (e: any) {
																	setFieldError('code', e.data.data[0].message);
																}
															}}
														/>
													),
												});
											} catch (e: any) {
												const errors = Object.fromEntries(
													e.data.data?.map(
														(item: { field: any; message: any }) => [
															item.field,
															item.message,
														]
													)
												);
												formikHelpers.setErrors(errors);
											} finally {
												closeAgreementModal();
											}
										}}
									/>
								),
							});
						}}
					>
						{({
							values,
							handleSubmit,
							handleBlur,
							isValid,
							errors,
							touched,
							setFieldValue,
						}) => (
							<Observer>
								{() => (
									<form onSubmit={handleSubmit}>
										<Row justify="space-between" gutter={[12, 12]}>
											<Col xs={12} lg={8}>
												<span>{t('Фамилия')}</span>
												<Form.Item
													validateStatus={getStatus(
														errors.lastName,
														touched.lastName
													)}
													help={touched.lastName && errors.lastName}
													hasFeedback
												>
													<Input
														onBlur={handleBlur}
														name="lastName"
														placeholder={t('Фамилия')}
														required={true}
														value={values.lastName}
														onChange={event =>
															setFieldValue('lastName', event.target.value)
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Имя')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.firstName,
														touched.firstName
													)}
													help={touched.firstName && errors.firstName}
												>
													<Input
														onBlur={handleBlur}
														name="firstName"
														placeholder={t('Имя')}
														required={true}
														value={values.firstName}
														onChange={event =>
															setFieldValue('firstName', event.target.value)
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Отчество')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.middleName,
														touched.middleName
													)}
													help={touched.middleName && errors.middleName}
												>
													<Input
														onBlur={handleBlur}
														name="middleName"
														placeholder={t('Отчество')}
														required={true}
														value={values.middleName}
														onChange={event =>
															setFieldValue('middleName', event.target.value)
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Дата рождения')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.birthday,
														touched.birthday
													)}
													help={touched.birthday && errors.birthday}
												>
													<DatePicker
														style={{ width: '100%' }}
														showToday={false}
														format="DD.MM.YYYY"
														disabledDate={(current: any) =>
															current > dayjs().endOf('day')
														}
														placeholder={t('Дата рождения')}
														onChange={(date: any) => {
															setFieldValue(
																'birthday',
																date?.format('YYYY-MM-DD')
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Электронный адрес')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.email,
														touched.email
													)}
													help={touched.email && errors.email}
												>
													<Input
														onBlur={handleBlur}
														name="email"
														placeholder={t('Электронный адрес')}
														required={false}
														value={values.email}
														onChange={event =>
															setFieldValue('email', event.target.value)
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Номер телефона')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.phone,
														touched.phone
													)}
													help={touched.phone && errors.phone}
												>
													<Input
														name="phone"
														onBlur={handleBlur}
														placeholder={t('Номер телефона')}
														required={true}
														addonBefore="+38"
														value={values.phone}
														onChange={event =>
															setFieldValue('phone', event.target.value)
														}
													/>
												</Form.Item>
											</Col>

											<Col xs={24} lg={8}>
												<span>{t('Пол')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.gender,
														touched.gender
													)}
													help={touched.gender && errors.gender}
												>
													<Select
														style={{ width: '100%', textAlign: 'left' }}
														placeholder={t('Пол')}
														options={[
															{
																label: t<string>('Мужской'),
																name: t<string>('Мужской'),
																value: 1,
															},
															{
																label: t<string>('Женский'),
																name: t<string>('Женский'),
																value: 0 as number,
															},
														]}
														value={values.gender}
														onChange={value => setFieldValue('gender', value)}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Пароль')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.password,
														touched.password
													)}
													help={touched.password && errors.password}
												>
													<Input
														type="password"
														onBlur={handleBlur}
														name="password"
														placeholder={t('Пароль')}
														required={true}
														value={values.password}
														onChange={event =>
															setFieldValue('password', event.target.value)
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} lg={8}>
												<span>{t('Подтверждение пароля')}</span>
												<Form.Item
													hasFeedback
													validateStatus={getStatus(
														errors.passwordConfirm,
														touched.passwordConfirm
													)}
													help={
														touched.passwordConfirm && errors.passwordConfirm
													}
												>
													<Input
														type="password"
														onBlur={handleBlur}
														name="passwordConfirm"
														placeholder={t('Подтверждение пароля')}
														required={true}
														value={values.passwordConfirm}
														onChange={event =>
															setFieldValue(
																'passwordConfirm',
																event.target.value
															)
														}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Button
													type="primary"
													htmlType="submit"
													disabled={!isValid}
													loading={signUpStore.loading}
												>
													{t('Регистрация')}
												</Button>
											</Col>
										</Row>
									</form>
								)}
							</Observer>
						)}
					</Formik>
					<Link to={SIGN_IN} className={styles.link}>
						<span>{t('Есть акаунт?')}</span> {t('Войдите')}
					</Link>
				</div>
			</Row>
		</Col>
	);
});

export default SignUpPage;
