import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../components/Admin/Layout';
import { AdminTable } from '../../../components/Admin/Table';
import { TableFilters } from '../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../components/EditDeleteIcons';
import { adminPatientsStore } from '../../../stores';
import styles from './styles.module.scss';

const Patients = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminPatientsStore;
	const { _meta, data } = adminPatientsStore.patients;

	const onSearch = (name: string) => {
		if (!name) {
			adminPatientsStore.setFilters({ name: null });
		} else {
			adminPatientsStore.setFilters({ name });
		}
		adminPatientsStore.getPatients();
	};
	const ageHandler = (value: string) => {
		if (!value) {
			adminPatientsStore.setFilters({
				birthday_start: null,
				birthday_end: null,
			});
		} else {
			const [birthday_start, birthday_end] = value.split('-');
			adminPatientsStore.setFilters({ birthday_start, birthday_end });
		}
		adminPatientsStore.getPatients();
	};
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminPatientsStore.getPatients(page, pageSize, sortField);
	};

	const columns = [
		{
			title: 'ID ',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('ФИО'),
			render: (user: any) => (
				<>
					{user.profiles.first_name} {user.profiles.last_name}
				</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: t('Меднеан бонусы'),
			dataIndex: 'mednean_bon',
			sorter: true,
		},
		{
			title: t('Кэш бонусы'),
			dataIndex: 'cash_bon',
			sorter: true,
		},
		{
			title: t('Дата создания'),
			dataIndex: 'created_at',
			render: (created_at: any) => (
				<>{new Date(created_at * 1000).toLocaleDateString()}</>
			),
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onOpen={() => adminPatientsStore.openModalDetail(item)}
				/>
			),
		},
	];
	React.useEffect(() => {
		adminPatientsStore.getPatients();
		return () => {
			adminPatientsStore.clearFilters();
		};
	}, []);

	return (
		<AdminLayout>
			<div className={styles.container}>
				<AdminTable
					loading={loading}
					className={styles.table}
					columns={columns}
					data={data}
					pagination={{
						options: {
							..._meta,
							onChange: paginationHandler,
						},
					}}
				>
					<TableFilters
						searchFieldConfigs={{
							loading,
							disabled: loading,
							className: styles.btnSearch,
							placeholder: t('ФИО'),
							onSearch: (value: string) => onSearch(value),
						}}
						ageFieldConfigs={{
							onChange: ageHandler,
							disabled: loading,
							loading,
						}}
					/>
				</AdminTable>
			</div>
		</AdminLayout>
	);
});

export default Patients;
