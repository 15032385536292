import { Button, Col, Row } from 'antd';
import React from 'react';

import styles from './styles.module.scss';

enum statusTypes {
	waiting = 0,
	access = 2,
	cancel = 4,
	passed = 6,
	absence = 8,
}
const AcceptToQueue = ({ onSubmit, patient }: any) => (
	<>
		<h3 className={styles.title}>Данные пользователя</h3>
		<Row>
			<Col span={24}>
				<p className={styles.label}>Имя</p>
				<p>{patient.user.profiles.first_name}</p>
			</Col>
			<Col span={24}>
				<p className={styles.label}>Фамилия</p>
				<p>{patient.user.profiles.last_name}</p>
			</Col>
			<Col span={24}>
				<p className={styles.label}>Отчество</p>
				<p>{patient.user.profiles.middle_name}</p>
			</Col>
			<Col span={24}>
				<p className={styles.label}>Дата рождения</p>
				<p>{patient.user.profiles.birthday}</p>
			</Col>
		</Row>
		<Row gutter={8}>
			<Col span={12}>
				<Button
					block
					className={styles.reject}
					type="ghost"
					onClick={() => onSubmit(statusTypes.cancel)}
				>
					Снять с очереди
				</Button>
			</Col>
			<Col span={12}>
				<Button
					block
					className={styles.accept}
					type="ghost"
					onClick={() => onSubmit(statusTypes.access)}
				>
					Принят в очередь
				</Button>
			</Col>
		</Row>
		<Row gutter={8}>
			<Col span={8}>
				<Button
					block
					className={styles.passed}
					type="primary"
					onClick={() => onSubmit(statusTypes.passed)}
				>
					Пройдено
				</Button>
			</Col>
			<Col span={8}>
				<Button
					block
					className={styles.waiting}
					type="primary"
					onClick={() => onSubmit(statusTypes.waiting)}
				>
					Ожидание
				</Button>
			</Col>
			<Col span={8}>
				<Button
					block
					className={styles.absence}
					type="ghost"
					onClick={() => onSubmit(statusTypes.absence)}
				>
					Не явка
				</Button>
			</Col>
		</Row>
	</>
);

export default AcceptToQueue;
