import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './App.less';
import './i18n';
import { ConfigProvider } from 'antd';
import { observer } from 'mobx-react-lite';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { MainRoutes } from './routes/routes';
import { languageStore } from './stores';

const container = document.getElementById('root')!;
const root = createRoot(container);
const App = observer(() => (
	<BrowserRouter>
		<ConfigProvider locale={languageStore.currentLocale}>
			<MainRoutes />
		</ConfigProvider>
	</BrowserRouter>
));
root.render(<App />);
