import React from 'react';
import { Col, Image, Row, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import relativeTime from 'dayjs/plugin/relativeTime';

import settings from '../../../settings';
import { User } from '../../../types/User';
import { getRoleName } from '../../../utils/helperFunctions';
import styles from './styles.module.scss';
dayjs.extend(relativeTime);

type Props = {
	user: User;
	loading: boolean;
};

const AdminUserInfo = observer(({ user, loading }: Props) => {
	const { t } = useTranslation();
	const extra = user;
	const role = getRoleName(user.role || extra.role);

	const userAge = dayjs().year() - dayjs(user.profiles.birthday).year();

	const createElement = (value: string) => {
		return <span className={styles.desc}>{value}</span>;
	};

	return (
		<>
			<br />
			<Row justify="center" gutter={[16, 16]}>
				<Skeleton loading={loading} active avatar>
					<Col span={4}>
						<Image
							preview={false}
							loading="eager"
							className={styles.avatar}
							src={`${settings.apiUrl}${user?.profiles?.avatar}`}
						/>
					</Col>
					<Col span={6}>
						<p>
							{createElement(`${t('ФИО')}: `)}
							{user?.profiles?.last_name} {user?.profiles?.first_name}{' '}
							{user?.profiles?.middle_name}
						</p>
						<p>
							{createElement(`${t('Дата рождения')}: `)}
							{new Date(user?.profiles?.birthday).toLocaleDateString()}
							{`(${userAge} ${t('лет')})`}
						</p>
						<p>
							{createElement(`${t('Номер телефона')}: `)}
							{user?.phone}
						</p>
					</Col>
					<Col span={6}>
						<p>
							{createElement(`${t('Кеш бонусы')}: `)}
							{user.cash_bon}
						</p>
						<p>
							{createElement(`${t('Меднеан бонусы')}: `)}
							{user.mednean_bon}
						</p>
						<p>
							{createElement(`${t('Реферальная прибыль')}: `)}
							{user.fullReferral}
						</p>
					</Col>
					<Col span={6}>
						<p>
							{createElement(`${t('Роль')}: `)}
							{t(role)}
						</p>
						<p>
							{createElement(`${t('Рефералов')}: `)}
							{user?.referralCount}
						</p>
						<p>
							{createElement(`${t('Дата создания')}: `)}
							{new Date(user?.created_at * 1000).toLocaleDateString()}
						</p>
					</Col>
				</Skeleton>
			</Row>
			<hr />
			<br />
		</>
	);
});

export default AdminUserInfo;
