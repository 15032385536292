import { Button, Card, Col, Input, Row, Space, Table, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
	adminCalendarStore,
	adminDepartmentsStore,
	adminServicesStore,
} from '../../../stores';
import { Service } from '../../../types/Service';
import DatePicker from '../../common/DatePicker';
import DepartmentSelection from '../../Selections/Departments';
import styles from './styles.module.scss';

enum ComponentType {
	settings,
	date,
	time,
	pickNumber,
	branch,
}

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

export const CreateEditQueue = observer(
	({ onCancel, initialValues = {}, onSubmit }: any) => {
		const { t } = useTranslation();
		const requiredText = t('Обязательное поле');

		const validationSchema = Yup.object().shape({
			department_id: Yup.string().required(requiredText),
			product_id: Yup.string().required(requiredText),
			day_start: Yup.string().required(requiredText),
			day_end: Yup.string().required(requiredText),
			time_end: Yup.string().required(requiredText),
			time_start: Yup.string().required(requiredText),
			count: Yup.string().required(requiredText),
		});
		const [componentType, setComponent] = useState(ComponentType.settings);
		const columns = [
			{
				title: 'ID',
				width: '120px',
				dataIndex: 'id',
			},
			{
				title: t('Услуга'),
				dataIndex: 'name',
			},
		];

		React.useEffect(() => {
			adminServicesStore.getServices();
		}, []);

		return (
			<div>
				<h2 className={styles.title}>{t('Создать очередь')}</h2>
				<span style={{ marginBottom: '10px', display: 'block' }}>
					{t('Выберите основные услуги')}
				</span>
				<Formik
					validateOnMount
					validationSchema={validationSchema}
					onSubmit={(values: any) =>
						onSubmit({ id: initialValues.id, ...values })
					}
					initialValues={initialValues}
				>
					{({
						handleSubmit,
						setFieldValue,
						setValues,
						errors,
						isValid,
						values,
					}) => (
						<Observer>
							{() => (
								<form onSubmit={handleSubmit}>
									<Row gutter={24}>
										<Col span={12}>
											<div className={styles.tableContainer}>
												<Table
													className={
														errors.product_id
															? styles.tableWithError
															: styles.tableContainer
													}
													rowSelection={{
														type: 'radio',
														onChange: (productId: React.Key[], otherProps) => {
															setValues({
																...values,
																product_id: productId[0],
																product_name: otherProps[0].name,
															});
														},
														selectedRowKeys: [values.product_id],
													}}
													columns={columns}
													dataSource={adminServicesStore.services.data.map(
														(item: Service) => {
															item.key = item.id;
															return item;
														}
													)}
													pagination={{ position: ['bottomCenter'] }}
													scroll={{ y: 230 }}
												/>
											</div>
										</Col>
										<Col span={12}>
											{ComponentType.settings === componentType && (
												<Card>
													<Button
														onClick={() => setComponent(ComponentType.branch)}
														className={styles.btnPrimary}
														value="large"
														type="primary"
														ghost
														danger={Boolean(errors.department_id)}
														block
													>
														{t('Выберите филию')}
													</Button>
													<Button
														onClick={() => setComponent(ComponentType.date)}
														className={styles.btnPrimary}
														value="large"
														type="primary"
														danger={
															Boolean(errors.day_start) &&
															Boolean(errors.day_end)
														}
														ghost
														block
													>
														{t('Выберите дату')}
													</Button>
													<Button
														onClick={() => setComponent(ComponentType.time)}
														className={styles.btnPrimary}
														value="large"
														type="primary"
														ghost
														block
														danger={
															Boolean(errors.time_start) &&
															Boolean(errors.time_end)
														}
													>
														{t('Выберите время')}
													</Button>
													<Button
														onClick={() =>
															setComponent(ComponentType.pickNumber)
														}
														className={styles.btnPrimary}
														value="large"
														type="primary"
														ghost
														block
														danger={Boolean(errors.count)}
													>
														{t('Выберите количество мест')}
													</Button>
												</Card>
											)}
											{ComponentType.date === componentType && (
												<Card>
													<span className={styles.titleItem}>
														{t('Выберите дату')}
													</span>
													<DatePicker.RangePicker
														disabledDate={current =>
															current && current < dayjs().subtract(1, 'day')
														}
														format="DD-MM-YYYY"
														style={{ width: '100%' }}
														onChange={(data: any) => {
															const dates = {
																day_start: data[0]?.format(dateFormat),
																day_end: data[1]?.format(dateFormat),
															};
															setValues({
																...values,
																...dates,
															});
														}}
													/>
													<div className={styles.btnContainer}>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="text"
														>
															{t('Отменить')}
														</Button>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="link"
														>
															{t('Подтвердить')}
														</Button>
													</div>
												</Card>
											)}
											{ComponentType.time === componentType && (
												<Card>
													<span className={styles.titleItem}>
														{t('Выберите время')}
													</span>
													<TimePicker.RangePicker
														onChange={(data: any) => {
															const times = {
																time_start: data[0].format(timeFormat),
																time_end: data[1].format(timeFormat),
															};
															setValues({
																...values,
																...times,
															});
														}}
														format="HH:mm"
														style={{ width: '100%' }}
														minuteStep={5}
													/>
													<div className={styles.btnContainer}>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="text"
														>
															{t('Отменить')}
														</Button>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="link"
														>
															{t('Подтвердить')}
														</Button>
													</div>
												</Card>
											)}
											{ComponentType.branch === componentType && (
												<Card>
													<span className={styles.titleItem}>
														{t('Выберите филию')}
													</span>
													<DepartmentSelection
														name="department_id"
														defaultValue={values.department_id}
														onChange={(value: any) => {
															setFieldValue('department_id', value);
														}}
													/>
													<div className={styles.btnContainer}>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="text"
														>
															{t('Отменить')}
														</Button>
														<Button
															className={styles.btnItem}
															type="link"
															onClick={() =>
																setComponent(ComponentType.settings)
															}
														>
															{t('Подтвердить')}
														</Button>
													</div>
												</Card>
											)}
											{ComponentType.pickNumber === componentType && (
												<Card>
													<span className={styles.titleItem}>
														Выберите количество мест
													</span>
													<Input
														defaultValue={values.count}
														onChange={event => {
															setFieldValue('count', event.target?.value);
														}}
													/>
													<div className={styles.btnContainer}>
														<Button
															onClick={() =>
																setComponent(ComponentType.settings)
															}
															className={styles.btnItem}
															type="text"
														>
															{t('Отменить')}
														</Button>
														<Button
															className={styles.btnItem}
															type="link"
															onClick={() =>
																setComponent(ComponentType.settings)
															}
														>
															{t('Подтвердить')}
														</Button>
													</div>
												</Card>
											)}
											<br />
											<Card size="small">
												<Row gutter={12}>
													<Col>
														Дата:{' '}
														<span className={styles.selectItem}>
															{values.day_start &&
																dayjs(values.day_start).format('DD.MM.YY')}{' '}
															-{' '}
															{values.day_end &&
																dayjs(values.day_end).format('DD.MM.YY')}
															.
														</span>
													</Col>
													<Col>
														{t('Время')}:{' '}
														<span className={styles.selectItem}>
															{values.period_start} - {values.period_end}.
														</span>
													</Col>
													<Col>
														{t('Количество мест')}:{' '}
														<span className={styles.selectItem}>
															{values.count}.
														</span>
													</Col>
													<Col>
														{t('Филия')}:{' '}
														<span className={styles.selectItem}>
															{adminDepartmentsStore.getDepartmentName(
																Number(values.department_id)
															)}
															.
														</span>
													</Col>
													<Col>
														{t('Послуга')}:{' '}
														<span className={styles.selectItem}>
															{values.product_name}.
														</span>
													</Col>
												</Row>
											</Card>
										</Col>
									</Row>
									<Space size={8}>
										<Button onClick={onCancel} danger size="large" type="text">
											{t('Отменить')}
										</Button>
										<Button
											loading={adminCalendarStore.loading}
											disabled={!isValid}
											size="large"
											htmlType="submit"
											type="primary"
										>
											{t('Сохранить')}
										</Button>
									</Space>
								</form>
							)}
						</Observer>
					)}
				</Formik>
			</div>
		);
	}
);
