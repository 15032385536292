export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const NOT_PERMISSIONS = '/permission-error';
export const PASSWORD_RECOVERY = '/password-recovery';
export const CABINET = '/personal-cabinet';
export const CABINET_DEPOSITE = `${CABINET}/deposit`;
export const CABINET_WITHDRAW = `${CABINET}/withdraw`;
export const CABINET_WITHDRAW_FINAL = `${CABINET}/withdraw/final`;
export const CABINET_SERVICES = `${CABINET}/services`;
export const CABINET_SETTINGS = `${CABINET}/settings`;
export const CABINET_STATISTICS = `${CABINET}/statistics`;
export const CABINET_CALCULATOR = `${CABINET}/calculator`;
export const CABINET_CALCULATOR_RESULT = `${CABINET}/calculator/results`;
export const CABINET_NOTIFICATIONS = `${CABINET}/notifications`;
export const CABINET_SETTINGS_CHANGE_PASSORD = `${CABINET}/settings/change-password`;
export const CABINET_SETTINGS_PROFILE = `${CABINET}/settings/profile`;
export const CABINET_SETTINGS_LICENSE = `${CABINET}/settings/license`;
export const CABINET_SETTINGS_ABOUT = `${CABINET}/settings/about`;
export const OPERTAION_SUCCESS = `${CABINET}/operation-success`;
export const OPERATION_FAILED = `${CABINET}/operation-failed`;

export const ADMIN = '/admin';
export const ADMIN_SERVICES = `${ADMIN}/services`;
export const ADMIN_SERVICES_SERVICES = `${ADMIN_SERVICES}/services`;
export const ADMIN_SERVICES_INVOICES = `${ADMIN_SERVICES}/invoices`;
export const ADMIN_SERVICES_CREATE_PAY = `${ADMIN_SERVICES}/createpay`;

export const ADMIN_CALENDAR = `${ADMIN}/calendar`;
export const ADMIN_CALENDAR_CALENDAR = `${ADMIN_CALENDAR}/calendar`;
export const ADMIN_CALENDAR_PATIENTS = `${ADMIN_CALENDAR}/patients`;
export const ADMIN_CALENDAR_SETTINGS = `${ADMIN_CALENDAR}/settings`;

export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_USERS_PATIENTS = `${ADMIN_USERS}/patients`;
export const ADMIN_USERS_REFERRALS = `${ADMIN_USERS}/referrals`;
export const ADMIN_USERS_NOTIFICATIONS = `${ADMIN_USERS}/notifications`;

export const ADMIN_STATISTICS = `${ADMIN}/statistics`;
export const ADMIN_STATISTICS_COMMON = `${ADMIN_STATISTICS}/common`;
export const ADMIN_STATISTICS_DETAIL = `${ADMIN_STATISTICS}/detail`;
export const ADMIN_STATISTICS_CALCULATOR = `${ADMIN_STATISTICS}/calculator`;

export const ADMIN_SETTINGS = `${ADMIN}/settings`;
export const ADMIN_SETTINGS_ACCESS = `${ADMIN_SETTINGS}/access`;
export const ADMIN_SETTINGS_PROFILE = `${ADMIN_SETTINGS}/profile`;
export const ADMIN_SETTINGS_BRANCHES = `${ADMIN_SETTINGS}/branches`;
export const ADMIN_SETTINGS_POSITIONS = `${ADMIN_SETTINGS}/positions`;

export const pathNamesArray = [
	SIGN_UP,
	SIGN_IN,
	NOT_PERMISSIONS,
	PASSWORD_RECOVERY,
	CABINET,
	CABINET_CALCULATOR,
	CABINET_CALCULATOR_RESULT,
	CABINET_DEPOSITE,
	CABINET_NOTIFICATIONS,
	CABINET_SERVICES,
	CABINET_SETTINGS,
	CABINET_SETTINGS_ABOUT,
	CABINET_SETTINGS_CHANGE_PASSORD,
	CABINET_SETTINGS_LICENSE,
	CABINET_SETTINGS_PROFILE,
	CABINET_STATISTICS,
	CABINET_WITHDRAW,
	CABINET_WITHDRAW_FINAL,
	ADMIN,
	ADMIN_CALENDAR,
	ADMIN_CALENDAR_CALENDAR,
	ADMIN_CALENDAR_PATIENTS,
	ADMIN_CALENDAR_SETTINGS,
	ADMIN_SERVICES,
	ADMIN_SERVICES_CREATE_PAY,
	ADMIN_SERVICES_INVOICES,
	ADMIN_SERVICES_SERVICES,
	ADMIN_SETTINGS,
	ADMIN_SETTINGS_ACCESS,
	ADMIN_SETTINGS_BRANCHES,
	ADMIN_SETTINGS_POSITIONS,
	ADMIN_SETTINGS_PROFILE,
	ADMIN_STATISTICS,
	ADMIN_STATISTICS_CALCULATOR,
	ADMIN_STATISTICS_COMMON,
	ADMIN_STATISTICS_DETAIL,
	ADMIN_USERS,
	ADMIN_USERS_NOTIFICATIONS,
	ADMIN_USERS_PATIENTS,
	ADMIN_USERS_REFERRALS,
	OPERTAION_SUCCESS,
	OPERATION_FAILED,
];
