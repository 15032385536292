import { FrownOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
	text?: string;
	icon?: React.ReactNode;
	wrapperCls?: any;
};

export const EmptyData = ({ text, icon, wrapperCls }: PropTypes) => {
	const { t } = useTranslation();

	return (
		<div className={wrapperCls || styles.noData}>
			{icon || <FrownOutlined />}
			<p>{text || t('Записей не найдено')}</p>
		</div>
	);
};
