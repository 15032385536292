import { Col, Divider, Radio, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { DatePicker } from '../../../components/common';
import { transactionsStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetStatistics = () => {
	const { t } = useTranslation();
	const [checked, setChecked] = React.useState(1);

	const options = [
		{ label: 'График', value: 1 },
		{ label: 'Список', value: 2 },
	];
	const data = [
		{ name: 'Group A', value: 400 },
		{ name: 'Group B', value: 300 },
		{ name: 'Group C', value: 300 },
		{ name: 'Group D', value: 200 },
	];

	const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
	}: any) => {
		const radius = Number(innerRadius) + (outerRadius - innerRadius) * 0.5;
		const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
		const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor={x > cx ? 'start' : 'end'}
				dominantBaseline="central"
			>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	React.useEffect(() => {
		if (checked === 2) {
			transactionsStore.getStatistic();
		}
	}, [checked]);

	return (
		<CabinetLayout>
			<PageTitle title={t('Статистика')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col offset={6}>
						<Radio.Group
							onChange={e => setChecked(e.target.value)}
							size="large"
							className={styles.switchBox}
							defaultValue={checked}
							options={options}
							optionType="button"
							buttonStyle="solid"
						/>
					</Col>
					<Col offset={4} className={styles.calendarBlock}>
						<DatePicker.RangePicker picker="month" bordered={false} />
					</Col>
				</Row>
				<Row justify="center">
					<Col className={styles.choosePeriod} span={12}>
						<p>
							Выберите период <br />
							Что бы увидеть статистику
						</p>
					</Col>
				</Row>
				<br />
				<br />
				{checked === 1 && (
					<Row justify="center">
						<div style={{ width: '100%', height: 200 }}>
							<ResponsiveContainer>
								<PieChart width={400} height={400}>
									<Pie
										data={data}
										cx="50%"
										cy="50%"
										labelLine={false}
										label={renderCustomizedLabel}
										outerRadius={80}
										fill="#8884d8"
										dataKey="value"
									>
										{data.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={COLORS[index % COLORS.length]}
											/>
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
					</Row>
				)}
				{checked === 2 && (
					<Row justify="center">
						<Col>
							<Row justify="space-between">
								<Col className={styles.statsTitle}>
									<span className={styles.pink}>Оплата медицинских услуг</span>
								</Col>
								<Col>
									<span className={styles.statAmount}>-5000</span>
								</Col>
							</Row>
							<Divider className={styles.smallmargin} />
							<Row justify="space-between">
								<Col className={styles.statsTitle}>
									<span className={styles.green}>
										Пополнение Меднеан - бонусов
									</span>
								</Col>
								<Col>
									<span className={styles.statAmount}>-5000</span>
								</Col>
							</Row>
							<Divider className={styles.smallmargin} />
							<Row justify="space-between">
								<Col className={styles.statsTitle}>
									<span className={styles.yellow}>Полученные кэш - бонусы</span>
								</Col>
								<Col>
									<span className={styles.statAmount}>-5000</span>
								</Col>
							</Row>
							<Divider className={styles.smallmargin} />
							<Row justify="space-between">
								<Col className={styles.statsTitle}>
									<span className={styles.blue}>Вывод кэш - бонусов</span>
								</Col>
								<Col>
									<span className={styles.statAmount}>5000</span>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		</CabinetLayout>
	);
};

export default CabinetStatistics;
