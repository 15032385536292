import { message } from 'antd';
import { makeAutoObservable } from 'mobx';

import settings from '../../settings';
import { Cards, Transaction, TransactionInfo } from '../../types/Transactions';
import authAxiosClient from '../../utils/authAxiosClient';

const { domainUrl } = settings;
export class PaymentCards {
	loading = false;
	transactionList: Transaction[] = [];
	cards: Cards[] = [];
	transactionType = '0,1';
	selectedTransaction = {} as TransactionInfo;
	withDrawData = {};
	statistics = [];

	constructor() {
		makeAutoObservable(this);
	}

	async getCards() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get('/transactions/get-cards');
			this.cards = data.data;
		} finally {
			this.setLoading(false);
		}
	}

	async addCard() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get('/transactions/add-card');
			if (data.data.url) {
				const { id, url } = data.data;
				message.loading('Переадерсация на сервис Portmone...');
				const redirectUrl = `${url}?successUrl=${domainUrl}/personal-cabinet/operation-success/${id}&failureUrl=${domainUrl}/personal-cabinet/operation-failed/${id}`;
				setTimeout(() => {
					window.location.assign(redirectUrl);
				}, 2000);
				window.location.assign(redirectUrl);
			}
		} finally {
			this.setLoading(false);
		}
	}

	async deleteCard(id: number) {
		try {
			this.setLoading(true);
			await authAxiosClient.delete(`/transactions/card/${id}`);
			await this.getCards();
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
