import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import settings from '../settings';
import { languageStore } from '../stores';
import { showSuccessNotification } from './showServerSuccessNotification';

const axiosClient = axios.create({
	baseURL: `${settings.apiUrl}/v1`,
	timeout: 3 * 60 * 1000,
});

axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
	config.headers = {
		...config.headers,
		language: languageStore.currentLang,
	};

	return config;
});

axiosClient.interceptors.response.use(
	(response: AxiosResponse) => {
		showSuccessNotification(response);
		return response;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	}
);

export default axiosClient;
