const development = process.env.NODE_ENV !== 'production';

const requiredEnvVariables = ['REACT_APP_SERVER_IP'];
const missingEnvs = requiredEnvVariables.filter(env => !process.env[env]);

if (missingEnvs.length) {
	const errorMessage = `Configuration error!! Please set up necessary env variables: ${missingEnvs.join(
		', '
	)}`;
	// eslint-disable-next-line no-console
	throw Error(errorMessage);
}

const settings = {
	appName: 'Mednean',
	development,
	apiUrl: process.env.REACT_APP_SERVER_IP,
	domainUrl: process.env.REACT_APP_FRONT_DOMAIN,
};

export default settings;
