import { makeAutoObservable } from 'mobx';
import React from 'react';

import Department from '../../components/Modals/Department';
import openSuccessModal from '../../components/showModalFunc';
import { Departments } from '../../types/Departments';
import authAxiosClient from '../../utils/authAxiosClient';

export class AdminDepartmentsStore {
	departments: Departments[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	createValidData(values: any) {
		const ukrData = {
			...values,
			name: values.nameUkr,
			language_id: 1,
		};
		const rusData = {
			...values,
			name: values.nameRus,
			language_id: 2,
		};

		return { ukrData, rusData };
	}

	openCreateDepartmentModal() {
		const { destroy } = openSuccessModal({
			width: '40%',
			keyboard: false,
			textContent: (
				<Department
					initialValues={{}}
					onSubmit={(values: any) => {
						this.createDepartment(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	openEditDepartmentModal(department: any) {
		const { destroy } = openSuccessModal({
			width: '40%',
			keyboard: false,
			textContent: (
				<Department
					initialValues={{
						nameRus: department.languages.ru?.name,
						nameUkr: department.languages.ua?.name,
						email: department.email,
						phone: department.phone,
						id: department.id,
					}}
					onSubmit={(values: any) => {
						this.editDepartment(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	async createDepartment(values: any) {
		const { ukrData, rusData } = this.createValidData(values);

		try {
			const { data } = await authAxiosClient.post(
				'/admin/departments',
				ukrData
			);
			rusData.id = data.data.id;
			this.editDepartment(rusData);
			// eslint-disable-next-line no-empty
		} finally {
		}
	}

	async editDepartment(data: any) {
		const { ukrData, rusData } = this.createValidData(data);
		try {
			await authAxiosClient.put(`/admin/departments/${data.id}`, ukrData);
			await authAxiosClient.put(`/admin/departments/${data.id}`, rusData);
			await this.getDepartments();
			// eslint-disable-next-line no-empty
		} finally {
		}
	}

	async getDepartments() {
		try {
			const { data } = await authAxiosClient.get(
				'/admin/departments?per-page=0'
			);
			this.departments = data.data;
			// eslint-disable-next-line no-empty
		} finally {
		}
	}

	getDepartmentName(id: number) {
		const department = this.departments.find(item => item.id === id);
		return department?.name;
	}
	async deleteDepartment(id: number) {
		try {
			await authAxiosClient.delete(`/admin/departments/${id}`);
			await this.getDepartments();
			// eslint-disable-next-line no-empty
		} finally {
		}
	}
}
