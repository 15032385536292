import { MessageOutlined } from '@ant-design/icons';
import { Col, List, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { EmptyData } from '../../../components/EmptyData';
import openSuccessModal from '../../../components/showModalFunc';
import { userStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetNotifications = observer(() => {
	const { t } = useTranslation();

	return (
		<CabinetLayout>
			<PageTitle title={t('Уведомления')} />
			<Row className={styles.container} align="middle" justify="center">
				<Col span={24}>
					<List
						itemLayout="horizontal"
						locale={{
							emptyText: (
								<EmptyData
									icon={<MessageOutlined />}
									text={t('У вас нет новых уведомлений')}
								/>
							),
						}}
						dataSource={userStore.userNotifications}
						renderItem={item => (
							<List.Item
								className={styles.listItem}
								key={item.id}
								onClick={() => {
									const { destroy } = openSuccessModal({
										textContent: item.data,
										title: item.text,
										width: '60%',
										okText: t('Спасибо, ознакомился'),
										keyboard: false,
										handleSubmit: () => {
											if (!item.seen) {
												userStore.setSeenNotification(item.id);
											}
											destroy();
										},
									});
								}}
							>
								<List.Item.Meta title={item.text} description={item.data} />
								<Row>
									<Col>
										{item.seen ? (
											<Tag color="green">Прочитано</Tag>
										) : (
											<Tag color="red">Не прочитано</Tag>
										)}
									</Col>
									<Col>
										<p>
											{dayjs(item.created_at * 1000).format('DD.MM.YYYY HH:mm')}
										</p>
									</Col>
								</Row>
							</List.Item>
						)}
					/>
				</Col>
			</Row>
		</CabinetLayout>
	);
});

export default CabinetNotifications;
