import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adminNotificationsStore } from '../../../stores';
import { AdminTable } from '../../Admin/Table';
import AdminUserInfo from '../../Admin/UserInfo/AdminUserInfo';
import styles from './styles.module.scss';

export const NotificationDetail = observer(
	({ onCancel, data, title = '' }: any) => {
		const { t } = useTranslation();
		React.useEffect(() => {
			adminNotificationsStore.getUserNotifications(data.id);
		}, []);

		const columns = [
			{
				title: 'ID',
				dataIndex: 'id',
			},
			{
				title: 'Уведомление',
				dataIndex: 'text',
			},
			{
				title: 'Дата',
				dataIndex: 'created_at',
				render: (date: any) => (
					<>{new Date(date * 1000).toLocaleDateString()}</>
				),
			},
			{
				title: 'Статус',
				dataIndex: 'seen',
				render: (seen: boolean) =>
					seen ? (
						<div className={styles.success}>Прочитано</div>
					) : (
						<div className={styles.danger}>Не прочитано</div>
					),
			},
		];

		return (
			<>
				<AdminUserInfo user={data} loading={adminNotificationsStore.loading} />
				<h3 className={styles.title}>{title}</h3>
				<div className={styles.container}>
					<AdminTable
						pagination={false}
						columns={columns}
						data={adminNotificationsStore.notifications}
					/>
				</div>
				<Row justify="center" gutter={8}>
					<Col>
						<Button
							className={styles.closeButton}
							type="primary"
							onClick={onCancel}
						>
							{t('Закрыть')}
						</Button>
					</Col>
				</Row>
			</>
		);
	}
);
