import { Form } from 'antd';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './inputFieldTitle.module.scss';

interface InputFieldProps {
	title: string;
	errorMessage?: string | boolean;
	icon?: React.ReactNode;
	required?: boolean;
	style?: any;
	className?: any;
}

const InputFieldTitle = ({
	title,
	icon,
	required = true,
	errorMessage,
	className,
	children,
	style,
}: PropsWithChildren<InputFieldProps>) => (
	<div className={classnames([styles.inputWrapper, className])} style={style}>
		<div className={styles.inputInfo}>
			{required && <span className={styles.asterisk}>*</span>}{' '}
			<span>{title}</span>
			{icon}
		</div>
		<Form.Item
			className={errorMessage ? styles.errorMassage : ''}
			validateStatus={errorMessage ? 'error' : undefined}
			help={errorMessage}
		>
			{children}
		</Form.Item>
	</div>
);

export default InputFieldTitle;
