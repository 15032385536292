import { Button, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminCalendarStore, languageStore } from '../../../../stores';
import styles from './styles.module.scss';

const CalendarSettings = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminCalendarStore;
	const { data, _meta } = adminCalendarStore.timeList;

	const onSearch = (name: string) => {
		if (!name) {
			adminCalendarStore.setFilters({ name: null });
		} else {
			adminCalendarStore.setFilters({ name });
		}
		adminCalendarStore.getTimeList();
	};
	const departmentChange = (department_id: string) => {
		if (!department_id) {
			adminCalendarStore.setFilters({ department_id: null });
		} else {
			adminCalendarStore.setFilters({ department_id });
		}
		adminCalendarStore.getTimeList();
	};
	const dateHandler = (date: any | null, dateString: string[]) => {
		if (!date) {
			adminCalendarStore.setFilters({ start: null, end: null });
		} else {
			const [start, end] = dateString;
			adminCalendarStore.setFilters({ start, end });
		}
		adminCalendarStore.getTimeList();
	};
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminCalendarStore.getTimeList(page, pageSize, sortField);
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('Основные услуги'),
			dataIndex: ['product', 'name'],
		},
		{
			title: t('Филия'),
			dataIndex: ['department', 'name'],
		},
		{
			title: t('Номер телефона'),
			dataIndex: ['department', 'phone'],
		},
		{
			title: t('Диапазон дат'),
			key: 'day_start',
			render: (item: any) => (
				<>
					{item?.day_start} - {item?.day_end}
				</>
			),
			sorter: true,
		},
		{
			title: t('Диапазон времени'),
			key: 'time_start',
			render: (item: any) => (
				<>
					{item?.time_start} - {item?.time_end}
				</>
			),
			sorter: true,
		},
		{
			title: t('Количество мест'),
			dataIndex: 'count',
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (quene: any) => (
				<EditDeleteIcons
					disabled={dayjs() > dayjs(quene.period_end, 'YYYY-MM-DD hh:mm:ss')}
					onEdit={() => adminCalendarStore.openModalEdit(quene)}
					onDelete={() => adminCalendarStore.deleteQuene(quene.id)}
				/>
			),
		},
	];

	React.useEffect(() => {
		adminCalendarStore.getTimeList();
	}, [languageStore.currentLang]);

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.btn}
					type="primary"
					onClick={() => adminCalendarStore.openModalCreate()}
				>
					{t('Создать очередь')}
				</Button>
			</Row>
			<AdminTable
				pagination={{
					options: {
						..._meta,
						onChange: paginationHandler,
					},
				}}
				loading={loading}
				columns={columns}
				data={data}
			>
				<TableFilters
					searchFieldConfigs={{
						loading,
						disabled: loading,
						className: styles.btnSearch,
						placeholder: t('Название услуги'),
						onSearch: (value: string) => onSearch(value),
					}}
					datesRangesConfigs={{ onChange: dateHandler, disabled: loading }}
					departmentFieldConfigs={{
						onChange: departmentChange,
						loading,
						disabled: loading,
					}}
				/>
			</AdminTable>
		</AdminLayout>
	);
});

export default CalendarSettings;
