import { Button, Col, Row } from 'antd';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CabinetLayout } from '../../../../components/Cabinet/Layout';
import { PageTitle } from '../../../../components/Cabinet/PageTitle';
import { CABINET_SETTINGS } from '../../../../pathNames';
import styles from './styles.module.scss';

const LicenseAgreement = React.lazy(
	async (): Promise<any> =>
		await import('../../../../components/LicenseAgreement')
);

const CabinetSettingsLicense = (): any => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<CabinetLayout>
			<PageTitle title={t('Настройки профиля')} />
			<div className={styles.container}>
				<Suspense
					fallback={
						<Row justify="center">
							<Col md={24} xs={24}>
								<div className={styles.textWrapper}>
									<LicenseAgreement agreement={false} />
									<br />
								</div>
							</Col>
						</Row>
					}
				>
					<LicenseAgreement agreement={true} />
				</Suspense>

				<Row gutter={10} align="bottom" justify="center">
					<Col md={16} xs={24}>
						<Button
							type="default"
							onClick={() => navigate(CABINET_SETTINGS)}
							block
							size="large"
						>
							{t('Назад')}
						</Button>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetSettingsLicense;
