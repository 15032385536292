import { Button, Form } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { passwordRecovery } from '../../../stores';
import InputField from '../../InputField';
import styles from './styles.modules.scss';

interface AgreementPopupProps {
	onSubmit: (props?: any) => void;
}

export const NewPasswordWithCode = observer(
	({ onSubmit }: AgreementPopupProps) => {
		const { t } = useTranslation();
		return (
			<Formik
				initialValues={{
					phone: '',
					password: '',
					recovery_token: '',
				}}
				validationSchema={Yup.object().shape({
					recovery_token: Yup.string().required(
						t<string>('Обязательное поле!')
					),
					password: Yup.string().required(t<string>('Обязательное поле!')),
				})}
				onSubmit={values => {
					onSubmit(values);
				}}
			>
				{({
					handleSubmit,
					touched,
					errors,
					setFieldValue,
					setFieldTouched,
				}) => (
					<Observer>
						{() => (
							<Form layout="vertical" onSubmitCapture={handleSubmit}>
								<span className={styles.label}>Код</span>
								<InputField
									placeholder="- - - -"
									required={false}
									className={styles.input}
									errorMessage={touched.recovery_token && errors.recovery_token}
									onBlur={() => setFieldTouched('recovery_token')}
									onChange={event =>
										setFieldValue('recovery_token', event.target.value)
									}
								/>
								<InputField
									value={`+${passwordRecovery.phone}`}
									disabled
									title="Телефон"
									required={true}
									className={styles.formControl}
								/>
								<InputField
									title={t('Новый пароль')}
									type="password"
									required={true}
									errorMessage={touched.password && errors.password}
									onBlur={() => setFieldTouched('password')}
									onChange={event =>
										setFieldValue('password', event.target.value)
									}
								/>
								<Button
									block
									size="middle"
									type="primary"
									htmlType="submit"
									loading={passwordRecovery.loading}
									disabled={passwordRecovery.loading}
								>
									{t('Подтвердить')}
								</Button>
							</Form>
						)}
					</Observer>
				)}
			</Formik>
		);
	}
);
