import { notification } from 'antd';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import settings from '../settings';
import { PasswordRecoveryRequestValues } from '../types/PasswordRecoveryRequestValues';

export class PasswordRecoveryStore {
	code = '';
	phone = '';
	loading = false;
	phoneNotFound = false;

	constructor() {
		makeAutoObservable(this);
	}

	async sendRecovery(values: PasswordRecoveryRequestValues) {
		this.loading = true;
		this.phoneNotFound = false;
		this.phone = values.phone;
		try {
			const { data } = await axios.put(
				`${settings.apiUrl}/v1/user/recovery-send`,
				values
			);
			notification.success({ message: data.data.msg });
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				if (error && error.response && error.response.status === 422) {
					this.phoneNotFound = true;
				}
			}
		} finally {
			this.loading = false;
		}
	}

	async sendNewPassword(values: {
		phone: string;
		password: string;
		recovery_token: string;
	}) {
		this.loading = true;
		try {
			const { data } = await axios.put(
				`${settings.apiUrl}/v1/user/recovery`,
				values
			);
			notification.success({ message: data.data.msg });
		} catch (error: any) {
			if (error && error.response && error.response.status === 422) {
				notification.error({
					message: error.response?.data?.data[0]?.message,
				});
			}
		} finally {
			this.loading = false;
		}
	}
}
