import { Button, Form } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { signUpStore } from '../../../stores';
import InputField from '../../InputField';
import styles from './enterSignUpMobileCodeForm.module.scss';

export const SignUpSmsCodePopup = observer(({ onSubmit }: any) => {
	const { t } = useTranslation();
	return (
		<Formik
			initialValues={{
				code: '',
			}}
			validationSchema={Yup.object().shape({
				code: Yup.string()
					.required(t<string>('Обязательное поле!'))
					.matches(/^\d+$/, t<string>('Буквы не допустимы'))
					.max(4, '4 символа'),
			})}
			onSubmit={onSubmit}
		>
			{({
				values,
				handleSubmit,
				isValid,
				touched,
				errors,
				setFieldValue,
				setFieldTouched,
			}) => (
				<Observer>
					{() => (
						<Form layout="vertical" onSubmitCapture={handleSubmit}>
							<span className={styles.label}>Код</span>
							<InputField
								placeholder="- - - -"
								required={false}
								className={styles.input}
								value={values.code}
								errorMessage={touched.code && errors.code}
								onBlur={() => setFieldTouched('code')}
								onChange={event => setFieldValue('code', event.target.value)}
							/>
							<br />
							<Button
								block
								size="middle"
								type="primary"
								htmlType="submit"
								loading={signUpStore.loading}
								disabled={!isValid}
							>
								{t('Регистрация')}
							</Button>
						</Form>
					)}
				</Observer>
			)}
		</Formik>
	);
});
