import { makeAutoObservable, runInAction } from 'mobx';

import { User, UserProfiles } from '../types/User';
import authAxiosClient from '../utils/authAxiosClient';

interface Notification {
	id: number;
	type: number;
	text: string;
	data?: null;
	created_at: number;
	updated_at: number;
	seen: boolean;
}
interface Password {
	password: string;
	passwordConfirm: string;
}

export class UserStore {
	userInfo = {} as User;
	userNotifications: Notification[] = [];
	notSeenNotifications = 0;
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async getInfo() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get('/user/my-self');
			runInAction(() => {
				this.userInfo = data.data;
			});
		} finally {
			this.setLoading(false);
		}
	}

	async getNotifications() {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get('/notifications');
			this.userNotifications = data.data;
			this.notSeenNotifications = data.data.filter(
				(item: Notification) => !item.seen
			).length;
		} finally {
			this.setLoading(false);
		}
	}

	async setSeenNotification(id: number) {
		try {
			this.setLoading(true);
			await authAxiosClient.put(`/notifications/${id}`);
			await this.getNotifications();
		} finally {
			this.setLoading(false);
		}
	}

	async changePassword(values: Password) {
		const { password, passwordConfirm } = values;
		try {
			this.setLoading(true);
			await authAxiosClient.put('/user/change-password', {
				password,
				new_password: passwordConfirm,
			});
		} finally {
			this.setLoading(false);
		}
	}

	async changeAvatar(img_base64: string) {
		try {
			this.setLoading(true);
			await authAxiosClient.post('/user/avatar', { img_base64 });
			this.getInfo();
		} finally {
			this.setLoading(false);
		}
	}

	async changeUserInfo(values: UserProfiles) {
		const { email, gender, birthday, middle_name, first_name, last_name } =
			values;
		try {
			this.setLoading(true);
			await authAxiosClient.put('/user/profile', {
				email,
				gender,
				birthday,
				middle_name,
				first_name,
				last_name,
			});
			this.getInfo();
		} catch (error) {
			return error;
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
