import { Button, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import styles from '../../../../components/Admin/Table/styles.module.scss';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminServicesStore, languageStore } from '../../../../stores';

const Services = observer(() => {
	const { t } = useTranslation();

	React.useEffect(() => {
		adminServicesStore.getServices();
	}, [languageStore.currentLang]);
	const { _meta, data } = adminServicesStore.services;

	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminServicesStore.getServices(page, pageSize, sortField);
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('Название'),
			dataIndex: 'name',
		},
		{
			title: t('Описание'),
			dataIndex: 'desc',
		},
		{
			title: t('Цена'),
			dataIndex: 'price',
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onEdit={() => adminServicesStore.openModalEditService(item)}
					onDelete={() => adminServicesStore.deleteServices(item.id)}
				/>
			),
		},
	];

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.btn}
					type="primary"
					onClick={() => adminServicesStore.openModalCreateService()}
				>
					{t('Добавить')}
				</Button>
			</Row>
			<br />
			<AdminTable
				pagination={{
					options: { ..._meta, onChange: paginationHandler },
				}}
				columns={columns}
				data={data}
			/>
		</AdminLayout>
	);
});

export default Services;
