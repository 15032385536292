import { makeAutoObservable } from 'mobx';
import React from 'react';

import AcceptToQueue from '../../components/Modals/AcceptToQueue';
import { OrderCreateChange } from '../../components/Modals/OrderCreateChange';
import { OrdersModal } from '../../components/Modals/Orders';
import openSuccessModal from '../../components/showModalFunc';
import { Orders } from '../../types/Orders';
import authAxiosClient from '../../utils/authAxiosClient';
import { getBase64FromUrl } from '../../utils/toBase64';
import { adminCalendarStore } from '../index';

const initialValues = {
	user_id: null,
	product_id: null,
	price: null,
	price_discount: null,
};

interface Filters {
	name: null | string;
	start: null | string;
	end: null | string;
	id?: number;
	user_id?: number;
}

export class AdminOrdersStore {
	orders = {} as Orders;
	filters = {} as Filters;
	modalOrders = {} as Orders;
	modalFilters = {} as Filters;
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async createValidData(values: any) {
		const {
			price,
			discount,
			price_discount,
			img_base64,
			desc,
			url,
			time,
			languages: { ru, ua },
		} = values;
		const validData = {
			price,
			discount,
			price_discount,
			img_base64,
			desc,
			url,
			time,
		};
		if (!validData.img_base64) {
			validData.img_base64 = await getBase64FromUrl('/images/services/2.webp');
		}
		const uaData = { language_id: 1, ...validData, ...ua };
		const ruData = { language_id: 2, ...validData, ...ru };

		return { uaData, ruData };
	}

	openModalCreate() {
		const { destroy } = openSuccessModal({
			title: 'Инвойс',
			width: '35%',
			keyboard: false,
			textContent: (
				<OrderCreateChange
					onSubmit={async (values: any) => {
						await this.createOrder(values);
						destroy();
					}}
					initialValues={initialValues}
				/>
			),
		});
	}

	openModalDetail(values: any) {
		const { destroy } = openSuccessModal({
			width: '70%',
			keyboard: false,
			textContent: (
				<OrdersModal
					user={{ ...values.user, ...values }}
					onCancel={() => destroy()}
					title="Детально"
				/>
			),
		});
	}

	openModalEdit(orderInfo: any) {
		try {
			const {
				price,
				price_discount,
				user_id,
				user: {
					phone,
					profiles: { last_name, first_name },
				},
			} = orderInfo;

			const initial = {
				id: orderInfo.id,
				product_id: orderInfo.product?.id,
				product_name: orderInfo.product?.name,
				price,
				price_discount,
				user_id,
				phone,
				last_name,
				first_name,
			};
			const { destroy } = openSuccessModal({
				width: '35%',
				keyboard: false,
				textContent: (
					<OrderCreateChange
						onSubmit={(values: any) => {
							this.editOrder(values);
							this.getModalOrders(values.user_id);
							destroy();
						}}
						initialValues={initial}
						data={orderInfo}
						onCancel={() => destroy()}
					/>
				),
			});
		} catch (e) {
			console.log('ERROR', e);
		}
	}

	openModalAcceptToQueue(patient: any) {
		const { destroy } = openSuccessModal({
			width: '38%',
			keyboard: false,
			textContent: (
				<AcceptToQueue
					patient={patient}
					onSubmit={(status: number) => {
						this.changeOrderStatus(patient.order.id, status);
						destroy();
					}}
					onDestroy={() => destroy()}
				/>
			),
		});
	}

	async editOrder(values: any) {
		this.setLoading(true);
		const { id, product_id, price, price_discount } = values;
		const data = { id, product_id, price, price_discount };
		try {
			await authAxiosClient.put(`/admin/orders/invoice/${id}`, data);
		} finally {
			this.setLoading(false);
		}
	}

	async createOrder(values: any) {
		this.setLoading(true);
		try {
			await authAxiosClient.post('/admin/orders/invoice', values);
			await this.getOrders();
		} finally {
			this.setLoading(false);
		}
	}

	async getOrders(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(
				`/admin/orders?page=${page}&per-page=${pageSize}&sort=${sortField}`,
				{
					params: {
						...this.filters,
					},
				}
			);
			this.orders = data;
		} finally {
			this.setLoading(false);
		}
	}

	async getModalOrders(page = 1, pageSize = 10, sortField = '') {
		this.setLoading(true);
		try {
			const { data } = await authAxiosClient.get(
				`/admin/orders?page=${page}&per-page=${pageSize}&sort=${sortField}`,
				{
					params: {
						...this.modalFilters,
					},
				}
			);
			this.modalOrders = data;
		} finally {
			this.setLoading(false);
		}
	}

	async changeOrderStatus(id: number, status: number) {
		try {
			await authAxiosClient.put(`/admin/orders/${id}/set-status`, {
				status,
				id,
			});
			await adminCalendarStore.getTime();
			// eslint-disable-next-line no-empty
		} finally {
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	setFilters(filters: any) {
		this.filters = { ...this.filters, ...filters };
	}

	setModalFilters(filters: any) {
		this.modalFilters = { ...this.modalFilters, ...filters };
	}

	clearFilters() {
		this.filters = {} as Filters;
	}

	clearModalFilters() {
		this.modalFilters = {} as Filters;
	}
}
