import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { NotPermissions } from '../pages/Common/NotPermissions';
import { ADMIN, SIGN_IN } from '../pathNames';
import { authStore } from '../stores';
import { Role } from './routes';

interface Props {
	children: JSX.Element;
	roles: string[];
}

export const PrivateRoute = ({ children, roles }: Props) => {
	const { authorized, loading, token } = authStore;
	const { role, permissions } = token;
	const navigate = useNavigate();
	const location = useLocation();
	const haveAdminAccess =
		role === Role.Admin || role === Role.Moderator || role === Role.Department;
	const isOwner = role === Role.Root;
	const isUser = role === Role.User;
	const userHasRequiredRole = roles.includes(token.role);
	const userHasPermissions = permissions?.includes(location.pathname);

	React.useEffect(() => {
		if (haveAdminAccess) {
			navigate(ADMIN);
		}
	}, [authorized]);

	if (loading) {
		return <p className="container">Checking auth..</p>;
	}

	if (isOwner) return children;
	if (isUser) return children;

	if (!authorized) {
		return <Navigate to={SIGN_IN} state={{ from: location }} />;
	}

	if (authorized && !userHasRequiredRole) return <NotPermissions />;
	if (!userHasPermissions) return <NotPermissions />;

	return children;
};
