import { LeftOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { referalsStore } from '../../../stores';
import { EmptyData } from '../../EmptyData';
import styles from './styles.module.scss';

const ReferalsList = ({ onBack, nextPage }: any) => {
	const { t } = useTranslation();
	const { loading, referals } = referalsStore;
	const { data, _meta } = referals;
	const columns = [
		{
			dataIndex: 'profiles',
			render: (text: any, record: any, index: any) => <>{++index}</>,
		},
		{
			dataIndex: 'profiles',
			render: (text: any) =>
				text?.last_name && text?.first_name ? (
					<>
						{text?.last_name} {text?.first_name}
					</>
				) : (
					'Реферал'
				),
		},
		{
			title: t('Рефералы'),
			dataIndex: 'referralCount',
			render: (referralCount: number) => (
				<span className={styles.tableItem}>{referralCount}</span>
			),
		},
		{
			title: t('Кэш бонусы'),
			dataIndex: 'fullReferral',
			render: (fullReferral: number) => (
				<span className={styles.tableItem}>{fullReferral}</span>
			),
		},
	];

	React.useEffect(() => {
		referalsStore.getReferalsList();
	}, []);

	const paginationHandler = (page: any, pageSize: any) => {
		referalsStore.getReferalsList(page, pageSize);
	};

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Button onClick={onBack} type="link" block>
						<LeftOutlined className={styles.icon} />
						{t('Назад')}
					</Button>
				</Col>
				<Col>
					<Button onClick={onBack} type="link" block>
						<UndoOutlined className={styles.icon} />
					</Button>
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<h3 className={styles.title}>
						{referalsStore.getGenerationRomanNum(referalsStore.selectedLevel)}{' '}
						{t('Поколение')}
					</h3>
				</Col>
			</Row>
			<Table
				loading={loading}
				rowKey="id"
				locale={{
					emptyText: <EmptyData />,
				}}
				columns={columns}
				pagination={{
					onChange: paginationHandler,
					hideOnSinglePage: true,
					showSizeChanger: true,
					current: _meta?.currentPage,
					pageSize: _meta?.perPage,
					total: _meta?.totalCount,
				}}
				className={styles.table}
				dataSource={data}
				onRow={record => ({
					onClick: () => {
						referalsStore.selectReferal(record);
						nextPage();
					},
				})}
			/>
		</>
	);
};

export default observer(ReferalsList);
