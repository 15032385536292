import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CabinetLayout } from '../../components/Cabinet/Layout';
import { PageTitle } from '../../components/Cabinet/PageTitle';
import Referals from '../../components/Distributors/CabinetReferals';
import Transactions from '../../components/Distributors/CabinetTransactions';
import styles from './styles.module.scss';

const Cabinet = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const transactionsRef = React.useRef<HTMLDivElement | null>(null);
	const referralsRef = React.useRef<HTMLDivElement | null>(null);
	React.useEffect(() => {
		if (location.hash === '#transactions') {
			return transactionsRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
		if (location.hash === '#referrals') {
			return referralsRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [location.hash]);
	return (
		<CabinetLayout>
			<Row gutter={18}>
				<Col ref={transactionsRef} xs={24} md={13}>
					<div style={{ height: '100%' }}>
						<PageTitle title={t('Транзакции')} />
						<div className={styles.listContainer}>
							<Transactions />
						</div>
					</div>
				</Col>
				<Col ref={referralsRef} xs={24} md={11}>
					<div style={{ height: '100%' }}>
						<PageTitle title={t('Рефералы')} />
						<div className={styles.listContainer}>
							<Referals />
						</div>
					</div>
				</Col>
			</Row>
		</CabinetLayout>
	);
};

export default Cabinet;
