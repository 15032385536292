import { CheckCircleOutlined, RiseOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import {
	adminStatisticsStore,
	adminTransactionsStore,
} from '../../../../stores';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons/index';

const StatisticsDetail = observer(() => {
	const { _meta, data } = adminTransactionsStore.replenishment;
	const { t } = useTranslation();
	const columns = [
		{
			title: 'ID',
			key: 'id',
			render: (user: any) => <>{user.userProfile.id}</>,
		},
		{
			title: t('ФИО'),
			dataIndex: 'userProfile',
			render: (user: any) => (
				<>
					{user.profiles.first_name} {user.profiles.last_name}{' '}
					{user.profiles.middle_name}
				</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'userProfile',
			render: (user: { phone: string }) => user.phone,
		},
		{
			title: t('Кол-во пополнений'),
			dataIndex: 'totalTransactions',
		},
		{
			title: t('Сумма в грн'),
			dataIndex: 'totalFill',
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onOpen={() => adminStatisticsStore.openModalDetail(item.userProfile)}
				/>
			),
		},
	];
	const dateRangesHandler = (dates: any, values: string[]) => {
		if (!dates) {
			adminTransactionsStore.setFilters({ start: null, end: null });
		} else {
			const [startDate, endDate] = values;
			const start = dayjs(startDate).format('YYYY-MM-DD');
			const end = dayjs(endDate).format('YYYY-MM-DD');
			adminTransactionsStore.setFilters({ start, end });
		}
		adminTransactionsStore.getReplenishment();
		adminTransactionsStore.calculateTotal();
	};

	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminTransactionsStore.getReplenishment(page, pageSize, sortField);
	};

	React.useEffect(() => {
		adminTransactionsStore.getReplenishment();

		return () => {
			adminTransactionsStore.clearFilters();
		};
	}, []);

	return (
		<AdminLayout>
			<Row gutter={8}>
				<Col>
					<Card>
						<Statistic
							title={t('Общее кол-во пополнений')}
							value={adminTransactionsStore.totalTransactions}
							valueStyle={{ color: '#64d8e5' }}
							prefix={<CheckCircleOutlined />}
							suffix={t('раз(а)')}
						/>
					</Card>
				</Col>
				<Col>
					<Card>
						<Statistic
							title={t('Полная сумма за период')}
							value={Math.round(adminTransactionsStore.totalFills)}
							valueStyle={{ color: '#7acd74' }}
							prefix={<RiseOutlined />}
							suffix="грн"
						/>
					</Card>
				</Col>
			</Row>
			<br />
			<AdminTable
				pagination={{ options: { ..._meta, onChange: paginationHandler } }}
				loading={adminTransactionsStore.loading}
				columns={columns}
				data={data}
			>
				<TableFilters
					datesRangesConfigs={{
						onChange: dateRangesHandler,
						disabled: adminTransactionsStore.loading,
						loading: adminTransactionsStore.loading,
					}}
				/>
			</AdminTable>
		</AdminLayout>
	);
});

export default StatisticsDetail;
