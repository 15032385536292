import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { PageTitle } from '../../../../components/Cabinet/PageTitle';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { ADMIN_SETTINGS_ACCESS } from '../../../../pathNames';
import { adminMenuStore, adminUsersStore } from '../../../../stores';
import { User } from '../../../../types/User';
import { getRoleName } from '../../../../utils/helperFunctions';
import styles from './styles.module.scss';

const RoleList = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		adminUsersStore.getUsersWithRoles();
	}, []);

	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminUsersStore.getUsers(page, pageSize, sortField);
	};

	const columns = [
		{
			title: 'ID ',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('ФИО'),
			dataIndex: 'profiles',
			render: (profiles: any) => (
				<>
					{profiles.first_name} {profiles.last_name}
				</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: t('Должность'),
			dataIndex: 'role',
			render: (role: string) => <span>{t(getRoleName(role))}</span>,
		},
		{
			title: t('Дата создания'),
			sorter: true,
			dataIndex: 'created_at',
			render: (created_at: any) => (
				<>{new Date(created_at * 1000).toLocaleDateString()}</>
			),
		},
		{
			title: t('Действие'),
			render: (user: User) => (
				<EditDeleteIcons
					onEdit={() => {
						adminMenuStore.setSidebarLink(ADMIN_SETTINGS_ACCESS);
						adminUsersStore.setSelectedAdmin({
							permissions: user.permissions,
							departments: user.departments.map(department => department.id),
							user_id: user.id,
							role: user.role,
						});
						navigate(ADMIN_SETTINGS_ACCESS);
					}}
					onDelete={() => adminUsersStore.deleteUserRoles(user.id)}
				/>
			),
		},
	];
	const { _meta, data }: any = adminUsersStore.usersWithRoles;

	return (
		<AdminLayout>
			<div className={styles.container}>
				<PageTitle customStyles={styles.title} title={t('Список должностей')} />
				<AdminTable
					pagination={{ options: { ..._meta, onChange: paginationHandler } }}
					loading={adminUsersStore.loading}
					className={styles.table}
					columns={columns}
					data={data}
				/>
			</div>
		</AdminLayout>
	);
});

export default RoleList;
