import { Button, Col, Input, Row } from 'antd';
import { Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { CABINET } from '../../../pathNames';
import { transactionsStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetDeposite = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const validationSchema = Yup.object().shape({
		amount: Yup.number().required(t<string>('Обязательное поле')),
	});

	return (
		<CabinetLayout>
			<PageTitle title={t('Пополнить счёт')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col>
						<div className={styles.titleBox}>
							<h3 className={styles.title}>{t('Пополнить')}</h3>
							<p>
								{t('Все услуги оплаченные через приложение на 20% дешевле')}
							</p>
						</div>
					</Col>
				</Row>
				<Row justify="center">
					<Formik
						validationSchema={validationSchema}
						validateOnMount={true}
						initialValues={{ amount: '', type: 'card' }}
						onSubmit={(values: any) => {
							if (values.card_id) {
								values.type = 'token';
							}
							transactionsStore.createDeposit(values);
						}}
					>
						{({
							handleSubmit,
							values,
							isValid,
							handleBlur,
							errors,
							touched,
							setFieldValue,
							submitForm,
						}) => (
							<Observer>
								{() => (
									<>
										<Col span={12}>
											<form onSubmit={handleSubmit}>
												<p className={styles.grayText}>
													{t('Сумма пополнения')}
												</p>
												<Input
													value={values.amount}
													type="number"
													name="amount"
													onBlur={handleBlur}
													className={styles.input}
													placeholder={t('Сумма пополнения')}
													onChange={e => {
														setFieldValue('amount', e.target.value);
													}}
												/>
												{errors.amount && touched.amount ? (
													<span className={styles.danger}>{errors.amount}</span>
												) : (
													Boolean(Number(values.amount)) && (
														<span className={styles.danger}>
															{t('Комисия банка составит', {
																amount: (Number(values.amount) * 2.5) / 100,
															})}
														</span>
													)
												)}
												<br />
												<br />
											</form>
										</Col>
										<Col xs={24} md={16}>
											<br />
											<br />
											<Row justify="center" gutter={[12, 12]}>
												<Col span={12}>
													<Button
														onClick={() => navigate(CABINET)}
														block
														size="large"
													>
														{t('Отменить')}
													</Button>
												</Col>
												<Col span={12}>
													<Button
														disabled={!isValid}
														onClick={submitForm}
														block
														size="large"
														type="primary"
													>
														{t('Оплатить')}
													</Button>
												</Col>
											</Row>
										</Col>
									</>
								)}
							</Observer>
						)}
					</Formik>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetDeposite;
