import { Button, Col, Result, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NotPermissions = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Row justify="center">
			<Col>
				<br />
				<br />
				<br />
				<Result
					status="error"
					title={t('Доступ ограничен')}
					subTitle={t(
						'За более детальной информацией Обратитесь к администратору'
					)}
					extra={[
						<Button
							size="middle"
							danger
							type="primary"
							onClick={() => navigate(-1)}
						>
							{t('Вернуться назад')}
						</Button>,
					]}
				/>
			</Col>
		</Row>
	);
};
