import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CABINET_SERVICES } from '../../../pathNames';
import { servicesStore } from '../../../stores';
import DatePicker from '../../common/DatePicker';
import styles from './styles.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const BookService = ({ onBook, onCancel }: any) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	React.useEffect(() => {
		if (!servicesStore.selectedService.id) {
			navigate(CABINET_SERVICES);
		}
		servicesStore.getAvailableDay({
			product_id: servicesStore.selectedService.id,
			month: dayjs().format('YYYY-MM-DD'),
		});
	}, []);

	const getStatusStyle = (status: number) => {
		switch (status) {
			case 0:
				return '#FF83A1';
			case 1:
				return '#59CF8F';
			case 2:
				return '#EAEAEA';
			case 3:
				return '#EAEAEA';
		}
	};

	const renderExtraFooter = () => (
		<>
			<div className={styles.flexColumn}>
				<div
					style={{ background: getStatusStyle(1) }}
					className={styles.square}
				/>
				<span>{t('Дата приема свободна')}</span>
			</div>
			<div className={styles.flexColumn}>
				<div
					style={{ background: getStatusStyle(0) }}
					className={styles.square}
				/>
				<span>{t('Свободных мест нет')}</span>
			</div>
			<div className={styles.flexColumn}>
				<div
					style={{ background: getStatusStyle(2) }}
					className={styles.square}
				/>
				<span>{t('Нет записи')}</span>
			</div>
		</>
	);

	const renderTime = (value: any) => {
		const key = value.date;

		const foundDate = servicesStore.availableTime[key];
		return foundDate?.time.map((item: any) => (
			<Select.Option
				disabled={item.status === 0}
				key={item.appointment_at}
				value={item.id}
			>
				{dayjs.unix(item.appointment_at).format('HH:mm')}
			</Select.Option>
		));
	};

	return (
		<>
			<Formik
				initialValues={{ timeId: '', date: '' }}
				onSubmit={({ timeId }) => {
					onBook(timeId);
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<Observer>
						{() => (
							<Row justify="center">
								<Col lg={12}>
									<form onSubmit={handleSubmit}>
										<div className={styles.titleBox}>
											<h3 className={styles.title}>
												{servicesStore.selectedService.name}
											</h3>
											<p>{t('Выберите дату и время')}</p>
										</div>
										<Row gutter={8} justify="center" align="bottom">
											<Col span={12}>
												<DatePicker
													dateRender={current => {
														const key = current
															.tz('Europe/Kiev', false)
															.startOf('day')
															.unix();

														return servicesStore.availableTime[key]?.time
															?.length ? (
															<div
																style={{
																	background: getStatusStyle(
																		servicesStore.availableTime[key]?.status
																	),
																}}
																className="ant-picker-cell-inner"
															>
																{current.date()}
															</div>
														) : (
															<div
																style={{ background: '#eaeaea' }}
																className="ant-picker-cell-inner"
															>
																{current.date()}
															</div>
														);
													}}
													format="DD.MM.YYYY"
													disabledDate={current => {
														const key = current
															.tz('Europe/Kiev', false)
															.startOf('day')
															.unix();

														const statusRecordDay =
															servicesStore.availableTime[key]?.status;
														switch (statusRecordDay) {
															case 0:
																return true;
															case 1:
																return false;
															case 2:
																return true;
															default:
																return true;
														}
													}}
													onChange={(date: any) => {
														if (!date) {
															setFieldValue('date', '');
															setFieldValue('timeId', '');
														}
														setFieldValue(
															'date',
															date
																?.tz('Europe/Kiev', false)
																.startOf('day')
																.unix()
														);
													}}
													className={styles.input}
													showToday={true}
													placeholder="- - -"
												/>
											</Col>
											<Col span={12}>
												<Select
													bordered={false}
													allowClear
													value={values.timeId}
													suffixIcon={<ClockCircleOutlined />}
													onChange={e => setFieldValue('timeId', e)}
													notFoundContent={
														<span>{t('Сперва выберите дату')}...</span>
													}
													className={styles.input}
													placeholder="- - -"
												>
													{values.date && renderTime(values)}
												</Select>
											</Col>
										</Row>
										<br />
										<Row gutter={[12, 12]}>{renderExtraFooter()}</Row>
										<br />
										<br />
										<Row justify="center" gutter={12}>
											<Col span={12}>
												<Button
													onClick={onCancel}
													type="ghost"
													block
													size="large"
												>
													{t('Отменить')}
												</Button>
											</Col>
											<Col span={12}>
												<Button
													disabled={!values.timeId}
													size="large"
													htmlType="submit"
													type="primary"
													block
												>
													{t('Забронировать')}
												</Button>
											</Col>
										</Row>
									</form>
								</Col>
							</Row>
						)}
					</Observer>
				)}
			</Formik>
		</>
	);
};

export default observer(BookService);
