import { WarningOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';

import InputFieldTitle from '../InputFieldTitle';
import styles from './inputField.module.scss';

type InputFieldProps = {
	title?: string;
	errorMessage?: string | boolean;
	titleStyle?: React.CSSProperties;
	wrapperClassName?: string;
	suffix?: React.ReactNode;
	fieldType?: string;
} & InputProps;

const InputField = ({
	title,
	required = false,
	wrapperClassName,
	errorMessage,
	suffix,
	titleStyle,
	fieldType = 'input',
	...props
}: InputFieldProps) => (
	<InputFieldTitle
		required={required}
		title={title || ''}
		errorMessage={errorMessage}
		style={titleStyle}
		className={wrapperClassName}
	>
		{fieldType === 'password' && (
			<Input.Password
				{...props}
				suffix={
					errorMessage ? (
						<WarningOutlined className={styles.suffixIco} />
					) : suffix ? (
						suffix
					) : (
						<span />
					)
				}
			/>
		)}
		{fieldType === 'input' && (
			<Input
				{...props}
				suffix={
					errorMessage ? (
						<WarningOutlined className={styles.suffixIco} />
					) : suffix ? (
						suffix
					) : (
						<span />
					)
				}
			/>
		)}
	</InputFieldTitle>
);

export default InputField;
