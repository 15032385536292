import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import styles from './styles.module.scss';

const OperationSuccess = () => {
	const { t } = useTranslation();

	return (
		<CabinetLayout>
			<PageTitle title={t('Статус оперции')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col>
						<CheckCircleOutlined className={styles.icon} />
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col className={styles.textCenter}>
						<p className={styles.text}>{t('Операция выполнена успешно')}</p>
						<p>
							{t('Через некоторое время детали появяться в ваших транзакциях')}
						</p>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default OperationSuccess;
