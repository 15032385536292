import { makeAutoObservable } from 'mobx';
import React from 'react';

import { ServiceInvoicesForm } from '../../components/Modals/ServicesInvoices';
import openSuccessModal from '../../components/showModalFunc';
import authAxiosClient from '../../utils/authAxiosClient';
import { getBase64FromUrl } from '../../utils/toBase64';

const initialValues = {
	url: null,
	department_id: null,
	img: null,
	price: null,
	price_discount: null,
	discount: null,
	created_at: null,
	languages: {
		ua: {
			name: null,
			desc: null,
		},
		ru: {
			name: null,
			desc: null,
		},
	},
};

export class AdminInvoicesStore {
	invoices: any = {};
	filters: any = {};
	loading = false;
	readonly invoiceType: number = 1;

	constructor() {
		makeAutoObservable(this);
	}

	async createValidData(values: any) {
		const {
			price,
			discount = 20,
			price_discount,
			img_base64,
			desc,
			url,
			time,
			department_id,
			languages: { ru, ua },
		} = values;

		const validData = {
			price,
			discount,
			price_discount,
			img_base64,
			desc,
			url,
			time,
			department_id,
		};

		if (!validData.img_base64) {
			validData.img_base64 = await getBase64FromUrl('/images/services/2.webp');
		}

		const uaData = {
			language_id: 1,
			type: this.invoiceType,
			...validData,
			...ua,
		};
		const ruData = {
			language_id: 2,
			type: this.invoiceType,
			...validData,
			...ru,
		};

		return {
			uaData,
			ruData,
		};
	}

	openModalCreateInvoice() {
		const { destroy } = openSuccessModal({
			title: 'Добавление инвойса',
			width: '50%',
			keyboard: false,
			textContent: (
				<ServiceInvoicesForm
					onSubmit={(values: any) => {
						this.createInvoice(values);
						destroy();
					}}
					onCancel={() => destroy()}
					initialValues={initialValues}
				/>
			),
		});
	}

	openModalEditInvoice(invoice: any) {
		const { destroy } = openSuccessModal({
			title: 'Редактирование основного инвойса',
			width: '50%',
			keyboard: false,
			textContent: (
				<ServiceInvoicesForm
					initialValues={invoice}
					onSubmit={(values: any) => {
						this.editInvoice(values);
						destroy();
					}}
					onCancel={() => destroy()}
				/>
			),
		});
	}

	async editInvoice(values: any) {
		this.setLoading(true);
		const { uaData, ruData } = await this.createValidData(values);

		try {
			await authAxiosClient.post(`/admin/products/${values.id}`, uaData);
			await authAxiosClient.post(`/admin/products/${values.id}`, ruData);
			this.getInvoices();
		} finally {
			this.setLoading(false);
		}
	}

	async createInvoice(values: any) {
		this.setLoading(true);

		const { uaData, ruData } = await this.createValidData(values);

		try {
			const { data } = await authAxiosClient.post('/admin/products', uaData);
			await authAxiosClient.post(`/admin/products/${data.data.id}`, ruData);
			await this.getInvoices();
			return data;
		} finally {
			this.setLoading(false);
		}
	}

	async getInvoices(page = 1, pageSize = 10, sortField?: string) {
		this.setLoading(true);
		const link = `/admin/products`;
		try {
			const { data } = await authAxiosClient.get(`${link}`, {
				params: {
					type: this.invoiceType,
					'per-page': pageSize,
					page,
					sort: sortField,
					...this.filters,
				},
			});
			this.invoices = data;
		} finally {
			this.setLoading(false);
		}
	}

	async deleteInvoice(id: any) {
		this.setLoading(true);
		try {
			await authAxiosClient.delete(`/ admin / products / ${id}`);
			this.getInvoices();
			// eslint-disable-next-line no-empty
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
	setFilters(filters: any) {
		this.filters = { ...this.filters, ...filters };
	}
	clearFilters() {
		this.filters = {};
	}
}
