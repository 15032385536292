import { Button, Checkbox, Col, Radio, Row, TreeSelect } from 'antd';
import { Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { UserSelection } from '../../../../components/Selections/Users';
import {
	ADMIN,
	ADMIN_CALENDAR,
	ADMIN_CALENDAR_CALENDAR,
	ADMIN_CALENDAR_PATIENTS,
	ADMIN_CALENDAR_SETTINGS,
	ADMIN_SERVICES,
	ADMIN_SERVICES_CREATE_PAY,
	ADMIN_SERVICES_INVOICES,
	ADMIN_SERVICES_SERVICES,
	ADMIN_SETTINGS,
	ADMIN_SETTINGS_ACCESS,
	ADMIN_SETTINGS_BRANCHES,
	ADMIN_SETTINGS_POSITIONS,
	ADMIN_SETTINGS_PROFILE,
	ADMIN_STATISTICS,
	ADMIN_STATISTICS_CALCULATOR,
	ADMIN_STATISTICS_COMMON,
	ADMIN_STATISTICS_DETAIL,
	ADMIN_USERS,
	ADMIN_USERS_NOTIFICATIONS,
	ADMIN_USERS_PATIENTS,
	ADMIN_USERS_REFERRALS,
	CABINET,
	CABINET_CALCULATOR,
	CABINET_CALCULATOR_RESULT,
	CABINET_DEPOSITE,
	CABINET_NOTIFICATIONS,
	CABINET_SERVICES,
	CABINET_SETTINGS,
	CABINET_SETTINGS_ABOUT,
	CABINET_SETTINGS_CHANGE_PASSORD,
	CABINET_SETTINGS_LICENSE,
	CABINET_SETTINGS_PROFILE,
	CABINET_STATISTICS,
	CABINET_WITHDRAW,
	CABINET_WITHDRAW_FINAL,
	OPERATION_FAILED,
	OPERTAION_SUCCESS,
} from '../../../../pathNames';
import { Role } from '../../../../routes/routes';
import { adminDepartmentsStore, adminUsersStore } from '../../../../stores';
import styles from './styles.module.scss';

const SettingAccess = observer(() => {
	const [allCheked, setAllCheked] = React.useState(false);
	const { t } = useTranslation();
	const departmentsData = adminDepartmentsStore.departments.map(
		(item: any) => ({
			label: item.name,
			value: item.id,
		})
	);

	React.useEffect(() => {
		adminDepartmentsStore.getDepartments();
		return () => {
			adminUsersStore.setSelectedAdmin(null);
		};
	}, []);

	const onSubmitHandler = (values: any) => {
		values.permissions.push(ADMIN);
		const permissions = Array.from(new Set([...values.permissions]));

		try {
			adminUsersStore.editUserPermissions({ ...values, permissions });
		} catch (e) {
			console.log(e);
		}
	};

	const treeData = [
		{
			title: t('Админка'),
			value: ADMIN,
			key: ADMIN,
			disableCheckbox: true,
		},
		{
			title: t('Личный кабинет'),
			value: CABINET,
			key: CABINET,
			children: [
				{
					title: t('Калькулятор'),
					value: CABINET_CALCULATOR,
					key: CABINET_CALCULATOR,
				},
				{
					title: t('Калькулятор(финал)'),
					value: CABINET_CALCULATOR_RESULT,
					key: CABINET_CALCULATOR_RESULT,
				},
				{
					title: t('Пополнение'),
					value: CABINET_DEPOSITE,
					key: CABINET_DEPOSITE,
				},
				{
					title: t('Уведомления'),
					value: CABINET_NOTIFICATIONS,
					key: CABINET_NOTIFICATIONS,
				},
				{ title: t('Услуги'), value: CABINET_SERVICES, key: CABINET_SERVICES },
				{
					title: t('Настройки'),
					value: CABINET_SETTINGS,
					key: CABINET_SETTINGS,
				},
				{
					title: t('Профиль'),
					value: CABINET_SETTINGS_PROFILE,
					key: CABINET_SETTINGS_PROFILE,
				},
				{
					title: t('Сменить пароль'),
					value: CABINET_SETTINGS_CHANGE_PASSORD,
					key: CABINET_SETTINGS_CHANGE_PASSORD,
				},
				{
					title: t('Лицензионные условия'),
					value: CABINET_SETTINGS_LICENSE,
					key: CABINET_SETTINGS_LICENSE,
				},
				{
					title: t('О нас'),
					value: CABINET_SETTINGS_ABOUT,
					key: CABINET_SETTINGS_ABOUT,
				},
				{
					title: t('Статистика'),
					value: CABINET_STATISTICS,
					key: CABINET_STATISTICS,
				},
				{ title: t('Вывод'), value: CABINET_WITHDRAW, key: CABINET_WITHDRAW },
				{
					title: t('Вывод(финал)'),
					value: CABINET_WITHDRAW_FINAL,
					key: CABINET_WITHDRAW_FINAL,
				},
				{
					title: t('Операция выполнена успешно'),
					value: OPERTAION_SUCCESS,
					key: OPERTAION_SUCCESS,
				},
				{
					title: t('Операция выполнена не успешно'),
					value: OPERATION_FAILED,
					key: OPERATION_FAILED,
				},
			],
		},
		{
			title: t('Календарь(реестратура)'),
			value: ADMIN_CALENDAR,
			key: ADMIN_CALENDAR,
			children: [
				{
					title: t('Календарь'),
					value: ADMIN_CALENDAR_CALENDAR,
					key: ADMIN_CALENDAR_CALENDAR,
				},
				{
					title: t('Пациенты'),
					value: ADMIN_CALENDAR_PATIENTS,
					key: ADMIN_CALENDAR_PATIENTS,
				},
				{
					title: t('Настройки календаря'),
					value: ADMIN_CALENDAR_SETTINGS,
					key: ADMIN_CALENDAR_SETTINGS,
				},
			],
		},
		{
			title: t('Услуги'),
			value: ADMIN_SERVICES,
			key: ADMIN_SERVICES,
			children: [
				{
					title: t('Основные услуги'),
					value: ADMIN_SERVICES_SERVICES,
					key: ADMIN_SERVICES_SERVICES,
				},
				{
					title: t('Основные инвойсы'),
					value: ADMIN_SERVICES_INVOICES,
					key: ADMIN_SERVICES_INVOICES,
				},
				{
					title: t('Создание оплаты'),
					value: ADMIN_SERVICES_CREATE_PAY,
					key: ADMIN_SERVICES_CREATE_PAY,
				},
			],
		},
		{
			title: t('Пользователи'),
			value: ADMIN_USERS,
			key: ADMIN_USERS,
			children: [
				{
					title: t('Пациенты'),
					value: ADMIN_USERS_PATIENTS,
					key: ADMIN_USERS_PATIENTS,
				},
				{
					title: t('Рефералы'),
					value: ADMIN_USERS_REFERRALS,
					key: ADMIN_USERS_REFERRALS,
				},
				{
					title: t('Управление уведомлениями'),
					value: ADMIN_USERS_NOTIFICATIONS,
					key: ADMIN_USERS_NOTIFICATIONS,
				},
			],
		},
		{
			title: t('Статистика'),
			value: ADMIN_STATISTICS,
			key: ADMIN_STATISTICS,
			children: [
				{
					title: t('Общая статистика'),
					value: ADMIN_STATISTICS_COMMON,
					key: ADMIN_STATISTICS_COMMON,
				},
				{
					title: t('Детальная статистика'),
					value: ADMIN_STATISTICS_DETAIL,
					key: ADMIN_STATISTICS_DETAIL,
				},
				{
					title: t('Калькулятор дохода'),
					value: ADMIN_STATISTICS_CALCULATOR,
					key: ADMIN_STATISTICS_CALCULATOR,
				},
			],
		},
		{
			title: t('Настройки'),
			value: ADMIN_SETTINGS,
			key: ADMIN_SETTINGS,
			children: [
				{
					title: t('Настройки доступа'),
					value: ADMIN_SETTINGS_ACCESS,
					key: ADMIN_SETTINGS_ACCESS,
				},
				{
					title: t('Настройки профиля'),
					value: ADMIN_SETTINGS_PROFILE,
					key: ADMIN_SETTINGS_PROFILE,
				},
				{
					title: t('Настройки филии'),
					value: ADMIN_SETTINGS_BRANCHES,
					key: ADMIN_SETTINGS_BRANCHES,
				},
				{
					title: t('Список должностей'),
					value: ADMIN_SETTINGS_POSITIONS,
					key: ADMIN_SETTINGS_POSITIONS,
				},
			],
		},
	];

	return (
		<AdminLayout>
			<div className={styles.container}>
				<Formik
					onSubmit={onSubmitHandler}
					enableReinitialize={true}
					initialValues={adminUsersStore.selectedAdmin}
				>
					{({ handleSubmit, setFieldValue, values, resetForm }) => (
						<Observer>
							{() => (
								<form onSubmit={handleSubmit}>
									<Row align="middle" justify="space-between">
										<Col span={11}>
											<p className={styles.label}>
												{t('Выберите пользователя')}
											</p>
											<UserSelection
												value={values.user_id}
												onChange={(value: any) =>
													setFieldValue('user_id', value)
												}
												placeholder={t('Пользователь')}
												showSearch={true}
											/>
											<br />
											<br />
											<p className={styles.label}>{t('Выберите доступ')}</p>
											<Checkbox
												onChange={e => {
													let arr: any = [];
													if (e.target.checked) {
														treeData.forEach((parent: any) => {
															arr.push(parent.value);
															if (parent.children?.length) {
																parent.children.forEach((child: any) =>
																	arr.push(child.value)
																);
															}
														});
													} else {
														arr = [];
													}
													setAllCheked(!allCheked);

													setFieldValue('permissions', arr);
												}}
												checked={allCheked}
											>
												{t('Выбрать все')}
											</Checkbox>
											<TreeSelect
												multiple
												showSearch
												onSelect={(selectedKey: string, info: any) => {
													if (info.children?.length) {
														const result = info.children.map(
															(item: any) => item.value
														);
														setFieldValue('permissions', [
															...values.permissions,
															...result,
															selectedKey,
														]);
													} else {
														setFieldValue('permissions', selectedKey);
													}
												}}
												treeNodeFilterProp="title"
												treeCheckable={true}
												showCheckedStrategy={'SHOW_ALL'}
												treeData={treeData}
												value={values.permissions}
												style={{ width: '100%' }}
												onChange={(value: any) => {
													setFieldValue('permissions', value);
												}}
											/>
										</Col>
										<Col span={11}>
											<p className={styles.label}>{t('Выберите должность')}</p>
											<Radio.Group
												value={values.role}
												onChange={(e: any) =>
													setFieldValue('role', e.target.value)
												}
											>
												<Radio value={Role.Admin}>Администратор</Radio>
												<Radio value={Role.Moderator}>Модератор</Radio>
												<Radio value={Role.Department}>Регистраторы</Radio>
											</Radio.Group>
											<br />
											<br />
											<p className={styles.label}>{t('Выберите филиал')}</p>

											<Checkbox.Group
												value={values.departments}
												options={departmentsData}
												onChange={value => setFieldValue('departments', value)}
											/>
										</Col>
									</Row>
									<br />
									<br />
									<br />
									<Row justify="space-between">
										<Col>
											<Button
												htmlType="reset"
												onClick={() => {
													adminUsersStore.setSelectedAdmin(null);
													resetForm();
												}}
												className={styles.clearButton}
											>
												{t('Очистить')}
											</Button>
											<Button
												htmlType="submit"
												className={styles.saveButton}
												type="primary"
											>
												{t('Сохранить')}
											</Button>
										</Col>
									</Row>
								</form>
							)}
						</Observer>
					)}
				</Formik>
			</div>
		</AdminLayout>
	);
});

export default SettingAccess;
