import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import InputFieldTitle from '../InputFieldTitle';
import styles from './selectField.module.scss';

type SelectFieldProps = {
	title?: string;
	wrapperStyle?: React.CSSProperties;
	wrapperClassName?: string;
	showValue?: boolean;
	options: Array<{ value: string | number; name: string; title?: string }>;
	errorMessage?: string | boolean;
	required?: boolean;
} & SelectProps<any>;

const SelectField = ({
	title,
	required,
	wrapperClassName,
	errorMessage,
	options,
	wrapperStyle,
	...props
}: SelectFieldProps) => (
	<InputFieldTitle
		required={required}
		title={title || ''}
		errorMessage={errorMessage}
		style={wrapperStyle}
		className={wrapperClassName}
	>
		<Select {...props} className={styles.select}>
			{options.map(option => (
				<Select.Option
					key={option.value}
					value={option.value}
					title={option.title}
				>
					{props.showValue ? `${option.value} ${option.name}` : option.name}
				</Select.Option>
			))}
		</Select>
	</InputFieldTitle>
);

export default SelectField;
