import { Button, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GenerationList from '../../Cabinet/GenerationList';
import { InviteReferals } from '../../Cabinet/InviteReferal';
import ReferalInfo from '../../Cabinet/ReferalInfo';
import ReferalsList from '../../Cabinet/ReferalsList';
import styles from './styles.module.scss';

enum ComponentType {
	inviteReferal,
	generationList,
	referalsList,
	referalInfo,
}

const Referals = () => {
	const [componentType, setComponentType] = useState(
		ComponentType.generationList
	);
	const { t } = useTranslation();
	const btn = (
		<Button
			onClick={() => setComponentType(ComponentType.inviteReferal)}
			className={styles.referalLinkBtn}
			type="primary"
		>
			{t('Пригласить реферала')}
		</Button>
	);
	const components = {
		[ComponentType.generationList]: (
			<>
				<GenerationList
					nextPage={() => setComponentType(ComponentType.referalsList)}
				/>
				<br />
				<Row justify="center"> {btn}</Row>
			</>
		),

		[ComponentType.referalsList]: (
			<>
				<ReferalsList
					nextPage={() => setComponentType(ComponentType.referalInfo)}
					onBack={() => setComponentType(ComponentType.generationList)}
				/>
				<br />
				<Row justify="center">{btn}</Row>
			</>
		),
		[ComponentType.referalInfo]: (
			<ReferalInfo
				toFirstPage={() => setComponentType(ComponentType.generationList)}
				onBack={() => setComponentType(ComponentType.referalsList)}
			>
				<br />
				{btn}
			</ReferalInfo>
		),
		[ComponentType.inviteReferal]: (
			<InviteReferals
				toFirstPage={() => setComponentType(ComponentType.generationList)}
				onBack={() => setComponentType(ComponentType.generationList)}
			/>
		),
	};
	return components[componentType] || ComponentType.generationList;
};

export default Referals;
