import React from 'react';
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { TransactionsModal } from '../../components/Modals/Transactions';
import openSuccessModal from '../../components/showModalFunc';
import authAxiosClient from '../../utils/authAxiosClient';

export class AdminTransactionsStore {
	filters: any = {};
	userTransactions: any = {};
	replenishment: any = {};
	loading = false;
	totalFills = 0;
	totalTransactions = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
	setFilters(filters: any) {
		this.filters = { ...this.filters, ...filters };
	}

	clearFilters() {
		this.filters = {};
	}

	openModalDetail(values: any) {
		const { destroy } = openSuccessModal({
			width: '70%',
			keyboard: false,
			textContent: (
				<TransactionsModal user={values} onCancel={() => destroy()} />
			),
		});
	}

	async getUserTransactions({
		id,
		page = 1,
		pageSize = 10,
		sortField = '',
	}: any) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(`/admin/transactions`, {
				params: {
					user_id: id,
					'per-page': pageSize,
					page,
					sort: sortField,
				},
			});
			this.userTransactions = data;
		} finally {
			this.setLoading(false);
		}
	}

	async getReplenishment(page = 1, pageSize = 10, sortField = '') {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/admin/transactions/number-and-amount-replenishment`,
				{
					params: {
						...this.filters,
						page,
						'per-page': pageSize,
						sort: sortField,
						start: this.filters.start ?? dayjs().format('YYYY-MM-DD'),
					},
				}
			);
			this.calculateTotal(data.data);
			this.replenishment = data;
		} finally {
			this.setLoading(false);
		}
	}

	calculateTotal(data: any = this.replenishment) {
		const fill: number[] = [];
		const transactions: number[] = [];
		if (data.length) {
			data.forEach((item: any) => {
				fill.push(parseFloat(item.totalFill));
				transactions.push(parseFloat(item.totalTransactions));
			});
			this.totalFills = fill.reduce((a, b) => a + b);
			this.totalTransactions = transactions.reduce((a, b) => a + b);
		}
	}
}
