import { makeAutoObservable } from 'mobx';
import React from 'react';

import { User } from '../../types/User';
import authAxiosClient from '../../utils/authAxiosClient';

export class AdminReferralsStore {
	userReferrals = {
		user: {} as User,
		referrals: [] as any,
	};
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async getUserReferrals(user: any) {
		try {
			this.setLoading(true);
			if (user.id) {
				const { data } = await authAxiosClient.get(
					`/admin/users/referral/${user.id}?per-page=0`
				);
				const result: any = [];
				for (const key in data.data) {
					result.push({ level: key, ...data.data[key][0] });
				}
				this.userReferrals = { user, referrals: result };
			} else {
				this.userReferrals = { user: {} as User, referrals: [] };
			}
		} finally {
			this.setLoading(false);
		}
	}

	async getUserReferralByLevel(level: any) {
		try {
			this.setLoading(true);
			const { data } = await authAxiosClient.get(
				`/admin/users/referral-level?user_id=${this.userReferrals.user.id}&level=${level}&per-page=0`
			);
			this.userReferrals = { ...this.userReferrals, referrals: data.data };
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}
}
