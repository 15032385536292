import React from 'react';
import { Row } from 'antd';
import { observer } from 'mobx-react-lite';

import SignUpForm from './components/SignUpForm';
import SignUpWelcome from './components/SignUpWelcome';
import styles from './signUp.module.scss';

const SignUpPage = observer(() => {

	return (
		<Row className={styles.flexColumnReverse}>
			<SignUpWelcome />
			<SignUpForm />
		</Row>
	);
});

export default SignUpPage;
