import ru from 'antd/lib/locale/ru_RU';
import uk from 'antd/lib/locale/uk_UA';
import dayjs from 'dayjs';
import localeRu from 'dayjs/locale/ru';
import localeUk from 'dayjs/locale/uk';
import { makeAutoObservable } from 'mobx';

import i18n from '../i18n';
import Storage, { StorageType } from './Storage';

const STORAGE_TYPE = StorageType.LocalStorage;

export class Language {
	currentLang = 'uk';
	locales: any = { ru, uk };
	currentLocale = undefined;

	dayjsLocales: any = { uk: localeUk, ru: localeRu }; // не удалять, магия! локализация для календарей

	constructor() {
		makeAutoObservable(this);
		const langIsExist = Storage(STORAGE_TYPE).get('lang');
		if (!langIsExist) {
			this.currentLang = 'uk';
			Storage(STORAGE_TYPE).put('lang', this.currentLang);
		}

		this.currentLocale = this.locales[this.currentLang];
		i18n.changeLanguage(this.currentLang);
		dayjs.locale(this.currentLang);
	}

	async changeLang(value: string) {
		this.currentLang = value;
		this.currentLocale = this.locales[this.currentLang];
		Storage(STORAGE_TYPE).put('lang', value);
		await i18n.changeLanguage(value);
		dayjs.locale(value);
	}
}
