import { Button, Col, Input, Row } from 'antd';
import { ErrorMessage, Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { SignUpSmsCodePopup } from '../../../components/Modals/EnterSignUpMobileCode';
import showModalFunc from '../../../components/showModalFunc';
import {
	CABINET_WITHDRAW,
	OPERATION_FAILED,
	OPERTAION_SUCCESS,
} from '../../../pathNames';
import { transactionsStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetWithdrawFinal = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const requiredText = t('Обязательное поле');

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(requiredText),
		inn: Yup.string().max(10).required(requiredText),
	});

	React.useEffect(() => {
		if (Object.keys(transactionsStore.withDrawData).length === 0) {
			navigate(CABINET_WITHDRAW);
		}
	}, []);

	return (
		<CabinetLayout>
			<PageTitle title={t('Вывод Кеш бонусов')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col xs={18} md={8}>
						<div className={styles.titleBox}>
							<h3 className={styles.title}>{t('Вывод Кэш бонусов')}</h3>
							<p>
								{t(
									'Во время вывода средств будет удержан налог на прибыль - 19,5% (НДФЛ 18% + военный сбор 1,5%)'
								)}
							</p>
						</div>
					</Col>
				</Row>
				<Row justify="center">
					<Formik
						validationSchema={validationSchema}
						validateOnMount={true}
						initialValues={{
							name: undefined,
							inn: undefined,
							sms: undefined,
						}}
						onSubmit={(values: any, formikHelpers) => {
							transactionsStore.setWithDrawData(values);
							transactionsStore.beforeOutCash(transactionsStore.withDrawData);
							const { destroy } = showModalFunc({
								title: t('Введите 4-х значный код из смс'),
								width: '50%',
								keyboard: false,
								textContent: (
									<SignUpSmsCodePopup
										onSubmit={async ({ code }: any) => {
											formikHelpers.setFieldValue('confirmed_token', code);
											const response = await transactionsStore.outCash({
												...transactionsStore.withDrawData,
												confirmed_token: code,
											});
											if (response?.status === 200) {
												navigate(OPERTAION_SUCCESS);
											} else {
												navigate(OPERATION_FAILED);
											}
											destroy();
										}}
									/>
								),
							});
						}}
					>
						{({
							handleSubmit,
							values,
							isValid,
							setFieldValue,
							errors,
							touched,
							submitForm,
						}) => (
							<Observer>
								{() => (
									<>
										<Col xs={24} md={12}>
											<form onSubmit={handleSubmit}>
												<div className={styles.formItem}>
													<p className={styles.grayText}>
														{t('Фамилия')} {t('Имя')} {t('Отчество')}
													</p>
													<Input
														value={values.name}
														type="text"
														className={styles.input}
														placeholder={`${t('Фамилия')} ${t('Имя')} ${t(
															'Отчество'
														)}`}
														onChange={e =>
															setFieldValue('name', e.target.value)
														}
													/>
													{errors.name && touched.name && (
														<span>{errors.name as string}</span>
													)}
												</div>
												<div className={styles.formItem}>
													<p className={styles.grayText}>
														{t('Идентификационный номер налогоплательщика')}
													</p>
													<Input
														value={values.inn}
														type="number"
														className={styles.input}
														placeholder={t(
															'Идентификационный номер налогоплательщика'
														)}
														onChange={e => setFieldValue('inn', e.target.value)}
													/>
													<ErrorMessage name="inn" />
												</div>
											</form>
										</Col>
										<Col xs={24} md={16}>
											<br />
											<br />
											<Row justify="center" gutter={[12, 12]}>
												<Col md={12} xs={24}>
													<Button
														onClick={() => navigate(CABINET_WITHDRAW)}
														block
														size="large"
													>
														{t('Отменить')}
													</Button>
												</Col>
												<Col md={12} xs={24}>
													<Button
														disabled={!isValid}
														onClick={submitForm}
														block
														size="large"
														type="primary"
													>
														{t('Подтвердить')}
													</Button>
												</Col>
											</Row>
										</Col>
									</>
								)}
							</Observer>
						)}
					</Formik>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetWithdrawFinal;
