import React from 'react';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { adminOrdersStore } from '../../../stores';
import { AdminTable } from '../../Admin/Table';
import AdminUserInfo from '../../Admin/UserInfo/AdminUserInfo';
import styles from './styles.module.scss';
import { EditDeleteIcons } from '../../EditDeleteIcons';

export const OrdersModal = observer(({ onCancel, user, title = '' }: any) => {
	const { t } = useTranslation();
	const { data, _meta } = adminOrdersStore.modalOrders;
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminOrdersStore.getModalOrders(page, pageSize, sortField);
	};
	React.useEffect(() => {
		adminOrdersStore.setModalFilters({ id: user.id });
		adminOrdersStore.getModalOrders();
	}, []);

	const columns = [
		{
			title: 'ID услуги',
			dataIndex: 'id',
		},
		{
			title: t('Услуга'),
			dataIndex: 'product',
			render: (product: any) => <>{product?.name}</>,
		},
		{
			title: 'Дата',
			dataIndex: 'created_at',
			render: (date: any) => <>{new Date(date * 1000).toLocaleString()}</>,
		},
		{
			title: t('Цена'),
			dataIndex: 'price',
			sort: true,
		},
		{
			title: t('Статус'),
			dataIndex: ['transactions'],
			render: (item: any) => {
				const stls = [
					styles.grey,
					styles.warning,
					styles.success,
					styles.danger,
				];
				const status = item?.status || 0;
				const statusName = item?.statusName || 'Невідомо';
				return <p className={stls[status]}>{statusName}</p>;
			},
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					disabled={item.transactions?.status === 2}
					onEdit={() => adminOrdersStore.openModalEdit(item)}
				/>
			),
		},
	];

	return (
		<>
			<AdminUserInfo user={user} loading={adminOrdersStore.loading} />
			<h3 className={styles.title}>{title}</h3>
			<div className={styles.container}>
				<AdminTable
					pagination={{
						..._meta,
						onChange: paginationHandler,
					}}
					columns={columns}
					data={data}
				/>
			</div>
			<Row justify="center" gutter={8}>
				<Col>
					<Button
						className={styles.closeButton}
						type="primary"
						onClick={onCancel}
					>
						{t('Закрыть')}
					</Button>
				</Col>
			</Row>
		</>
	);
});
