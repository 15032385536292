import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SIGN_IN } from '../../../../pathNames';
import styles from '../signUp.module.scss';

const SignUpWelcome = () => {
	const { t } = useTranslation();

	return (
		<Col
			style={{ backgroundImage: 'url(/images/bg-auth.webp)' }}
			xs={0}
			md={10}
		>
			<Row justify="center" align="middle" className={styles.signIn}>
				<div>
					<h2 className={styles.title}>{t('Снова вернулись')}!</h2>
					<p>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem
						accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
						quae ab illo inventore veritatis et quasi architecto beatae vitae
						dicta sunt explicabo.
					</p>
					<Link to={SIGN_IN}>
						<Button className={styles.formControl} ghost>
							{t('Вход')}
						</Button>
					</Link>
				</div>
			</Row>
		</Col>
	);
};

export default SignUpWelcome;
