import { Button, Col, Input, Row } from 'antd';
import { Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { CABINET, CABINET_WITHDRAW_FINAL } from '../../../pathNames';
import { transactionsStore, userStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetWithdraw = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const validationSchema = Yup.object().shape({
		amount: Yup.number()
			.max(userStore.userInfo.cash_bon, t<string>('У вас нет столько бонусов'))
			.required(t<string>('Обязательное поле')),
		card: Yup.string()
			.length(16, t<string>('Номер карты должен состоять из 16 цифр'))
			.required(t<string>('Обязательное поле')),
	});

	return (
		<CabinetLayout>
			<PageTitle title={t('Вывод Кеш бонусов')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col>
						<div className={styles.titleBox}>
							<h3 className={styles.title}>{t('Вывод Кеш бонусов')}</h3>
							<p>
								{t(
									'Во время вывода средств будет удержан налог на прибыль - 19,5% (НДФЛ 18% + военный сбор 1,5%)'
								)}
							</p>
						</div>
					</Col>
				</Row>
				<Row justify="center">
					<Formik
						validationSchema={validationSchema}
						validateOnMount={true}
						initialValues={{
							amount: '',
							card: '',
						}}
						onSubmit={(values: any) => {
							transactionsStore.setWithDrawData(values);
							navigate(CABINET_WITHDRAW_FINAL);
						}}
					>
						{({
							handleSubmit,
							values,
							handleBlur,
							isValid,
							setFieldValue,
							errors,
							touched,
							submitForm,
						}) => (
							<Observer>
								{() => (
									<>
										<Col xs={24} md={12}>
											<form onSubmit={handleSubmit}>
												<div className={styles.formItem}>
													<p className={styles.grayText}>{t('Сумма вывода')}</p>
													<Input
														value={values.amount}
														type="number"
														name="amount"
														onBlur={handleBlur}
														className={styles.input}
														placeholder={t('Сумма вывода')}
														onChange={e =>
															setFieldValue('amount', e.target.value)
														}
													/>
													{errors.amount && touched.amount ? (
														<span className={styles.danger}>
															{errors.amount}
														</span>
													) : (
														Boolean(Number(values.amount)) && (
															<span className={styles.danger}>
																{t('Комисия банка составит', {
																	amount: (Number(values.amount) * 0.805) / 100,
																})}
															</span>
														)
													)}
												</div>
												<div className={styles.formItem}>
													<p className={styles.grayText}>{t('Номер карты')}</p>
													<Input
														value={values.card}
														type="number"
														name="card"
														onBlur={handleBlur}
														className={styles.input}
														placeholder={t('Номер карты')}
														onChange={e =>
															setFieldValue('card', String(e.target.value))
														}
													/>
													{errors.card && touched.card && (
														<span className={styles.danger}>{errors.card}</span>
													)}
												</div>
											</form>
										</Col>
										<Col xs={24} md={16}>
											<br />
											<br />
											<Row justify="center" gutter={[12, 12]}>
												<Col md={12} xs={24}>
													<Button
														onClick={() => navigate(CABINET)}
														block
														size="large"
													>
														{t('Отменить')}
													</Button>
												</Col>
												<Col md={12} xs={24}>
													<Button
														disabled={!isValid}
														onClick={submitForm}
														block
														size="large"
														type="primary"
													>
														{t('Подтвердить')}
													</Button>
												</Col>
											</Row>
										</Col>
									</>
								)}
							</Observer>
						)}
					</Formik>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetWithdraw;
