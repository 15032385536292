import { Row } from 'antd';
import React from 'react';

import SignInSide from './components/SignInSide';
import SignUpSide from './components/SignUpSide';

const SignIn = () => {
	return (
		<Row>
			<SignInSide />
			<SignUpSide />
		</Row>
	);
};

export default SignIn;
