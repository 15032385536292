import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminUsersStore } from '../../../../stores';
import { User } from '../../../../types/User';
import styles from './styles.module.scss';

const SettingProfile = observer(() => {
	const { t } = useTranslation();
	const { _meta, data } = adminUsersStore.users;

	React.useEffect(() => {
		adminUsersStore.getUsers();
	}, []);

	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminUsersStore.getUsers(page, pageSize, sortField);
	};

	const onSearch = (name: string) => {
		if (!name) {
			adminUsersStore.setFilters({ name: null });
		} else {
			adminUsersStore.setFilters({ name });
		}
		adminUsersStore.getUsers();
	};

	const columns = [
		{
			title: 'ID ',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('ФИО'),
			render: (user: User) => (
				<>
					{user.profiles.first_name} {user.profiles.last_name}{' '}
					{user.profiles.middle_name}
				</>
			),
		},
		{
			title: t('Дата рождения'),
			key: 'birthday',
			sorter: true,
			render: (user: User) => (
				<>{new Date(user.profiles.birthday).toLocaleDateString()}</>
			),
		},
		{
			title: t('Телефон'),
			dataIndex: 'phone',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (user: User) => (
				<EditDeleteIcons
					onEdit={() => adminUsersStore.openEditUserModal(user)}
					onDelete={() => adminUsersStore.deleteUser(user.id)}
				/>
			),
		},
	];

	return (
		<AdminLayout>
			<div className={styles.container}>
				<AdminTable
					pagination={{
						options: {
							..._meta,
							onChange: paginationHandler,
						},
					}}
					className={styles.table}
					columns={columns}
					data={data}
				>
					<TableFilters
						searchFieldConfigs={{
							loading: adminUsersStore.loading,
							placeholder: t('Введите для поиска'),
							onSearch: (value: string) => onSearch(value),
						}}
					/>
				</AdminTable>
			</div>
		</AdminLayout>
	);
});

export default SettingProfile;
