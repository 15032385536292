import { Button, Col, Row, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { CABINET_CALCULATOR } from '../../../pathNames';
import { cabinetCalculatorStore, referalsStore } from '../../../stores';
import { RadioTypes } from '../../../stores/Cabinet/Calculator';
import styles from './styles.module.scss';

const CabinetCalculatorResults = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [data, setData] = React.useState<any>([]);
	const [totalCash, setTotalCash] = React.useState<number>(0);
	const [totalRefs, setTotalRefs] = React.useState<number>(0);
	const contributedFunds = Number(cabinetCalculatorStore.contributedFunds);
	const entityFunds = Number(cabinetCalculatorStore.entityFunds);
	const { selectedType } = cabinetCalculatorStore;
	React.useEffect(() => {
		if (selectedType === RadioTypes.autoFill) {
			autoFillCalc();
		}
		if (selectedType === RadioTypes.payBack) {
			paybackCalc();
		}
		if (selectedType === RadioTypes.manual) {
			manualCalc();
		}
		return () => {
			cabinetCalculatorStore.setDefault();
		};
	}, [selectedType]);

	const paybackCalc = () => {
		const tempCopy = [...cabinetCalculatorStore.data];
		const Y = 7000;
		const X = Number(entityFunds);
		const firstGeneration = tempCopy[0];
		const secondGeneration = tempCopy[1];
		const thirdGeneration = tempCopy[2];
		const fourthGeneration = tempCopy[3];
		const fifthGeneration = tempCopy[4];
		const sixthGeneration = tempCopy[5];
		const seventhGeneration = tempCopy[6];

		firstGeneration.referrals = 10;
		if (X <= 3600) {
			firstGeneration.cash_bon = X / 10;
		}
		if (X > 3600 && X < 11000) {
			firstGeneration.cash_bon = X / 10;
			secondGeneration.cash_bon = (X / 2) * 0.07;
			secondGeneration.referrals = (X / secondGeneration.cash_bon) * 2;
		}
		if (X > 11000) {
			firstGeneration.cash_bon = Y * 0.1;
			secondGeneration.cash_bon = Y * 0.07;
			secondGeneration.referrals = (X - Y) / secondGeneration.cash_bon;
			if (secondGeneration.referrals > 100) {
				secondGeneration.referrals = 100;

				thirdGeneration.cash_bon = Y * 0.05;
				thirdGeneration.referrals =
					(X - Y - 100 * secondGeneration.cash_bon) / thirdGeneration.cash_bon;

				if (thirdGeneration.referrals > 1000) {
					thirdGeneration.referrals = 1000;

					fourthGeneration.cash_bon = Y * 0.03;
					fourthGeneration.referrals =
						(X -
							Y -
							100 * secondGeneration.cash_bon -
							1000 * thirdGeneration.cash_bon) /
						fourthGeneration.cash_bon;

					if (fourthGeneration.referrals > 10000) {
						fourthGeneration.referrals = 10000;
						fifthGeneration.cash_bon = Y * 0.02;
						fifthGeneration.referrals =
							(X -
								Y -
								100 * secondGeneration.cash_bon -
								1000 * thirdGeneration.cash_bon -
								10000 * fourthGeneration.cash_bon) /
							fifthGeneration.cash_bon;
						if (fifthGeneration.referrals > 100000) {
							fifthGeneration.referrals = 100000;
							sixthGeneration.cash_bon = Y * 0.01;
							sixthGeneration.referrals =
								(X -
									Y -
									100 * secondGeneration.cash_bon -
									1000 * thirdGeneration.cash_bon -
									10000 * fourthGeneration.cash_bon -
									100000 * fifthGeneration.cash_bon) /
								sixthGeneration.cash_bon;
							if (sixthGeneration.referrals > 1000000) {
								sixthGeneration.referrals = 1000000;
								seventhGeneration.cash_bon = Y * 0.001;
								seventhGeneration.referrals =
									(X -
										Y -
										100 * secondGeneration.cash_bon -
										1000 * thirdGeneration.cash_bon -
										10000 * fourthGeneration.cash_bon -
										100000 * fifthGeneration.cash_bon -
										sixthGeneration.cash_bon * 1000000) /
									seventhGeneration.cash_bon;
							}
						}
					}
				}
			}
		}

		const refTotal = tempCopy
			.map(item => item.referrals)
			.reduce((a, b) => a + b);
		setTotalCash(X);
		setTotalRefs(refTotal);
		setData(
			tempCopy.map(item => {
				item.referrals = Math.round(item.referrals);
				item.cash_bon = Math.round(item.cash_bon);
				return item;
			})
		);
	};
	const manualCalc = () => {
		const tempCopy = [...cabinetCalculatorStore.data];
		const cashTotal = tempCopy
			.map(item => item.cash_bon)
			.reduce((a, b) => a + b);
		const refTotal = tempCopy
			.map(item => item.referrals)
			.reduce((a, b) => a + b);
		setTotalCash(cashTotal);
		setTotalRefs(refTotal);
		setData(tempCopy);
	};
	const autoFillCalc = () => {
		const tempCopy = [...cabinetCalculatorStore.data];
		tempCopy[0].referrals = 10;
		tempCopy[1].referrals = tempCopy[0].referrals * 10;
		tempCopy.map((item, index) => {
			if (index <= 1) {
				return item;
			}
			item.referrals = tempCopy[0].referrals * tempCopy[index - 1].referrals;

			return item;
		});
		tempCopy[0].cash_bon = Math.round(
			tempCopy[0].referrals * contributedFunds * 0.1
		);
		tempCopy[1].cash_bon = Math.round(
			tempCopy[1].referrals * contributedFunds * 0.07
		);
		tempCopy[2].cash_bon = Math.round(
			tempCopy[2].referrals * contributedFunds * 0.05
		);
		tempCopy[3].cash_bon = Math.round(
			tempCopy[3].referrals * contributedFunds * 0.03
		);
		tempCopy[4].cash_bon = Math.round(
			tempCopy[4].referrals * contributedFunds * 0.02
		);
		tempCopy[5].cash_bon = Math.round(
			tempCopy[5].referrals * contributedFunds * 0.01
		);
		tempCopy[6].cash_bon = Math.round(
			tempCopy[6].referrals * contributedFunds * 0.001
		);
		const cashTotal = tempCopy
			.map(item => item.cash_bon)
			.reduce((a, b) => a + b);
		const refTotal = tempCopy
			.map(item => item.referrals)
			.reduce((a, b) => a + b);
		setTotalCash(cashTotal);
		setTotalRefs(refTotal);
		setData(tempCopy);
	};
	const columns = [
		{
			title: `% ${t('Вложенных средств')}`,
			width: '40%',
			render: (level: number) => (
				<>
					{referalsStore.getGenerationRomanNum(level)} {t('Поколение')}
				</>
			),
			dataIndex: 'key',
		},
		{
			title: () => <>{t('Рефералы')}</>,
			editable: true,
			width: '30%',
			dataIndex: 'referrals',
		},
		{
			title: () => <>{t('Кэш бонусы')}</>,
			editable: true,
			width: '30%',
			dataIndex: 'cash_bon',
		},
	];

	return (
		<CabinetLayout>
			<PageTitle title={t('Калькулятор')} />
			<div className={styles.container}>
				<Row justify="center">
					<Col>
						<p className={styles.calcTitle}>{t('Общee количество Ваших')}</p>
						<Row justify="space-between">
							<Col className={styles.textCenter}>
								<div>{totalRefs}</div>
								<p>{t('Рефералов')}</p>
							</Col>
							<Col className={styles.textCenter}>
								<div>{totalCash}</div>
								<p>{t('Кэш бонусов')}</p>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row align="middle" justify="space-between">
					<Col lg={8} md={12} xs={24}>
						<div className={styles.detailText}>
							<h3 className={styles.calcTitle}>
								{selectedType === RadioTypes.autoFill && t('Автозаполнение')}
								{selectedType === RadioTypes.payBack && t('Возврат средств')}
								{selectedType === RadioTypes.manual && t('Ручной ввод')}
							</h3>
							<span>
								{t(
									'Снять Кэш-бонусы возможно с удержанием доходного налога и военного сбора 19,5%'
								)}
							</span>
						</div>
					</Col>
					<Col md={12}>
						<Table columns={columns} pagination={false} dataSource={data} />
						<Button
							onClick={() => navigate(CABINET_CALCULATOR)}
							block
							size="large"
						>
							{t('Закрыть')}
						</Button>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
});

export default CabinetCalculatorResults;
