import { makeAutoObservable } from 'mobx';

import authAxiosClient from '../../utils/authAxiosClient';

export class ServicesStore {
	services = [];
	invoices = [];
	selectedService: any = {};
	availableTime: any = {};

	constructor() {
		makeAutoObservable(this);
	}

	async getServices() {
		const { data } = await authAxiosClient.get('/products');
		this.services = data.data;
	}

	async getInvoices() {
		const { data } = await authAxiosClient.get('/products/invoice');
		this.invoices = data.data;
	}

	async bookTime(timeId: string) {
		try {
			await authAxiosClient.put(`/products/booking/${timeId}`);
			// eslint-disable-next-line no-empty
		} finally {
		}
	}

	async getAvailableTime(values: any) {
		const { data } = await authAxiosClient.get('/products/get-time', {
			params: { ...values },
		});
		this.availableTime = data.data;
	}

	async getAvailableDay(values: any) {
		const { data } = await authAxiosClient.get(`/products/get-day-info`, {
			params: { ...values },
		});
		this.availableTime = data.data;
	}

	chooseService(service: any) {
		this.selectedService = service;
	}
}
