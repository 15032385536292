import { Button, Col, Row } from 'antd';
import { Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import InputField from '../../../components/InputField';
import { CABINET_SETTINGS_PROFILE } from '../../../pathNames';
import { userStore } from '../../../stores';
import styles from './styles.module.scss';

const CabinetChangePassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const requiredText = t('Обязательное поле');

	const validationSchema = Yup.object().shape({
		old: Yup.string().required(requiredText),
		password: Yup.string().required(requiredText),
		passwordConfirm: Yup.string()
			.required(requiredText)
			.oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
	});

	return (
		<CabinetLayout>
			<PageTitle title={t('Настройки профиля')} />
			<div className={styles.container}>
				<Row justify="center">
					<h3 className={styles.title}>{t('Смена пароля')}</h3>
				</Row>
				<Formik
					validationSchema={validationSchema}
					initialValues={{ password: '', passwordConfirm: '' }}
					onSubmit={(values: any) => {
						userStore.changePassword(values);
						navigate(CABINET_SETTINGS_PROFILE);
					}}
				>
					{({ handleSubmit, setFieldValue, handleBlur, touched, errors }) => (
						<Observer>
							{() => (
								<form onSubmit={handleSubmit}>
									<Row justify="center">
										<Col lg={12}>
											<Row gutter={[16, 16]} justify="center">
												<Col span={22}>
													<InputField
														fieldType="password"
														onBlur={handleBlur}
														name="old"
														title={t('Старый пароль')}
														placeholder={t('Пароль')}
														required={true}
														className={styles.formControl}
														errorMessage={touched.password && errors.password}
														onChange={event =>
															setFieldValue('old', event.target.value)
														}
													/>
												</Col>
												<Col span={22}>
													<InputField
														fieldType="password"
														onBlur={handleBlur}
														name="password"
														title={t('Введите новый пароль')}
														placeholder={t('Введите новый пароль')}
														required={true}
														className={styles.formControl}
														errorMessage={touched.password && errors.password}
														onChange={event =>
															setFieldValue('password', event.target.value)
														}
													/>
												</Col>
												<Col span={22}>
													<InputField
														fieldType="password"
														onBlur={handleBlur}
														name="passwordConfirm"
														title={t('Подтвердите новый пароль')}
														placeholder={t('Подтвердите новый пароль')}
														required={true}
														className={styles.formControl}
														errorMessage={
															touched.passwordConfirm && errors.passwordConfirm
														}
														onChange={event =>
															setFieldValue(
																'passwordConfirm',
																event.target.value
															)
														}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<br />
									<br />
									<br />
									<Row gutter={10} align="bottom" justify="center">
										<Col lg={6}>
											<Button
												onClick={() => navigate(CABINET_SETTINGS_PROFILE)}
												block
												size="large"
											>
												{t('Отменить')}
											</Button>
										</Col>
										<Col lg={6}>
											<Button
												htmlType="submit"
												block
												size="large"
												type="primary"
											>
												{t('Сохранить')}
											</Button>
										</Col>
									</Row>
								</form>
							)}
						</Observer>
					)}
				</Formik>
			</div>
		</CabinetLayout>
	);
};

export default CabinetChangePassword;
