import { Modal, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { referalsStore } from '../../../stores';
import { EmptyData } from '../../EmptyData';
import { UserInfo } from '../UserInfo';
import styles from './styles.module.scss';

interface Props {
	nextPage: () => void;
}

const GenerationList = ({ nextPage }: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const columns = [
		{
			dataIndex: 'key',
			render: (key: number) => (
				<span className={styles.tableItemNoColor}>
					{referalsStore.getGenerationRomanNum(key)} {t('Поколение')}
				</span>
			),
		},
		{
			title: t('Рефералы'),
			render: (count: number | null) => (
				<span className={styles.tableItem}>{count || 0}</span>
			),
			dataIndex: 'count',
		},
		{
			title: t('Кэш бонусы'),
			render: (cash_bon: number | null) => (
				<span className={styles.tableItem}>{cash_bon || 0}</span>
			),
			dataIndex: 'cash_bon',
		},
	];

	React.useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setLoading(true);
		await referalsStore.getGenerationsList();
		setLoading(false);
	};

	return (
		<>
			<UserInfo detailInfo={true} />
			<Table
				loading={loading}
				columns={columns}
				locale={{
					emptyText: <EmptyData />,
				}}
				className={styles.table}
				dataSource={referalsStore.generations}
				onRow={record => ({
					onClick: () => {
						const { key } = record;
						if (Number(record.count)) {
							referalsStore.selectGenerationLevel(key);
							nextPage();
						} else {
							const modal = Modal.warning({
								title: t('В этом поколении нет рефералов', {
									generation: referalsStore.getGenerationRomanNum(key),
								}),
							});
							setTimeout(() => {
								modal.destroy();
							}, 4000);
						}
					},
				})}
				pagination={false}
			/>
		</>
	);
};

export default observer(GenerationList);
