import { Layout, Menu, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import {
	ADMIN,
	ADMIN_CALENDAR,
	ADMIN_CALENDAR_CALENDAR,
	ADMIN_CALENDAR_PATIENTS,
	ADMIN_CALENDAR_SETTINGS,
	ADMIN_SERVICES,
	ADMIN_SERVICES_CREATE_PAY,
	ADMIN_SERVICES_INVOICES,
	ADMIN_SERVICES_SERVICES,
	ADMIN_SETTINGS,
	ADMIN_SETTINGS_ACCESS,
	ADMIN_SETTINGS_BRANCHES,
	ADMIN_SETTINGS_POSITIONS,
	ADMIN_SETTINGS_PROFILE,
	ADMIN_STATISTICS,
	ADMIN_STATISTICS_CALCULATOR,
	ADMIN_STATISTICS_COMMON,
	ADMIN_STATISTICS_DETAIL,
	ADMIN_USERS,
	ADMIN_USERS_NOTIFICATIONS,
	ADMIN_USERS_PATIENTS,
	ADMIN_USERS_REFERRALS,
	CABINET,
	SIGN_IN,
} from '../../../pathNames';
import { Role } from '../../../routes/routes';
import { adminMenuStore, authStore } from '../../../stores';
import LanguageSelection from '../../Selections/Languages';
import styles from './styles.module.scss';

const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

export const AdminLayout = observer(
	({ children }: { children: React.ReactNode }) => {
		const location = useLocation();
		const navigate = useNavigate();
		const { t } = useTranslation();
		const { permissions, role } = authStore.token;

		const checkMainLink = (path: string, item: MenuItem) => {
			if (location.pathname.includes(path)) return item;
			return null;
		};

		const checkForDisabled = (path: string) => {
			if (role === Role.Root) return false;
			return !permissions.includes(path);
		};

		const headerLinks: MenuItem[] = [
			{
				label: (
					<NavLink to={ADMIN_CALENDAR}>{t('Календарь(реестратура)')}</NavLink>
				),
				key: ADMIN_CALENDAR,
				disabled: checkForDisabled(ADMIN_CALENDAR),
			},
			{
				label: <NavLink to={ADMIN_SERVICES}>{t('Услуги')}</NavLink>,
				key: ADMIN_SERVICES,
				disabled: checkForDisabled(ADMIN_SERVICES),
			},
			{
				label: <NavLink to={ADMIN_USERS}>{t('Пользователи')}</NavLink>,
				key: ADMIN_USERS,
				disabled: checkForDisabled(ADMIN_USERS),
			},
			{
				label: <NavLink to={ADMIN_STATISTICS}>{t('Статистика')}</NavLink>,
				key: ADMIN_STATISTICS,
				disabled: checkForDisabled(ADMIN_STATISTICS),
			},
			{
				label: <NavLink to={ADMIN_SETTINGS}>{t('Настройки')}</NavLink>,
				key: ADMIN_SETTINGS,
				disabled: checkForDisabled(ADMIN_SETTINGS),
			},
			{
				label: <NavLink to={CABINET}>{t('Личный кабинет')}</NavLink>,
				key: CABINET,
				disabled: checkForDisabled(CABINET),
			},
			{
				label: t('Выход'),
				key: 'signOut',
				onClick: () => {
					authStore.signOut();
					navigate(SIGN_IN);
				},
			},
			{
				label: <LanguageSelection />,
				key: 'lang',
			},
		];

		const sidebarLinks: MenuItem[] = [
			checkMainLink(ADMIN_CALENDAR, {
				label: <NavLink to={ADMIN_CALENDAR_CALENDAR}>{t('Календарь')}</NavLink>,
				key: ADMIN_CALENDAR_CALENDAR,
				disabled: checkForDisabled(ADMIN_CALENDAR_CALENDAR),
			}),

			checkMainLink(ADMIN_CALENDAR, {
				label: <NavLink to={ADMIN_CALENDAR_PATIENTS}>{t('Пациенты')}</NavLink>,
				key: ADMIN_CALENDAR_PATIENTS,
				disabled: checkForDisabled(ADMIN_CALENDAR_PATIENTS),
			}),

			checkMainLink(ADMIN_CALENDAR, {
				label: (
					<NavLink to={ADMIN_CALENDAR_SETTINGS}>
						{t('Настройки календаря')}
					</NavLink>
				),
				key: ADMIN_CALENDAR_SETTINGS,
				disabled: checkForDisabled(ADMIN_CALENDAR_SETTINGS),
			}),

			checkMainLink(ADMIN_SERVICES, {
				label: (
					<NavLink to={ADMIN_SERVICES_SERVICES}>{t('Основные услуги')}</NavLink>
				),
				key: ADMIN_SERVICES_SERVICES,
				disabled: checkForDisabled(ADMIN_SERVICES_SERVICES),
			}),
			checkMainLink(ADMIN_SERVICES, {
				label: (
					<NavLink to={ADMIN_SERVICES_INVOICES}>
						{t('Основные инвойсы')}
					</NavLink>
				),
				key: ADMIN_SERVICES_INVOICES,
				disabled: checkForDisabled(ADMIN_SERVICES_INVOICES),
			}),
			checkMainLink(ADMIN_SERVICES, {
				label: (
					<NavLink to={ADMIN_SERVICES_CREATE_PAY}>
						{t('Создание оплаты')}
					</NavLink>
				),
				key: ADMIN_SERVICES_CREATE_PAY,
				disabled: checkForDisabled(ADMIN_SERVICES_CREATE_PAY),
			}),

			checkMainLink(ADMIN_USERS, {
				label: <NavLink to={ADMIN_USERS_PATIENTS}>{t('Пациенты')}</NavLink>,
				key: ADMIN_USERS_PATIENTS,
				disabled: checkForDisabled(ADMIN_USERS_PATIENTS),
			}),
			checkMainLink(ADMIN_USERS, {
				label: <NavLink to={ADMIN_USERS_REFERRALS}>{t('Реферали')}</NavLink>,
				key: ADMIN_USERS_REFERRALS,
				disabled: checkForDisabled(ADMIN_USERS_REFERRALS),
			}),
			checkMainLink(ADMIN_USERS, {
				label: (
					<NavLink to={ADMIN_USERS_NOTIFICATIONS}>
						{t('Управление уведомлениями')}
					</NavLink>
				),
				key: ADMIN_USERS_NOTIFICATIONS,
				disabled: checkForDisabled(ADMIN_USERS_NOTIFICATIONS),
			}),

			checkMainLink(ADMIN_STATISTICS, {
				label: (
					<NavLink to={ADMIN_STATISTICS_COMMON}>
						{t('Общая статистика')}
					</NavLink>
				),
				key: ADMIN_STATISTICS_COMMON,
				disabled: checkForDisabled(ADMIN_STATISTICS_COMMON),
			}),
			checkMainLink(ADMIN_STATISTICS, {
				label: (
					<NavLink to={ADMIN_STATISTICS_DETAIL}>
						{t('Детальная статистика')}
					</NavLink>
				),
				key: ADMIN_STATISTICS_DETAIL,
				disabled: checkForDisabled(ADMIN_STATISTICS_DETAIL),
			}),
			checkMainLink(ADMIN_STATISTICS, {
				label: (
					<NavLink to={ADMIN_STATISTICS_CALCULATOR}>
						{t('Калькулятор прибыли рефералови')}
					</NavLink>
				),
				key: ADMIN_STATISTICS_CALCULATOR,
				disabled: true,
			}),

			checkMainLink(ADMIN_SETTINGS, {
				label: (
					<NavLink to={ADMIN_SETTINGS_ACCESS}>{t('Настройка доступа')}</NavLink>
				),
				key: ADMIN_SETTINGS_ACCESS,
				disabled: checkForDisabled(ADMIN_SETTINGS_ACCESS),
			}),
			checkMainLink(ADMIN_SETTINGS, {
				label: (
					<NavLink to={ADMIN_SETTINGS_PROFILE}>
						{t('Настройки профиля')}
					</NavLink>
				),
				key: ADMIN_SETTINGS_PROFILE,
				disabled: checkForDisabled(ADMIN_SETTINGS_PROFILE),
			}),
			checkMainLink(ADMIN_SETTINGS, {
				label: (
					<NavLink to={ADMIN_SETTINGS_BRANCHES}>{t('Настройки филии')}</NavLink>
				),
				key: ADMIN_SETTINGS_BRANCHES,
				disabled: checkForDisabled(ADMIN_SETTINGS_BRANCHES),
			}),
			checkMainLink(ADMIN_SETTINGS, {
				label: (
					<NavLink to={ADMIN_SETTINGS_POSITIONS}>
						{t('Список должностей')}
					</NavLink>
				),
				key: ADMIN_SETTINGS_POSITIONS,
				disabled: checkForDisabled(ADMIN_SETTINGS_POSITIONS),
			}),
		];

		React.useEffect(() => {
			if (adminMenuStore.isFirstRender) {
				adminMenuStore.setIsFirstRender();
				const splittedLink = location.pathname.split('/');
				const menu = '/' + splittedLink[1] + '/' + splittedLink[2];
				const sidebar = `/${splittedLink[1]}/${splittedLink[2]}/${splittedLink[3] || '/'
					}`;
				adminMenuStore.setMenuLink(menu);
				adminMenuStore.setSidebarLink(sidebar);
			}
		}, []);

		return (
			<Layout>
				<Header className={styles.header}>
					<div className={styles.logoWrapper}>
						<NavLink to={ADMIN}>
							<img src="/images/logo-nav.webp" alt="logo" />
						</NavLink>
					</div>
					<Menu
						onClick={(e: any) => {
							adminMenuStore.setMenuLink(e.key);
							adminMenuStore.setSidebarLink('');
						}}
						className={styles.menu}
						theme="light"
						mode="horizontal"
						items={headerLinks}
						selectedKeys={[adminMenuStore.menuActiveLink]}
					/>
				</Header>
				<Content>
					<Layout>
						<Sider width={200}>
							<Menu
								onSelect={(e: any) => adminMenuStore.setSidebarLink(e.key)}
								selectedKeys={[adminMenuStore.sidebarActiveLink]}
								style={{ height: '100%' }}
								items={sidebarLinks}
							/>
						</Sider>
						<Layout>
							<Content
								className="site-layout-background"
								style={{
									padding: 20,
									minHeight: 280,
								}}
							>
								<div className={styles.content}>{children}</div>
							</Content>
						</Layout>
					</Layout>
				</Content>
			</Layout>
		);
	}
);
