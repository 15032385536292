import { makeAutoObservable } from 'mobx';
import snakeCaseKeys from 'snakecase-keys';

import axiosClient from '../utils/axiosClient';

interface SignUpRequestValues {
	firstName: string;
	lastName: string;
	middleName: string;
	phone: string;
	email: string;
	password: string;
	referrer_id?: string | null;
	birthday: string | null;
	gender: string;
	confirmedToken?: string;
}

export class SignUpStore {
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async sendBeforeRegistration(values: SignUpRequestValues) {
		this.setLoading(true);

		try {
			await axiosClient.post(
				'/user/before-registration',
				snakeCaseKeys(values)
			);
		} catch (error: any) {
			throw error.response;
		} finally {
			this.setLoading(false);
		}
	}

	async sendSignUpCodeRegistration(values: SignUpRequestValues) {
		this.setLoading(true);
		try {
			await axiosClient.post('/user/registration', snakeCaseKeys(values));
		} catch (error: any) {
			throw error.response;
		} finally {
			this.setLoading(false);
		}
	}

	setLoading(value: boolean) {
		this.loading = value;
	}

	addCodeToPhone(phone: string) {
		return `38${phone}`;
	}
}
