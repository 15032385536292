import { Button, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminLayout } from '../../../../components/Admin/Layout';
import { AdminTable } from '../../../../components/Admin/Table';
import { TableFilters } from '../../../../components/Admin/TableFilters';
import { EditDeleteIcons } from '../../../../components/EditDeleteIcons';
import { adminInvoicesStore, adminServicesStore } from '../../../../stores';
import styles from './styles.module.scss';

const Invoices = observer(() => {
	const { t } = useTranslation();
	const { loading } = adminInvoicesStore;
	const { _meta, data } = adminInvoicesStore.invoices;

	React.useEffect(() => {
		adminInvoicesStore.getInvoices();
		adminServicesStore.getServices();
		return () => {
			adminInvoicesStore.clearFilters();
		};
	}, []);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
		},
		{
			title: t('Название'),
			dataIndex: 'name',
		},
		{
			title: t('Описание'),
			dataIndex: 'desc',
		},
		{
			title: t('Цена'),
			dataIndex: 'price',
			sorter: true,
		},
		{
			title: t('Действие'),
			render: (item: any) => (
				<EditDeleteIcons
					onEdit={() => adminInvoicesStore.openModalEditInvoice(item)}
					onDelete={() => adminInvoicesStore.deleteInvoice(item.id)}
				/>
			),
		},
	];

	const onDateChange = (dates: any, values: any) => {
		if (!dates) {
			adminInvoicesStore.setFilters({ start: null, end: null });
		} else {
			const [start, end] = values;
			adminInvoicesStore.setFilters({ start, end });
		}
		adminInvoicesStore.getInvoices();
	};
	const onDepartmenChange = (department_id: number) => {
		if (!department_id) {
			adminInvoicesStore.setFilters({ department_id: null });
		} else {
			adminInvoicesStore.setFilters({ department_id });
		}
		adminInvoicesStore.getInvoices();
	};
	const onSearch = (name: string) => {
		if (!name) {
			adminInvoicesStore.setFilters({ name: null });
		} else {
			adminInvoicesStore.setFilters({ name });
		}
		adminInvoicesStore.getInvoices();
	};
	const paginationHandler = (page: any, pageSize: any, sortField: string) => {
		adminInvoicesStore.getInvoices(page, pageSize, sortField);
	};

	return (
		<AdminLayout>
			<Row justify="end">
				<Button
					className={styles.btn}
					type="primary"
					onClick={() => adminInvoicesStore.openModalCreateInvoice()}
				>
					{t('Добавить')}
				</Button>
			</Row>
			<AdminTable
				pagination={{
					options: { ..._meta, onChange: paginationHandler },
				}}
				columns={columns}
				loading={loading}
				data={data}
			>
				<TableFilters
					searchFieldConfigs={{
						disabled: loading,
						loading,
						className: styles.btnSearch,
						placeholder: t('Введите для поиска'),
						onSearch,
					}}
					departmentFieldConfigs={{
						loading,
						disabled: loading,
						onChange: onDepartmenChange,
					}}
					datesRangesConfigs={{ onChange: onDateChange, disabled: loading }}
				/>
			</AdminTable>
		</AdminLayout>
	);
});

export default Invoices;
