import { makeAutoObservable, observable } from 'mobx';

const initial = [
	{ key: 1, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 2, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 3, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 4, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 5, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 6, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
	{ key: 7, referrals: 0, cash_bon: 0, editable: false, rowChecked: false },
];

export enum RadioTypes {
	manual = 1,
	payBack,
	autoFill,
}
export type CalculatorItem = {
	key: number;
	referrals: number;
	cash_bon: number;
	editable: boolean;
	rowChecked: boolean;
};
export class CalculatorStore {
	@observable data: CalculatorItem[] = [...initial];
	contributedFunds = '';
	entityFunds = '';
	rowsDisabled = true;
	selectedType = 3;

	constructor() {
		makeAutoObservable(this);
	}

	setData(values: CalculatorItem[]) {
		this.data = values;
	}

	setType(value: number) {
		this.selectedType = value;
	}

	setContributedFunds(value: string) {
		this.contributedFunds = value;
	}

	setEntityFunds(value: string) {
		this.entityFunds = value;
	}

	setRowsDisabled(value: boolean) {
		this.rowsDisabled = value;
	}

	resetAll() {
		this.data = [...initial];
		this.contributedFunds = '';
		this.entityFunds = '';
	}

	setDefault() {
		this.contributedFunds = '';
		this.entityFunds = '';
		this.rowsDisabled = true;
		this.selectedType = 3;
	}
}
