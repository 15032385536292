import {
	AppstoreOutlined,
	ControlOutlined,
	CustomerServiceOutlined,
	LinkOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CabinetLayout } from '../../../../components/Cabinet/Layout';
import { PageTitle } from '../../../../components/Cabinet/PageTitle';
import { UserInfo } from '../../../../components/Cabinet/UserInfo';
import {
	CABINET_SETTINGS_ABOUT,
	CABINET_SETTINGS_LICENSE,
	CABINET_SETTINGS_PROFILE,
} from '../../../../pathNames';
import styles from './styles.module.scss';

const CabinetSettings = () => {
	const { t } = useTranslation();

	const items = [
		{
			icon: <ControlOutlined />,
			link: CABINET_SETTINGS_PROFILE,
			text: t('Настройки личных данных'),
		},
		{
			icon: <CustomerServiceOutlined />,
			link: '#',
			text: t('Техническая поддержка'),
		},
		{
			icon: <LinkOutlined />,
			otherLink: 'https://www.mednean.com.ua/',
			text: t('Наш Веб-сайт'),
		},
		{
			icon: <AppstoreOutlined />,
			link: CABINET_SETTINGS_ABOUT,
			text: t('Про приложение'),
		},
		{
			icon: <AppstoreOutlined />,
			otherLink: 'https://www.mednean.com.ua/kontakty/',
			text: t('Контакты'),
		},
		{
			icon: <AppstoreOutlined />,
			link: CABINET_SETTINGS_LICENSE,
			text: t('Лицензионное соглашение'),
		},
	];

	return (
		<CabinetLayout>
			<PageTitle title={t('Настройки профиля')} />
			<div className={styles.container}>
				<UserInfo editIcon={true} />
				<Row justify="center">
					<Col xs={24} md={10}>
						<Row justify="center" align="middle" gutter={[16, 16]}>
							{items.map(item => (
								<Col key={item.text} md={8} xs={8}>
									{item.link ? (
										<Link to={item.link}>
											<div className={styles.settingsCard}>
												{item.icon}
												<p>{item.text}</p>
											</div>
										</Link>
									) : (
										<a href={item.otherLink} target="_blank" rel="noreferrer">
											<div className={styles.settingsCard}>
												{item.icon}
												<p>{item.text}</p>
											</div>
										</a>
									)}
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
};

export default CabinetSettings;
