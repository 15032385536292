import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SIGN_UP } from '../../../../pathNames';
import styles from '../signIn.module.scss';

const SignUpSide = observer(() => {
	const { t } = useTranslation();

	return (
		<Col
			style={{ backgroundImage: 'url(/images/bg-auth.webp)' }}
			xs={{ span: 0 }}
			md={{ span: 10 }}
		>
			<Row justify="center" align="middle" className={styles.signUp}>
				<Col>
					<h2 className={styles.title}>{t('Добро пожаловать')}!</h2>
					<p>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem
						accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
						quae ab illo inventore veritatis et quasi architecto beatae vitae
						dicta sunt explicabo.
					</p>
					<Link to={SIGN_UP}>
						<Button className={styles.formControl} ghost>
							{t('Регистрация')}
						</Button>
					</Link>
				</Col>
			</Row>
		</Col>
	);
});

export default SignUpSide;
