import { Select } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adminUsersStore } from '../../../stores';

export const UserSelection = observer(
	({
		onChange,
		placeholder,
		showSearch = true,
		allowClear = true,
		value,
		disabled,
	}: any) => {
		const { t } = useTranslation();
		const { loading, users } = adminUsersStore;

		React.useEffect(() => {
			adminUsersStore.getUsers();
			return () => {
				adminUsersStore.clearFilters();
			};
		}, []);

		const searchByName = (name: string) => {
			adminUsersStore.setFilters({ name });
			adminUsersStore.getUsers(1, 0);
			return users?.data.length;
		};

		return (
			<Select
				loading={loading}
				allowClear={allowClear}
				showSearch={showSearch}
				optionFilterProp="children"
				disabled={disabled}
				style={{ width: '100%' }}
				value={value}
				placeholder={placeholder || t('Пациент')}
				onSearch={debounce(searchByName, 700)}
				onChange={(value, options) => onChange(value, options)}
			>
				{users?.data?.map((item: any) => (
					<Select.Option
						key={item.phone}
						user={item}
						value={item.profiles.user_id}
						first_name={item.profiles.first_name}
						last_name={item.profiles.last_name}
						phone={item.phone}
					>
						{item.profiles.last_name} {item.profiles.first_name}
					</Select.Option>
				))}
			</Select>
		);
	}
);
