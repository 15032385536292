import {
	Button,
	Col,
	Input,
	InputNumber,
	Radio,
	Row,
	Space,
	Table,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CabinetLayout } from '../../../components/Cabinet/Layout';
import { PageTitle } from '../../../components/Cabinet/PageTitle';
import { UserInfo } from '../../../components/Cabinet/UserInfo';
import { CABINET_CALCULATOR_RESULT } from '../../../pathNames';
import { cabinetCalculatorStore, referalsStore } from '../../../stores';
import { RadioTypes } from '../../../stores/Cabinet/Calculator';
import styles from './styles.module.scss';

const CabinetCalculator = observer(() => {
	const { entityFunds, contributedFunds, rowsDisabled, selectedType } =
		cabinetCalculatorStore;
	const navigate = useNavigate();
	const { t } = useTranslation();
	React.useEffect(() => {
		autoFillHandler();
	}, []);

	const columns = [
		{
			title: `% ${t('Вложенных средств')}`,
			width: '40%',
			render: (level: number) => (
				<>
					{referalsStore.getGenerationRomanNum(level)} {t('Поколение')}
				</>
			),
			dataIndex: 'key',
		},
		{
			title: () => <>{selectedType !== RadioTypes.autoFill && t('Рефералы')}</>,
			editable: true,
			width: '30%',
			render: (item: any) =>
				item.editable ? (
					<InputNumber
						onChange={(e: any) => {
							referralInputHandler(e, item);
						}}
						disabled={!item.editable}
						defaultValue={item.referrals}
						value={item.referrals}
					/>
				) : (
					<>{selectedType !== RadioTypes.autoFill && item.referrals}</>
				),
		},
		{
			title: () => <>{t('Кэш бонусы')}</>,
			editable: true,
			width: '30%',
			render: (item: any) =>
				item.editable ? (
					<InputNumber
						onChange={(e: any) => {
							cashInputHandler(e, item);
						}}
						disabled={!item.editable}
						defaultValue={item.cash_bon}
						value={item.cash_bon}
					/>
				) : (
					<>{item.cash_bon}</>
				),
		},
	];

	const cashInputHandler = (value: number, record: any) => {
		const result = cabinetCalculatorStore.data.map(item => {
			if (item.key === record.key) {
				item.cash_bon = value;
			}
			return item;
		});
		cabinetCalculatorStore.setData(result);
	};

	const referralInputHandler = (value: number, record: any) => {
		const result = cabinetCalculatorStore.data.map(item => {
			if (item.key === record.key) {
				item.referrals = value;
			}
			return item;
		});
		cabinetCalculatorStore.setData(result);
	};

	const singleRowHandler = (selectedRowKeys: any) => {
		cabinetCalculatorStore.setData(
			cabinetCalculatorStore.data.filter(item => {
				if (selectedRowKeys.includes(item.key)) {
					item.editable = true;
				} else {
					item.editable = false;
				}
				return item;
			})
		);
	};

	const typeHandler = (e: any) => {
		cabinetCalculatorStore.setData([]); // clear
		const { value } = e.target;
		cabinetCalculatorStore.setType(value);
		if (value === RadioTypes.manual) {
			manualHandler();
		}
		if (value === RadioTypes.payBack) {
			payBackHandler(true);
		}
		if (value === RadioTypes.autoFill) {
			autoFillHandler();
		}
	};

	const manualHandler = () => {
		cabinetCalculatorStore.resetAll();
		cabinetCalculatorStore.setRowsDisabled(false);
		cabinetCalculatorStore.setData(
			cabinetCalculatorStore.data.map(item => item)
		);
	};

	const payBackHandler = (value: boolean) => {
		cabinetCalculatorStore.resetAll();
		cabinetCalculatorStore.setRowsDisabled(true);
		cabinetCalculatorStore.setData(
			cabinetCalculatorStore.data.map(item => ({ ...item, rowDisabled: value }))
		);
	};

	const autoFillHandler = () => {
		cabinetCalculatorStore.resetAll();
		cabinetCalculatorStore.setContributedFunds('1000');
		cabinetCalculatorStore.setRowsDisabled(true);
		cabinetCalculatorStore.setData(
			cabinetCalculatorStore.data.map(item => {
				item.referrals = 10;
				if (item.key === 1) {
					item.cash_bon = 1000;
				} else if (item.key === 2) {
					item.cash_bon = 7000;
				} else if (item.key === 3) {
					item.cash_bon = 50000;
				} else if (item.key === 4) {
					item.cash_bon = 300000;
				} else if (item.key === 5) {
					item.cash_bon = 2000000;
				} else if (item.key === 6 || item.key === 7) {
					item.cash_bon = 10000000;
				}
				return item;
			})
		);
	};

	const selectAll = (value: boolean) => {
		const result = cabinetCalculatorStore.data.map(item => {
			item.rowChecked = value;
			return item;
		});
		cabinetCalculatorStore.setData(result);
	};

	const rowSelection = {
		hideSelectAll: false,
		onSelectAll: (value: boolean) => selectAll(value),
		onChange: (selectedRowKeys: React.Key[]) => {
			singleRowHandler(selectedRowKeys);
		},
		getCheckboxProps: () => ({
			disabled: rowsDisabled,
		}),
	};

	return (
		<CabinetLayout>
			<PageTitle title={t('Калькулятор')} />
			<div className={styles.container}>
				<UserInfo detailInfo={true} />
				<Row justify="space-between" gutter={12}>
					<Col lg={10}>
						<Row justify="space-between">
							<Col lg={12}>
								<Radio.Group
									defaultValue={RadioTypes.autoFill}
									onChange={typeHandler}
								>
									<Row gutter={[16, 16]}>
										<Col>
											<Radio value={RadioTypes.manual}>
												{t('Ручной ввод')}
											</Radio>
										</Col>
										<Col>
											<Radio value={RadioTypes.payBack}>
												{t('Возврат средств')}
											</Radio>
										</Col>
										<Col>
											<Radio value={RadioTypes.autoFill}>
												{t('Автозаполнение')}
											</Radio>
										</Col>
									</Row>
								</Radio.Group>
							</Col>
							<Col lg={12}>
								<Space direction="vertical" size={6}>
									<div>
										<span>{t('Кол-во средств')}</span>
										<Input
											disabled={selectedType !== RadioTypes.payBack}
											value={entityFunds}
											onChange={e => {
												if (e.target.value) {
													const funds = Number(e.target.value);
													if (funds > 99000000000) {
														return cabinetCalculatorStore.setEntityFunds(
															String(99000000000)
														);
													}
													cabinetCalculatorStore.setEntityFunds(String(funds));
												} else {
													cabinetCalculatorStore.setEntityFunds('');
												}
											}}
											placeholder={t('Кол-во средств')}
											title={t('Кол-во средств')}
											className={styles.input}
											bordered={false}
										/>
									</div>
									<div>
										<span>{t('Внесенные средства')}</span>
										<Input
											disabled={selectedType !== RadioTypes.autoFill}
											onChange={e => {
												if (e.target.value) {
													const funds = Number(e.target.value);
													if (funds > 100000) {
														return cabinetCalculatorStore.setContributedFunds(
															'100000'
														);
													}
													cabinetCalculatorStore.setContributedFunds(
														String(funds)
													);
												} else {
													cabinetCalculatorStore.setContributedFunds('');
												}
											}}
											value={contributedFunds}
											placeholder={t('Внесенные средства')}
											className={styles.input}
											bordered={false}
										/>
									</div>
								</Space>
							</Col>
							{selectedType === RadioTypes.autoFill && (
								<span>{t('Обьяснение реферальной системи-3')}</span>
							)}
						</Row>
					</Col>
					<Col lg={14}>
						<Table
							rowSelection={{
								type: 'checkbox',
								...rowSelection,
							}}
							columns={columns}
							pagination={false}
							dataSource={cabinetCalculatorStore.data}
						/>
						<Button
							onClick={() => navigate(CABINET_CALCULATOR_RESULT)}
							block
							size="large"
						>
							{t('Рассчитать')}
						</Button>
					</Col>
				</Row>
			</div>
		</CabinetLayout>
	);
});

export default CabinetCalculator;
