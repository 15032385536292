import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { adminCalendarStore, languageStore } from '../../../stores';
import { Calendar } from '../../common';
import styles from './styles.module.scss';

export const CalendarMonth = observer(() => {
	const { t } = useTranslation();
	const { data } = adminCalendarStore.timeList;
	const { currentMonth } = adminCalendarStore;

	const [currentDate, setCurrentDate] = React.useState(dayjs());
	React.useEffect(() => {
		const month = dayjs().format('YYYY-MM-DD');
		adminCalendarStore.setFilters({ month });
		adminCalendarStore.getTimeList();
	}, []);

	const monthCellRender = (value: any) => {
		const startOfDate = value.startOf('day').format('MM');

		return (
			<ul className={styles.events}>
				{data?.map((item: any) =>
					item.timeListUnix.map(
						(unixTime: number) =>
							dayjs.unix(unixTime).startOf('day').format('MM') ===
							startOfDate && (
								<li key={item.id}>
									<span className={styles[item.type]}>
										{t('Очередь с')} {item.time_start.replace(/:\d+$/gm, '')} -{' '}
										{item.time_end.replace(/:\d+$/gm, '')}
									</span>
								</li>
							)
					)
				)}
			</ul>
		);
	};

	const dateCellRender = (value: any) => {
		const startOfDate = value.startOf('day');

		return (
			<ul className={styles.events}>
				{data?.map((item: any) =>
					item.timeListUnix.map((unixTime: number) => (
						<>
							{dayjs
								.unix(unixTime)
								.startOf('day')
								.isSame(dayjs(startOfDate).startOf('day')) && (
									<li key={item.id}>
										<span
											className={
												dayjs().isSame(dayjs(startOfDate)) ||
													dayjs().isAfter(dayjs(startOfDate))
													? styles.disabled
													: styles.success
											}
										>
											{t('Очередь с')} {item.time_start.replace(/:\d+$/gm, '')} -{' '}
											{item.time_end.replace(/:\d+$/gm, '')}
										</span>
									</li>
								)}
						</>
					))
				)}
			</ul>
		);
	};

	return (
		<div className={styles.container}>
			<Row>
				<Col span={24}>
					<div className={styles.tabTitle}>
						<Button
							onClick={() => adminCalendarStore.changeMonth('subtract')}
							className={styles.btnArrow}
						>
							<LeftOutlined className={styles.icon} />
						</Button>
						<span className={styles.title}>
							{currentMonth.locale(languageStore.currentLang).format('MMMM')}
						</span>
						<Button
							onClick={() => adminCalendarStore.changeMonth('add')}
							className={styles.btnArrow}
						>
							<RightOutlined className={styles.icon} />
						</Button>
					</div>
				</Col>
			</Row>
			<Calendar
				value={currentMonth}
				monthCellRender={monthCellRender}
				onSelect={(date: any) => setCurrentDate(date)}
				className={styles.dateContainer}
				dateCellRender={dateCellRender}
			/>
		</div>
	);
});
