import { configure } from 'mobx';

import { AdminCalendarStore } from './Admin/Calendar';
import { AdminDepartmentsStore } from './Admin/Departments';
import { AdminInvoicesStore } from './Admin/Invoices';
import { AdminMenu } from './Admin/Menu';
import { AdminNotificationsStore } from './Admin/Notifications';
import { AdminOrdersStore } from './Admin/Orders';
import { AdminPatientsStore } from './Admin/Patients';
import { AdminReferralsStore } from './Admin/Referrals';
import { AdminServicesStore } from './Admin/Services';
import { AdminStatisticsStore } from './Admin/Statistics';
import { AdminTransactionsStore } from './Admin/Transactions';
import { AdminUsersStore } from './Admin/Users';
import { AuthStore } from './Auth';
import { CalculatorStore } from './Cabinet/Calculator';
import { PaymentCards } from './Cabinet/PaymentCards';
import { ReferalsStore } from './Cabinet/Referals';
import { ServicesStore } from './Cabinet/Services';
import { CabinetSideBarStore } from './Cabinet/SideBar';
import { TransactionsStore } from './Cabinet/Transactions';
import { Language } from './Language';
import { PasswordRecoveryStore } from './PasswordRecovery';
import { SignUpStore } from './SignUp';
import { UserStore } from './User';

configure({
	enforceActions: 'never',
});

export const passwordRecovery = new PasswordRecoveryStore();
export const signUpStore = new SignUpStore();
export const authStore = new AuthStore();
export const referalsStore = new ReferalsStore();
export const transactionsStore = new TransactionsStore();
export const adminTransactionsStore = new AdminTransactionsStore();
export const cabinetSideBarStore = new CabinetSideBarStore();
export const userStore = new UserStore();
export const adminServicesStore = new AdminServicesStore();
export const adminInvoicesStore = new AdminInvoicesStore();
export const languageStore = new Language();
export const adminStatisticsStore = new AdminStatisticsStore();
export const adminDepartmentsStore = new AdminDepartmentsStore();
export const adminUsersStore = new AdminUsersStore();
export const adminPatientsStore = new AdminPatientsStore();
export const servicesStore = new ServicesStore();
export const adminOrdersStore = new AdminOrdersStore();
export const adminNotificationsStore = new AdminNotificationsStore();
export const adminCalendarStore = new AdminCalendarStore();
export const adminReferralsStore = new AdminReferralsStore();
export const cabinetCalculatorStore = new CalculatorStore();
export const adminMenuStore = new AdminMenu();
export const paymentCardsStore = new PaymentCards();
