import {
	ArrowUpOutlined,
	CloseCircleOutlined,
	LeftOutlined,
	RightOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adminCalendarStore, adminOrdersStore } from '../../../stores';
import { EditDeleteIcons } from '../../EditDeleteIcons';
import { AdminTable } from '../Table';
import { TableFilters } from '../TableFilters';
import styles from './styles.module.scss';

export const CalendarWeek = observer(() => {
	const { t } = useTranslation();

	const [withFilters, setWithFilters] = React.useState(false);

	React.useEffect(() => {
		return () => {
			adminCalendarStore.resetCurrentDate();
		};
	}, []);

	const getQueueColor = (status: number) => {
		switch (status) {
			case 0:
				return styles.disabled;
			case 2:
				return styles.success;
			case 4:
				return styles.danger;
			case 6:
				return styles.disabled;
			case 8:
				return styles.warning;
		}
	};

	const renderColumn = (item: any) => {
		const appointmentTime = dayjs.unix(item?.appointment_at);
		const pastRecording = dayjs() > appointmentTime;
		return item?.appointment_at ? (
			<Row
				gutter={12}
				title={item.product.name}
				align="middle"
				justify="space-between"
			>
				<Col>
					{item?.appointment_at && appointmentTime.clone().format('HH:mm')}
				</Col>
				{item.order && (
					<>
						<Col>
							<span
								className={
									pastRecording ? '' : getQueueColor(item.order.status)
								}
							>
								{item?.user?.profiles?.last_name}{' '}
								{item?.user?.profiles?.first_name[0]}.
							</span>
						</Col>
						{!pastRecording && (
							<Col>
								<EditDeleteIcons
									onEdit={() => adminOrdersStore.openModalAcceptToQueue(item)}
								/>
							</Col>
						)}
					</>
				)}
			</Row>
		) : (
			<span className={styles.free}>{t('Нет записи')}</span>
		);
	};

	const departmentHandler = (department_id: number) => {
		setWithFilters(true);
		adminCalendarStore.setFilters({ department_id });
		adminCalendarStore.getTime();
	};

	const serviceHandler = (product_id: number) => {
		setWithFilters(true);
		adminCalendarStore.setFilters({ product_id });
		adminCalendarStore.getTime();
	};

	const configs = {
		disabled: adminCalendarStore.loading,
		loading: adminCalendarStore.loading,
	};

	const columns = [
		{
			title: `Пн/${adminCalendarStore.currentWeek[0]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[0]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Вт/${adminCalendarStore.currentWeek[1]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[1]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Ср/${adminCalendarStore.currentWeek[2]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[2]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Чт/${adminCalendarStore.currentWeek[3]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[3]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Пт/${adminCalendarStore.currentWeek[4]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[4]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Сб/${adminCalendarStore.currentWeek[5]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[5]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
		{
			title: `Вс/${adminCalendarStore.currentWeek[6]}`,
			dataIndex: adminCalendarStore.getTimeUnix(
				adminCalendarStore.currentWeek[6]
			),
			render: (item: any) => ({
				props: {
					style: {
						background:
							dayjs() > dayjs.unix(item?.appointment_at) && '#f1f1f1a6',
					},
				},
				children: renderColumn(item),
			}),
		},
	];

	return (
		<div>
			<Row justify="center" className={styles.navigation}>
				<Col>
					<Button
						disabled={adminCalendarStore.loading}
						className={styles.btnArrow}
						onClick={() => {
							setWithFilters(true);
							adminCalendarStore.changeWeek('subtract');
						}}
					>
						<LeftOutlined className={styles.icon} />
					</Button>
				</Col>
				<Col>
					<span className={styles.title}>
						{adminCalendarStore.currentWeek[0]} -{' '}
						{adminCalendarStore.currentWeek[6]}
					</span>
					{Object.keys(adminCalendarStore.filters).length ? (
						<CloseCircleOutlined
							onClick={() => {
								setWithFilters(false);
								adminCalendarStore.resetCurrentDate();
							}}
							style={{ fontSize: '20px' }}
						/>
					) : null}
				</Col>
				<Col>
					<Button
						disabled={adminCalendarStore.loading}
						className={styles.btnArrow}
						onClick={() => {
							setWithFilters(true);
							adminCalendarStore.changeWeek('add');
						}}
					>
						<RightOutlined className={styles.icon} />
					</Button>
				</Col>
			</Row>
			<AdminTable
				loading={adminCalendarStore.loading}
				columns={columns}
				bordered
				localeText={!withFilters ? <>{t('Выберите услугу или филию')}</> : null}
				localeIcon={!withFilters ? <ArrowUpOutlined rotate={-55} /> : null}
				data={adminCalendarStore.time}
				pagination={false}
			>
				<TableFilters
					departmentFieldConfigs={{
						onChange: departmentHandler,
						value: adminCalendarStore.filters.department_id,
						...configs,
					}}
					serviceFieldConfigs={{
						value: adminCalendarStore.filters.product_id,
						onChange: serviceHandler,
						...configs,
					}}
				/>
			</AdminTable>
		</div>
	);
});
