import { LeftOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { referalsStore } from '../../../stores';
import styles from './styles.module.scss';

const ReferalInfo = ({ onBack, toFirstPage, children }: any) => {
	const { t } = useTranslation();
	const { selectedReferal } = referalsStore;
	const fullName =
		selectedReferal?.profiles?.first_name &&
		selectedReferal?.profiles?.last_name ? (
			<>
				{selectedReferal?.profiles?.first_name}{' '}
				{selectedReferal?.profiles?.last_name}{' '}
				{selectedReferal?.profiles?.middle_name}
			</>
		) : undefined;
	const getGenderName = (gender: number | undefined) => {
		switch (gender) {
			case 0:
				return <>{t('Женский')}</>;
			case 1:
				return <>{t('Мужской')}</>;
			default:
				return undefined;
		}
	};
	const renderValue = (value: any) => {
		if (value) {
			return value;
		}
		return t('Недоступно');
	};
	return (
		<>
			<Row justify="space-between">
				<Col>
					<Button onClick={onBack} type="link" block>
						<LeftOutlined className={styles.icon} />
						{t('Назад')}
					</Button>
				</Col>
				<Col>
					<Button onClick={toFirstPage} type="link" block>
						<UndoOutlined className={styles.icon} />
					</Button>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<h3 className={styles.title}>
						{selectedReferal?.profiles?.first_name &&
						selectedReferal?.profiles?.last_name ? (
							<>
								{selectedReferal?.profiles?.first_name}{' '}
								{selectedReferal?.profiles?.last_name}
							</>
						) : (
							'Реферал'
						)}
					</h3>
				</Col>
			</Row>
			<Row className={styles.listTop} gutter={24} justify="center">
				<Col span={10}>
					<div>{selectedReferal?.referralCount}</div>
					<div>Рефералов</div>
				</Col>
				<Col span={10}>
					<div>{selectedReferal?.fullReferral}</div>
					<div>{t('Кеш-бонусов')}</div>
				</Col>
			</Row>
			<Row gutter={24} justify="space-between">
				<Col span={10}>
					<p>{t('ФИО')}</p>
				</Col>
				<Col span={14}>
					<p>{renderValue(fullName)}</p>
				</Col>
				<Col span={10}>
					<p>{t('Номер телефона')}</p>
				</Col>
				<Col span={14}>
					<p>{renderValue(selectedReferal?.phone)}</p>
				</Col>
				<Col span={10}>
					<p>{t('Дата рождения')}</p>
				</Col>
				<Col span={14}>
					<p>{renderValue(selectedReferal?.profiles?.birthday)}</p>
				</Col>
				<Col span={10}>
					<p>{t('Пол')}</p>
				</Col>
				<Col span={14}>
					<p>{renderValue(getGenderName(selectedReferal?.profiles?.gender))}</p>
				</Col>
				<Col span={10}>
					<p>{t('Электронный адрес')}</p>
				</Col>
				<Col span={14}>
					<p>{renderValue(selectedReferal?.email)}</p>
				</Col>
			</Row>
			<br />
			<Row justify="center">
				{children}
				{/* <Button className={styles.accessBtn} type="primary" size="large">*/}
				{/*  {t('Запросить доступ')}*/}
				{/* </Button>*/}
			</Row>
		</>
	);
};

export default ReferalInfo;
