import { Button, Col, Row } from 'antd';
import { Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adminDepartmentsStore } from '../../../stores';
import InputField from '../../InputField';
import styles from './styles.module.scss';

const Department = ({
	onCancel,
	onSubmit,
	initialValues = { nameRus: '', nameUkr: '', email: '', phone: '' },
}: any) => {
	const { t } = useTranslation();
	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={values => {
					adminDepartmentsStore.createDepartment(values);
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<Observer>
						{() => (
							<form onSubmit={handleSubmit}>
								<Row justify="space-between" align="middle">
									<Col span={12}>
										<span>Русский</span>
										<img className={styles.langIcon} src="/images/russia.webp" />
									</Col>
									<Col span={12}>
										<span>Українська</span>
										<img
											className={styles.langIcon}
											src="/images/ukraine.webp"
										/>
									</Col>
								</Row>
								<Row gutter={12}>
									<Col span={12}>
										<InputField
											value={values.nameRus}
											title="Введите название филии"
											placeholder="Введите название филии"
											required={true}
											onChange={event =>
												setFieldValue('nameRus', event.target.value)
											}
										/>
									</Col>
									<Col span={12}>
										<InputField
											value={values.nameUkr}
											title="Введiть назву фiлiї"
											placeholder="Введiть назву фiлiї"
											required={true}
											onChange={event =>
												setFieldValue('nameUkr', event.target.value)
											}
										/>
									</Col>
								</Row>
								<Row gutter={12}>
									<Col span={12}>
										<InputField
											value={values.phone}
											title="Введите номер филии"
											placeholder="Введите номер филии"
											required={true}
											onChange={event =>
												setFieldValue('phone', event.target.value)
											}
										/>
									</Col>
									<Col span={12}>
										<InputField
											value={values.email}
											title="Введите почту филии"
											placeholder="Введите почту филии"
											required={true}
											onChange={event =>
												setFieldValue('email', event.target.value)
											}
										/>
									</Col>
								</Row>
								<Row justify="end" gutter={12}>
									<Col>
										<Button onClick={onCancel} type="default" size="large">
											{t('Отменить')}
										</Button>
									</Col>
									<Col>
										<Button
											onClick={() => onSubmit(values)}
											type="primary"
											size="large"
										>
											{t('Сохранить')}
										</Button>
									</Col>
								</Row>
							</form>
						)}
					</Observer>
				)}
			</Formik>
		</>
	);
};

export default Department;
