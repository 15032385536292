import {
	AppleOutlined,
	CommentOutlined,
	DatabaseOutlined,
	DeploymentUnitOutlined,
	ExportOutlined,
	GoogleOutlined,
	HomeOutlined,
	PercentageOutlined,
	SisternodeOutlined,
	WifiOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { authStore, cabinetSideBarStore, languageStore } from '../../../stores';
import styles from './styles.module.scss';
const { Sider } = Layout;

const SideBar = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const siteUrl = `https://mednean.com.ua/${languageStore.currentLang}`;
	return (
		<Sider
			trigger={null}
			collapsible
			collapsedWidth={cabinetSideBarStore.collapseWidth}
			breakpoint="sm"
			width={260}
			collapsed={cabinetSideBarStore.collapsed}
			className={styles.sideBar}
			onBreakpoint={point => cabinetSideBarStore.setCollapsedWidth(point)}
		>
			<img className={styles.logo} src={'/images/logo-white.webp'} alt="logo" />
			<Menu
				className={styles.menu}
				theme="dark"
				defaultSelectedKeys={[location.pathname]}
			>
				<Menu.Item
					key="1"
					icon={cabinetSideBarStore.collapsed && <HomeOutlined />}
				>
					<a href={`${siteUrl}/`}>{t('Главная')}</a>
				</Menu.Item>
				<Menu.Item
					key="2"
					icon={cabinetSideBarStore.collapsed && <DatabaseOutlined />}
				>
					<a href={`${siteUrl}/napravlenyya-raboty/`}>Напрямки роботи</a>
				</Menu.Item>
				<Menu.Item
					key="3"
					icon={cabinetSideBarStore.collapsed && <DeploymentUnitOutlined />}
				>
					<a href={`${siteUrl}/metody-lecheniya/`}>Методи лікування</a>
				</Menu.Item>
				<Menu.Item
					key="4"
					icon={cabinetSideBarStore.collapsed && <SisternodeOutlined />}
				>
					<a href={`${siteUrl}/bolezni/`}>Хвороби</a>
				</Menu.Item>
				<Menu.Item
					key="5"
					icon={cabinetSideBarStore.collapsed && <PercentageOutlined />}
				>
					<a href={`${siteUrl}/prajs-list/`}>Прайс</a>
				</Menu.Item>
				<Menu.Item
					key="6"
					icon={cabinetSideBarStore.collapsed && <WifiOutlined />}
				>
					<a href={`${siteUrl}/вопросы-и-ответы/`}>Питання - Відповіді</a>
				</Menu.Item>
				<Menu.Item
					key="7"
					icon={cabinetSideBarStore.collapsed && <CommentOutlined />}
				>
					<a href={`${siteUrl}/kontakty/`}>Контакти</a>
				</Menu.Item>
				<Menu.Item
					className={styles.menuImg}
					key="10"
					icon={cabinetSideBarStore.collapsed && <AppleOutlined />}
				>
					<img src={'/images/AppleStore.wepb'} />
				</Menu.Item>
				<Menu.Item
					className={styles.menuImg}
					key="11"
					icon={cabinetSideBarStore.collapsed && <GoogleOutlined />}
				>
					<img src={'/images/GooglePlay.webp'} />
				</Menu.Item>
				<Menu.Item
					icon={cabinetSideBarStore.collapsed && <ExportOutlined />}
					onClick={() => authStore.signOut()}
					className={styles.exit}
					key="12"
				>
					<ExportOutlined /> {t('Выход')}
				</Menu.Item>
			</Menu>
		</Sider>
	);
};

export default observer(SideBar);
