import { CheckCircleTwoTone } from '@ant-design/icons';
import { Select, notification } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { languageStore } from '../../../stores';
import styles from './styles.modules.scss';

const LanguageSelection = () => {
	const { t } = useTranslation();

	const languages = [
		{ value: 'ru', icon: <img width="20px" src="/images/russia.webp" /> },
		{ value: 'uk', icon: <img width="20px" src="/images/ukraine.webp" /> },
	];
	return (
		<Select
			suffixIcon={null}
			className={styles.langSelect}
			value={languageStore.currentLang}
			defaultValue={languageStore.currentLang}
			onChange={value => {
				languageStore.changeLang(value);
				notification.success({
					message: t('Язык интерфейса изменился'),
					description: '',
					icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
				});
			}}
		>
			{languages.map(lang => (
				<Select.Option key={lang.value} value={lang.value}>
					{lang.icon}
				</Select.Option>
			))}
		</Select>
	);
};

export default React.memo(LanguageSelection);
